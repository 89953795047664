import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[800],
        outline: 'none',
        cursor: 'context-menu',
    },
    fixed: {
        width: '100%',
        height: '100%',
    },
    video: {
        display: 'block',
        width: '100%',
        backgroundColor: theme.palette.grey[900],
        outline: 'none',
    },
}))

const initialMouseState = {
    x: null,
    y: null,
}

const VideoPlayer = React.forwardRef(
    ({ video, fixed, submitReelForm, classes, ...videoProps }, ref) => {
        const compClasses = useStyles()
        const [mouseState, setMouseState] = useState(initialMouseState)
        const rootClass = classNames(compClasses.root, classes.root, {
            [compClasses.fixed]: fixed,
        })
        const videoClass = classNames(compClasses.video, classes.video, {
            [compClasses.fixed]: fixed,
        })
        const sourceTags = video.resources
            .sort((a, b) => {
                const precedence = ['av1', 'hevc', 'h264']
                const aIndex = precedence.indexOf(a.video_codec_name)
                const bIndex = precedence.indexOf(b.video_codec_name)

                if (aIndex === bIndex) {
                    return 0
                } else if (aIndex === -1) {
                    return 1
                } else if (bIndex === -1) {
                    return -1
                } else if (aIndex < bIndex) {
                    return -1
                } else {
                    return 1
                }
            })
            .map((resource) => {
                let mediaType = resource.mimetype
                const codecParams = []
                if (resource.video_codec_parameter_string) {
                    codecParams.push(resource.video_codec_parameter_string)
                }
                if (resource.audio_codec_parameter_string) {
                    codecParams.push(resource.audio_codec_parameter_string)
                }
                if (codecParams.length > 0) {
                    mediaType += `; codecs=${codecParams.join(',')}`
                }

                return (
                    <source
                        key={resource.id}
                        src={resource.url}
                        type={mediaType}
                    />
                )
            })

        const onContextMenu = (evt) => {
            evt.preventDefault()
            setMouseState({
                x: evt.clientX - 2,
                y: evt.clientY - 4,
            })
        }
        const handleClose = (evt) => {
            setMouseState({ x: null, y: null })
        }

        return (
            <div onContextMenu={onContextMenu} className={rootClass}>
                <video
                    key={video.id}
                    ref={ref}
                    className={videoClass}
                    {...videoProps}
                >
                    {sourceTags}
                </video>
                <Menu
                    keepMounted
                    open={mouseState.y !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        mouseState.y !== null && mouseState.x !== null
                            ? { top: mouseState.y, left: mouseState.x }
                            : undefined
                    }
                >
                    <MenuItem
                        onClick={() => {
                            submitReelForm(`reelForm-source${video.id}`, {
                                source_id: video.id,
                                source_type: 'video',
                                item_type: 'image',
                                count: 1,
                                start: ref.current.currentTime,
                            })
                            handleClose()
                        }}
                    >
                        Capture Screenshot
                    </MenuItem>
                </Menu>
            </div>
        )
    }
)

VideoPlayer.propTypes = {
    /**
     * The video to play
     */
    video: PropTypes.object.isRequired,
    /**
     * Whether or not to render a fixed video player
     */
    fixed: PropTypes.bool,
    /**
     * Function to call when capturing a screenshot
     */
    submitReelForm: PropTypes.func,
    /**
     * Optional CSS class to apply components
     */
    classes: PropTypes.shape({
        /**
         * Class to apply to the root component
         */
        root: PropTypes.string,
        /**
         * Class to apply to the video element
         */
        video: PropTypes.string,
    }),
    // /**
    //  * Properties to be sent to the underlying video DOM element
    //  */
    // videoProps: PropTypes.object,
}

VideoPlayer.defaultProps = {
    classes: {},
}

export default VideoPlayer
