import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridGap: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            gridTemplateRows: '1fr 1fr',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    inline: {
        display: 'inline-grid',
    },
    comfy: {
        padding: theme.spacing(1),
    },
    contained: {
        padding: theme.spacing(0.5, 1),
        border: `1px solid ${theme.palette.grey[400]}`,
        background: theme.palette.grey[100],
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(0.5),
    },
    vertical: {
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: '1fr',
    },
    tooltip: {
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
    },
    alternate: {
        backgroundColor: theme.palette.grey[200],
    },
    label: {
        fontWeight: 'bold',
        color: theme.palette.grey[800],
        placeSelf: 'center start',
        [theme.breakpoints.down('sm')]: {
            placeSelf: 'center',
        },
    },
    labelVertical: {
        placeSelf: 'center',
    },
    value: {
        placeSelf: 'center end',
        [theme.breakpoints.down('sm')]: {
            placeSelf: 'center',
        },
    },
    valueVertical: {
        placeSelf: 'center',
    },
}))

const Stat = ({
    label,
    tooltip,
    children,
    vertical,
    inline,
    comfy,
    contained,
    alt,
}) => {
    const classes = useStyles()
    const rootClass = classNames(classes.root, {
        [classes.vertical]: vertical && !tooltip,
        [classes.tooltip]: tooltip,
        [classes.contained]: contained,
        [classes.comfy]: comfy,
        [classes.inline]: inline,
        [classes.alternate]: alt,
    })
    const labelClass = classNames(classes.label, {
        [classes.labelVertical]: vertical,
    })
    const valueClass = classNames(classes.value, {
        [classes.valueVertical]: vertical,
    })

    let body
    if (tooltip) {
        body = (
            <Tooltip title={label} placement="top">
                <Typography variant="caption" classes={{ caption: valueClass }}>
                    {children || '--'}
                </Typography>
            </Tooltip>
        )
    } else {
        body = (
            <>
                <Typography variant="button" classes={{ button: labelClass }}>
                    {label}
                </Typography>
                <Typography variant="caption" classes={{ caption: valueClass }}>
                    {children || '--'}
                </Typography>
            </>
        )
    }
    return <div className={rootClass}>{body}</div>
}

Stat.propTypes = {
    /**
     * The label for this stat
     */
    label: PropTypes.string.isRequired,
    /**
     * The stat value, if any
     */
    children: PropTypes.node,
    /**
     * Whether or not to render as a tooltip
     */
    tooltip: PropTypes.bool,
    /**
     * Display inline
     */
    inline: PropTypes.bool,
    /**
     * If true, orient vertically
     */
    vertical: PropTypes.bool,
    /**
     * Apply contained style
     */
    contained: PropTypes.bool,
    /**
     * If true, add some padding
     */
    comfy: PropTypes.bool,
    /**
     * If true, use an alternate style
     */
    alt: PropTypes.bool,
}

export default Stat
