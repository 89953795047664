import { connect } from 'react-redux'
import { getAppState } from '../selectors'
import Loader from '../components/Loader'

const mapStateToProps = (state) => {
    const { loaderUrl: src } = getAppState(state)
    return { src }
}

export default connect(mapStateToProps, undefined)(Loader)
