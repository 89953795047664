import { normalize } from 'normalizr'
import { mediaSchema } from '../schema'
import { updateEntities, storeCollection, storeMedia } from './Entities'
import {
    makePageLoadStart,
    makePageLoadError,
    makePageLoadSuccess,
    makeUpdatePageState,
} from './PageState'

export const pageLoadStart = makePageLoadStart('videoPage')
export const pageLoadError = makePageLoadError('videoPage')
export const pageLoadSuccess = makePageLoadSuccess('videoPage')
export const updatePageState = makeUpdatePageState('videoPage')

export const fetchVideo = (videoId) => {
    return (dispatch) => {
        dispatch(pageLoadStart())

        return fetch(`/api/v1/videos/${videoId}?with_related=true`)
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())

                    return json.then((video) => {
                        dispatch(storeMedia(video))
                        dispatch(
                            updatePageState({
                                entityId: video.id,
                            })
                        )
                    })
                }

                dispatch(pageLoadError('Bad response from server'))
            })
            .catch((error) => console.error(error))
    }
}

export const fetchRandomVideo = (history) => {
    return (dispatch) => {
        dispatch(pageLoadStart())
        return fetch('/api/v1/videos/random')
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())
                    return json.then((video) => {
                        dispatch(storeMedia(video))
                        dispatch(
                            updatePageState({
                                entityId: video.id,
                            })
                        )
                        history.replace(`/videos/${video.slug}`)
                    })
                } else {
                    dispatch(pageLoadError('Bad response from server'))
                }
            })
            .catch((error) => console.error(error))
    }
}

export const fetchRelatedVideos = (videoId) => {
    return (dispatch) => {
        dispatch(pageLoadStart())

        return fetch(`/api/v1/videos/${videoId}/related`)
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())

                    return json.then((videos) => {
                        const normalized = normalize(videos, [mediaSchema])
                        dispatch(updateEntities(normalized.entities))
                        dispatch(
                            updatePageState({
                                relatedVideoIds: normalized.result,
                            })
                        )
                    })
                }

                dispatch(pageLoadError('Bad response from server'))
            })
            .catch((error) => console.error(error))
    }
}

export const reencodeVideo = (videoId, enqueueSnackbar, videoCodec) => {
    return (dispatch) => {
        return fetch(`/api/v1/videos/${videoId}/reencode/${videoCodec}`, {
            method: 'POST',
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((result) => {
                        if (enqueueSnackbar) {
                            enqueueSnackbar(
                                `Re-encoding to ${videoCodec}. This may take some time.`,
                                {
                                    variant: 'info',
                                }
                            )
                        }
                    })
                }

                return json.then((error) => {
                    if (enqueueSnackbar) {
                        enqueueSnackbar(
                            `Failed to submit re-encode job: ${error}`,
                            {
                                variant: 'error',
                            }
                        )
                    }
                })
            })
            .catch((error) => console.error(error))
    }
}

export const updateMetadata = (videoId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/videos/${videoId}/metadata`, { method: 'POST' })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((thumbs) => {
                        if (enqueueSnackbar) {
                            enqueueSnackbar('Generating metadata', {
                                variant: 'info',
                            })
                        }
                    })
                }

                return json.then((error) => {
                    if (enqueueSnackbar) {
                        enqueueSnackbar(`Failed to update metadata: ${error}`, {
                            variant: 'error',
                        })
                    }
                })
            })
            .catch((error) => console.error(error))
    }
}

export const generateSpectrogram = (videoId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/media/${videoId}/spectrogram`, { method: 'POST' })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    if (enqueueSnackbar) {
                        enqueueSnackbar('Generating audio spectrogram...', {
                            variant: 'info',
                        })
                    }
                    return json.then((collection) => {
                        dispatch(storeCollection(collection))
                    })
                }

                return json.then((error) => {
                    if (enqueueSnackbar) {
                        enqueueSnackbar(
                            `Failed to generate audio spectrogram: ${error}`,
                            {
                                variant: 'error',
                            }
                        )
                    }
                })
            })
            .catch((error) => console.error(error))
    }
}
