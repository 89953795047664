import { connect } from 'react-redux'

import Page from '../components/Page'
import { getPageState } from '../selectors'
import {
    setPageTitle,
    setFaviconUrl,
    updateCurrentRequest,
} from '../actions/AppState'

const mapStateToProps = (state, { pageName }) => {
    const pageState = getPageState(state, pageName)
    return {
        ...pageState,
    }
}

export default connect(
    mapStateToProps,
    {
        setPageTitle,
        setFaviconUrl,
        updateCurrentRequest,
    }
)(Page)
