const initialState = {
    isOpen: false,
    isSubmitting: false,
    videos: [],
}

function VideoUploadDialog(state = initialState, action) {
    switch (action.type) {
        case 'ADD_VIDEOS':
            return Object.assign({}, state, {
                videos: [...action.videos, ...state.videos],
            })

        case 'SUBMIT_VIDEO_UPLOAD_FORM':
            return Object.assign({}, state, {
                isSubmitting: true,
            })

        case 'SUCCESS_VIDEO_UPLOAD_FORM':
        case 'FAILED_VIDEO_UPLOAD_FORM':
            return Object.assign({}, state, {
                isSubmitting: false,
            })

        case 'OPEN_VIDEO_UPLOAD_DIALOG':
            return Object.assign({}, state, {
                isOpen: true,
            })

        case 'CLOSE_VIDEO_UPLOAD_DIALOG':
            return Object.assign({}, state, {
                isOpen: false,
            })

        default:
            return state
    }
}

export default VideoUploadDialog
