import { storeMedia } from './Entities'
import {
    makePageLoadStart,
    makePageLoadError,
    makePageLoadSuccess,
    makeUpdatePageState,
} from './PageState'

export const pageLoadStart = makePageLoadStart('videoPage')
export const pageLoadError = makePageLoadError('videoPage')
export const pageLoadSuccess = makePageLoadSuccess('videoPage')
export const updatePageState = makeUpdatePageState('videoPage')

export const fetchMedia = (mediaId, history) => {
    return (dispatch) => {
        dispatch(pageLoadStart())

        return fetch(`/api/v1/media/${mediaId}`)
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())

                    return json.then((media) => {
                        dispatch(storeMedia(media))
                        history.push(
                            `/${media.media_type}s/${
                                media.slug ? media.slug : media.id
                            }`
                        )
                    })
                }

                dispatch(pageLoadError('Bad response from server'))
            })
            .catch((error) => console.error(error))
    }
}
