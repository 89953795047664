import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'redux-form'
import { Select, TextField } from 'redux-form-material-ui'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'

import EditableTagList from './EditableTagList'
import { useAutoSearch } from '../utils'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    progress: {
        display: 'block',
        width: '100%',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    success: {
        display: 'flex',
        alignItems: 'center',
        color: 'green',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    submitIcon: {
        marginRight: theme.spacing(0.25),
    },
    grow: {
        flexGrow: 1,
    },
}))

const VideoSearchForm = ({
    autoSearchParams,
    searchData,
    onSubmit,
    onExcludeListChange,
    onIncludeListChange,
    reset,
    change,
    remoteSubmit,
    isSubmitting,
    pristine,
}) => {
    // perform an auto-search any time the autoSearchParams are modified
    useAutoSearch(autoSearchParams, onSubmit, change)

    const classes = useStyles()
    const className = classNames(classes.root, {})
    return (
        <form className={className} onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="keyword"
                            label="Keyword"
                            placeholder="keyword"
                            component={TextField}
                            fullWidth
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="user"
                            label="User"
                            placeholder="username"
                            component={TextField}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl
                        margin="normal"
                        fullWidth
                        className={classes.tagList}
                    >
                        <EditableTagList
                            label="Include Tags"
                            tags={searchData.include_tags}
                            onChange={onIncludeListChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl
                        margin="normal"
                        fullWidth
                        className={classes.tagList}
                    >
                        <EditableTagList
                            label="Exclude Tags"
                            tags={searchData.exclude_tags}
                            onChange={onExcludeListChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="min_duration"
                            label="Min. Duration"
                            placeholder="5m"
                            component={TextField}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="max_duration"
                            label="Max. Duration"
                            placeholder="30m"
                            component={TextField}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="width"
                            label="Width"
                            placeholder="1280"
                            component={TextField}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="height"
                            label="Height"
                            placeholder="720"
                            component={TextField}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={9} />
                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="sort">Sort</InputLabel>
                        <Field name="sort" label="Sort" component={Select}>
                            <MenuItem value="duration">Duration</MenuItem>
                            <MenuItem value="resolution">Resolution</MenuItem>
                            <MenuItem value="chronological">Latest</MenuItem>
                        </Field>
                    </FormControl>
                </Grid>
            </Grid>

            <CardActions className={classes.actions}>
                {searchData._successMsg && (
                    <div className={classes.success}>
                        <CheckCircleOutlineIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {searchData._successMsg}
                        </Typography>
                    </div>
                )}
                {searchData._failedMsg && (
                    <div className={classes.error}>
                        <ErrorIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {searchData._failedMsg}
                        </Typography>
                    </div>
                )}
                <div className={classes.grow} />
                <Button
                    variant="contained"
                    onClick={() => {
                        reset()

                        // unclear why these don't get cleared by the reset above
                        // it is possible these fields are not registered properly
                        onIncludeListChange([], {}, true)
                        onExcludeListChange([], {}, true)
                    }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={remoteSubmit}
                >
                    {isSubmitting ? 'Searching...' : 'Search'}
                </Button>
            </CardActions>
            {isSubmitting && (
                <div className={classes.progress}>
                    <LinearProgress />
                </div>
            )}
        </form>
    )
}

VideoSearchForm.propTypes = {
    /**
     * If passed, perform an initial search with the provided params
     */
    autoSearchParams: PropTypes.object,
    /**
     * The searchData associated with the form
     */
    searchData: PropTypes.object.isRequired,
    /**
     * Errors with the submission
     */
    errors: PropTypes.object,
    /**
     * Whether or not a submission is in progress
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Submit handler
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * Handle updates to the exclude list
     */
    onExcludeListChange: PropTypes.func.isRequired,
    /**
     * Handle updates to the include list
     */
    onIncludeListChange: PropTypes.func.isRequired,
    /**
     * Whether or not the form is unchanged
     */
    pristine: PropTypes.bool.isRequired,
    /**
     * Handler to reset form data to initial values
     */
    reset: PropTypes.func.isRequired,
    /**
     * Handler to change a form value
     */
    change: PropTypes.func.isRequired,
    /**
     * Submit function
     */
    remoteSubmit: PropTypes.func.isRequired,
}

export default VideoSearchForm
