import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Page from '../containers/Page'
import PageContainer from '../components/PageContainer'
import Placeholder from '../containers/Placeholder'

const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
        width: '75%',
        margin: `${theme.spacing()}px auto`,
    },
}))

const RandomVideoPage = ({ fetchRandomVideo }) => {
    const classes = useStyles()
    const history = useHistory()
    useEffect(() => {
        fetchRandomVideo(history)
    }, [history, fetchRandomVideo])

    return (
        <Page title="Random Video Page" pageName="randomVideoPage">
            <PageContainer>
                <Placeholder
                    classes={{ img: classes.img }}
                    label="Fetching random video..."
                />
            </PageContainer>
        </Page>
    )
}

RandomVideoPage.propTypes = {
    fetchRandomVideo: PropTypes.func,
}

export default RandomVideoPage
