import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { fade, makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
}))

const KeywordSearchForm = ({ searchUrl }) => {
    const inputEl = useRef(null)
    const history = useHistory()
    const doSearch = (keyword) => {
        history.push(`${searchUrl}?keyword=${keyword}`)
    }
    const classes = useStyles()
    return (
        <form
            action={searchUrl}
            method="get"
            className={classes.root}
            onSubmit={(e) => {
                e.preventDefault()
                doSearch(inputEl.current.value)
            }}
        >
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                name="keyword"
                inputRef={inputEl}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'Search' }}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onBlur={() => doSearch(inputEl.current.value)}
            />
        </form>
    )
}

KeywordSearchForm.propTypes = {
    /**
     * Where to send the search query
     */
    searchUrl: PropTypes.string.isRequired,
}

export default KeywordSearchForm
