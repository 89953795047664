const initialState = {}

function NavBar(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}

export default NavBar
