import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import range from 'lodash/range'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LinkIcon from '@material-ui/icons/Link'

import Link from './Link'
import VideoDetails from './VideoDetails'
import VideoPlayer from './VideoPlayer'

const rotate = (arr, count) => {
    const newArr = [...arr]
    count -= newArr.length * Math.floor(count / newArr.length)
    // eslint-disable-next-line prefer-spread
    newArr.push.apply(newArr, newArr.splice(0, count))
    return newArr
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    item: {
        width: '100%',
        height: '100%',
    },
    carouselContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '1px',
        display: 'flex',
        alignItems: 'center',
    },
    carouselItem: {
        position: 'absolute',
        boxShadow: `0 0 20px 0px ${theme.palette.grey[900]}`,
        transition: theme.transitions.create(
            ['width', 'height', 'left', 'z-index'],
            {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.complex,
            }
        ),
    },
    carouselMedia: {
        height: '100%',
        width: '100%',
    },
    controls: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: `${theme.spacing(1)}px auto`,
        width: '400px',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
}))

const Carousel = ({
    videos,
    onFocusChange,
    aspectRatio,
    opacityFloor,
    scaleFloor,
    focusSize,
    showDetails,
}) => {
    const [focusIndex, setFocusIndex] = useState(0)
    const [containerWidth, setContainerWidth] = useState(0)
    const containerEl = useRef(null)

    useEffect(() => {
        setContainerWidth(containerEl.current.clientWidth)
    }, [containerEl, setContainerWidth])

    const length = videos.length
    const midpoint = length / 2
    const rotateSlots = -1 * (Math.floor(midpoint) - focusIndex)
    const posMap = rotate(range(length), rotateSlots)

    const [wAspect, hAspect] = (aspectRatio || '16:9').split(':')

    // determine the horizontal portion of the parent element that the focused
    // content should occupy.
    const focusPortion =
        focusSize === 'xl' ? 0.9 : focusSize === 'lg' ? 0.8 : 0.6
    const focusWidth = containerWidth * focusPortion

    // the height of the carousel is determined by the aspect ratio
    const focusHeight = (focusWidth * hAspect) / wAspect

    // these helper methods scale down the size and add depth to elements
    // that are further from the focal point
    const rads = length === 1 ? 2 * Math.PI : Math.PI / (length - 1)
    const getScale = (i) => scaleFloor + (1 - scaleFloor) * Math.cos(i * rads)
    const getOpacity = (i) =>
        opacityFloor + (1 - opacityFloor) * Math.cos(i * rads)
    const getZIndex = (i) => 1 + Math.round(100 * Math.cos(i * rads))

    // this helper method is based on the lateral position in the carousel
    const getOffset = (i) =>
        (100 * (1 - Math.cos(i * rads)) * (1 - focusPortion)) / 2

    const changeFocus = (index) => {
        let normalized = index % length
        if (normalized < 0) {
            normalized += length
        }
        if (typeof onFocusChange === 'function') {
            onFocusChange(videos[normalized])
        }
        setFocusIndex(normalized)
    }

    const classes = useStyles()
    const items = videos.map((video, i) => {
        // determine the distance from the focal point, which is used
        // to scale the size and add depth
        const distance =
            midpoint - Math.abs(Math.abs(focusIndex - i) - midpoint)

        const scale = getScale(distance)
        const scaledW = focusWidth * scale
        const scaledH = focusHeight * scale
        const itemStyle = {
            width: scaledW ? `${scaledW}px` : 'auto',
            height: scaledH ? `${scaledH}px` : 'auto',
            opacity: getOpacity(distance),
            zIndex: getZIndex(distance),
        }

        // for positioning, it's helpful to know the 'true' position
        // of the element moving laterally (left to right)
        const truePos = posMap.indexOf(i)
        if (truePos < midpoint) {
            itemStyle.left = `${getOffset(truePos)}%`
        } else {
            itemStyle.left = `calc(${
                100 - getOffset(length - truePos - 1)
            }% - ${scaledW}px)`
        }

        const videoItemClass = classNames(classes.item, {
            [classes.hidden]: i !== focusIndex,
        })
        const thumbClass = classNames(classes.item, {
            [classes.hidden]: i === focusIndex,
        })
        return (
            <div key={i} className={classes.carouselItem} style={itemStyle}>
                <div className={videoItemClass}>
                    <VideoPlayer
                        video={video}
                        fixed
                        autoPlay={i === focusIndex}
                        poster={video.thumbnail}
                        controls
                        loop
                        preload={i === focusIndex ? 'auto' : 'metadata'}
                    />
                </div>
                <div className={thumbClass}>
                    <img
                        src={video.thumbnail}
                        className={classes.carouselMedia}
                        alt={video.title}
                        onClick={() => changeFocus(i)}
                    />
                </div>
            </div>
        )
    })

    const featured = videos ? videos[focusIndex] : null
    return (
        <div className={classes.root}>
            {featured && (
                <Typography
                    noWrap
                    gutterBottom
                    style={{
                        width: focusWidth,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                    display="block"
                    variant="h5"
                    align="center"
                >
                    {featured.title}
                </Typography>
            )}

            <div
                className={classes.carouselContainer}
                style={{ height: focusHeight }}
                ref={containerEl}
            >
                {items}
            </div>

            <div className={classes.controls}>
                <IconButton onClick={() => changeFocus(focusIndex - 1)}>
                    <ChevronLeftIcon fontSize="large" />
                </IconButton>
                <div className={classes.grow} />
                {featured && (
                    <Link
                        src={`/${featured.media_type}s/${
                            featured.slug || featured.id
                        }`}
                    >
                        <IconButton>
                            <LinkIcon />
                        </IconButton>
                    </Link>
                )}
                <div className={classes.grow} />
                <IconButton onClick={() => changeFocus(focusIndex + 1)}>
                    <ChevronRightIcon fontSize="large" />
                </IconButton>
            </div>

            {featured && showDetails && (
                <div
                    className={classes.details}
                    style={{ width: focusWidth, margin: '0 auto' }}
                >
                    <VideoDetails video={featured} />
                </div>
            )}
        </div>
    )
}

Carousel.propTypes = {
    /**
     * The videos that form the carousel
     */
    videos: PropTypes.array.isRequired,
    /**
     * Target aspect ratio, used to determine height of carousel. Defaults to 16:9.
     */
    aspectRatio: PropTypes.oneOf([
        '1:1',
        '5:4',
        '4:3',
        '3:2',
        '5:3',
        '16:9',
        '3:1',
    ]),
    /**
     * The minimum scale factor for carousel items farthest from focus
     */
    scaleFloor: PropTypes.number,
    /**
     * The minimum opacity for carousel items farthest from focus
     */
    opacityFloor: PropTypes.number,
    /**
     * Event handler fired when the carousel focus changes. Receives the item in focus
     */
    onFocusChange: PropTypes.func,
    /**
     * The size of the video in focus
     */
    focusSize: PropTypes.oneOf(['md', 'lg', 'xl']),
    /**
     * Whether or not to show extra information about the featured video
     */
    showDetails: PropTypes.bool,
}

Carousel.defaultProps = {
    aspectRatio: '16:9',
    scaleFloor: 0.25,
    opacityFloor: 1.0,
    focusSize: 'lg',
    showDetails: false,
}

export default Carousel
