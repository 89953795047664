import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Checkbox, TextField } from 'redux-form-material-ui'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}))

const LoginForm = ({ isSubmitting, pristine, handleSubmit }) => {
    const classes = useStyles()

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <div>
                <FormControl margin="normal" fullWidth>
                    <Field
                        name="username"
                        autoComplete="username"
                        label="Username"
                        placeholder="Username"
                        component={TextField}
                        fullWidth
                    />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <Field
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        label="Password"
                        placeholder="Password"
                        component={TextField}
                        fullWidth
                    />
                </FormControl>
                <FormGroup row>
                    <FormControlLabel
                        control={<Field name="remember" component={Checkbox} />}
                        label="Remember me?"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || pristine}
                    >
                        {isSubmitting ? 'Logging in...' : 'Login'}
                    </Button>
                </FormGroup>
            </div>
        </form>
    )
}

LoginForm.propTypes = {
    /**
     * True when the form is submitted and awaiting response from the server.
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * True if the form values have not been modified from initial values.
     */
    pristine: PropTypes.bool.isRequired,
    /**
     * Handler for form submission
     */
    handleSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
    validate: (values) => {
        const errors = {}
        const requiredFields = ['username', 'password']
        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Required'
            }
        })
        return errors
    },
})(LoginForm)
