import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'

import Loader from '../containers/Loader'
import FileDrop from '../containers/FileDrop'
import VideoForm from '../containers/VideoForm'
import VideoPlayer from './VideoPlayer'

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    loader: {
        margin: `${theme.spacing(2)}px auto`,
    },
    uploadButton: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    progress: {
        width: 60,
        margin: 'auto',
    },
}))

const VideoUploadDialog = ({ isSubmitting, videos, submitVideoFiles }) => {
    const [isOpen, setDialogOpen] = useState(false)
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const videoForms = videos.map((video, i, arr) => {
        return (
            <React.Fragment key={video.resources[0].id}>
                <VideoPlayer video={video} autoPlay controls />
                <VideoForm
                    showLink
                    form={`videoForm-resource${video.resources[0].id}`}
                    initialValues={{
                        ...video,
                        resource_id: video.resources[0].id,
                    }}
                />
                {i + 1 < arr.length && <Divider variant="middle" />}
            </React.Fragment>
        )
    })

    return (
        <>
            <Dialog
                id="video-upload-dialog"
                open={isOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="video-form-title"
            >
                <DialogTitle id="video-form-title">Upload</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <FileDrop
                        onFilesChanged={(...args) => {
                            submitVideoFiles(enqueueSnackbar, ...args)
                        }}
                    />
                    {isSubmitting && <Loader />}
                    {videoForms}
                </DialogContent>
            </Dialog>
            <Fab
                color="primary"
                aria-label="Upload"
                className={classes.uploadButton}
                onClick={() => setDialogOpen(true)}
            >
                <AddIcon />
            </Fab>
        </>
    )
}

VideoUploadDialog.propTypes = {
    /**
     * True when a file upload has begun
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * The list of videos uploaded so far
     */
    videos: PropTypes.array.isRequired,
    /**
     * Function to handle video file upload
     */
    submitVideoFiles: PropTypes.func.isRequired,
}

export default VideoUploadDialog
