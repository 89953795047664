import merge from 'lodash/merge'
import omit from 'lodash/omit'

const initialState = {
    relatedVideosById: {},
}

/**
 * Entity reducer
 *
 * @param {object} state   The current slice of state this reducer will act
 *                         upon
 * @param {object} action  The action being performed
 * @return {object}        The new state
 */
export default function Entities(state = initialState, action) {
    const { entityType, entityId, entity } = action
    switch (action.type) {
        case 'REMOVE_ENTITY':
            return omit(state, [`${entityType}.${entityId}`])

        case 'REPLACE_ENTITY':
            return Object.assign({}, state, {
                [entityType]: {
                    ...state[entityType],
                    [entity.id]: entity,
                },
            })

        case 'UPDATE_ENTITIES':
            if (action.entities) {
                return merge({}, state, action.entities)
            }
            return state

        case 'APPEND_COLLECTION':
            return Object.assign({}, state, {
                media: {
                    ...state.media,
                    [action.sourceId]: {
                        ...state.media[action.sourceId],
                        collections: [
                            ...state.media[action.sourceId].collections,
                            action.collectionId,
                        ],
                    },
                },
            })

        case 'REMOVE_COLLECTION':
            return Object.assign({}, state, {
                media: {
                    ...state.media,
                    [action.sourceId]: {
                        ...state.media[action.sourceId],
                        collections: state.media[
                            action.sourceId
                        ].collections.filter(
                            (cid) => cid !== action.collectionId
                        ),
                    },
                },
                collections: {
                    ...omit(state.collections, [action.collectionId]),
                },
            })

        default:
            return state
    }
}
