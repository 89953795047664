import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { RadioGroup, TextField } from 'redux-form-material-ui'
import SwipeableViews from 'react-swipeable-views'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Switch from '@material-ui/core/Switch'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import AudioIcon from '@material-ui/icons/Audiotrack'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import GifIcon from '@material-ui/icons/Gif'
import ImageIcon from '@material-ui/icons/Image'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'
import VideoIcon from '@material-ui/icons/Videocam'

import ReelDimensions from './ReelDimensions'
import ReelInterval from './ReelInterval'
import VideoPlayer from './VideoPlayer'

const useStyles = makeStyles((theme) => ({
    root: {},
    tabLabel: {
        margin: 0,
    },
    progress: {
        display: 'block',
        width: '100%',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    success: {
        display: 'flex',
        alignItems: 'center',
        color: 'green',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    submitIcon: {
        marginRight: theme.spacing(0.25),
    },
    wxh: {
        display: 'flex',
        flexDirection: 'column',
    },
    grow: {
        flexGrow: 1,
    },
    shadowVideo: {
        display: 'none',
        width: '1px',
        height: '1px',
    },
}))

const ReelForm = ({
    form,
    change,
    collection,
    source,
    errors,
    onSubmit,
    remoteSubmit,
    isSubmitting,
    pristine,
}) => {
    const classes = useStyles()

    const itemTypes = ['video', 'gif', 'image', 'sound_bite']

    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
        change('item_type', itemTypes[newValue])
    }
    const handleChangeIndex = (newValue) => {
        setTabIndex(newValue)
        change('item_type', itemTypes[newValue])
    }

    // used to constrain width and height to the same aspect ratio
    // not yet implemented
    const [isConstrained, setConstrained] = useState(true)

    // used to communicate between the interval and the dimension display
    const sourceVideoRef = useRef()
    const canvasRef = useRef()
    const [lastTimestamp, setLastTimestamp] = useState(null)
    const [snapshots, setSnapshots] = useState({})
    const updateSnapshot = (timestamp) => {
        if (timestamp === lastTimestamp) {
            return
        }
        setLastTimestamp(timestamp)
        if (Object.keys(snapshots).includes(timestamp)) {
            return
        }

        const vid = sourceVideoRef.current
        const canvas = canvasRef.current
        if (!vid || !canvas) {
            return
        }

        const ctx = canvas.getContext('2d')
        vid.addEventListener(
            'seeked',
            (evt) => {
                ctx.drawImage(vid, 0, 0, canvas.width, canvas.height)
                setSnapshots({
                    ...snapshots,
                    [timestamp]: canvas.toDataURL('image/jpeg'),
                })
            },
            { once: true, passive: true }
        )

        // now seek
        vid.currentTime = timestamp
    }

    if (!source) {
        return <div>Nothing here</div>
    }

    const makeTab = (itemType) => {
        const icons = {
            video: VideoIcon,
            gif: GifIcon,
            image: ImageIcon,
            sound_bite: AudioIcon,
        }
        const TabIcon = icons[itemType]

        return (
            <Tab
                key={itemType}
                label={
                    <FormControlLabel
                        value={itemType}
                        classes={{ root: classes.tabLabel }}
                        control={
                            <Radio
                                icon={<TabIcon fontSize="small" />}
                                checkedIcon={<TabIcon color="primary" />}
                            />
                        }
                    />
                }
            />
        )
    }

    const makePanel = (itemType) => {
        const hasDimensions =
            itemType === 'video' || itemType === 'gif' || itemType === 'image'
        const hasDuration =
            itemType === 'video' ||
            itemType === 'gif' ||
            itemType === 'sound_bite'
        return (
            <div key={itemType}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl margin="dense">
                            <Field
                                name="count"
                                label="Count"
                                placeholder="Count"
                                variant="outlined"
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl margin="dense">
                            <Field
                                name="start"
                                label="Start"
                                placeholder="60"
                                variant="outlined"
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl margin="dense">
                            <Field
                                name="end"
                                label="End"
                                placeholder="-60"
                                variant="outlined"
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    {hasDuration && (
                        <Grid item xs={3}>
                            <FormControl margin="dense">
                                <Field
                                    name="duration"
                                    label="Duration"
                                    placeholder="Duration"
                                    variant="outlined"
                                    component={TextField}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

                {hasDimensions && (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div className={classes.wxh}>
                                <FormControl margin="dense">
                                    <Field
                                        name="width"
                                        label="Width"
                                        placeholder="Width"
                                        variant="outlined"
                                        component={TextField}
                                    />
                                </FormControl>
                                <FormControl margin="dense">
                                    <Field
                                        name="height"
                                        label="Height"
                                        placeholder="Height"
                                        variant="outlined"
                                        component={TextField}
                                    />
                                </FormControl>
                                <FormControl margin="dense">
                                    <FormControlLabel
                                        label="Constrain"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={isConstrained}
                                                onChange={() =>
                                                    setConstrained(
                                                        !isConstrained
                                                    )
                                                }
                                            />
                                        }
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <ReelDimensions
                                background={snapshots[lastTimestamp]}
                                sourceW={source.width}
                                sourceH={source.height}
                                itemW={collection.width}
                                itemH={collection.height}
                                size="large"
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }

    return (
        <form className={classes.root} onSubmit={onSubmit}>
            {/**
             * These elements help power the preview-screenshot-at-timestamp
             * feature. It helps to use a separate video so that it's possible
             * to seek to a particular timestamp for capturing without
             * interfering with the main video.
             */}
            <VideoPlayer
                crossOrigin="anonymous"
                video={source}
                ref={sourceVideoRef}
                classes={{ root: classes.shadowVideo }}
                preload="auto"
            />
            <canvas className={classes.shadowVideo} ref={canvasRef} />

            <Field
                className={classes.mediaType}
                name="item_type"
                row
                component={RadioGroup}
            >
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textcolor="primary"
                    variant="fullWidth"
                >
                    {itemTypes.map(makeTab)}
                </Tabs>
            </Field>

            {source && (
                <ReelInterval
                    count={collection.count}
                    start={collection.start}
                    end={collection.end}
                    stepDuration={collection.duration}
                    totalDuration={source.duration}
                    onPreview={updateSnapshot}
                />
            )}

            <SwipeableViews index={tabIndex} onChangeIndex={handleChangeIndex}>
                {itemTypes.map(makePanel)}
            </SwipeableViews>

            <CardActions className={classes.actions}>
                {collection._successMsg && (
                    <div className={classes.success}>
                        <CheckCircleOutlineIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {collection._successMsg}
                        </Typography>
                    </div>
                )}
                {collection._failedMsg && (
                    <div className={classes.error}>
                        <ErrorIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {collection._failedMsg}
                        </Typography>
                    </div>
                )}

                <div className={classes.grow} />

                <FormControl margin="dense">
                    <InputLabel shrink>Visibility</InputLabel>

                    <Field
                        className={classes.visibility}
                        name="visibility"
                        row
                        component={RadioGroup}
                    >
                        <FormControlLabel
                            value="private"
                            control={
                                <Radio
                                    icon={<LockIcon fontSize="small" />}
                                    checkedIcon={
                                        <LockIcon
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            value="public"
                            control={
                                <Radio
                                    icon={<PublicIcon fontSize="small" />}
                                    checkedIcon={
                                        <PublicIcon
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    }
                                />
                            }
                        />
                    </Field>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || pristine}
                    onClick={remoteSubmit}
                >
                    {isSubmitting
                        ? 'Submitting...'
                        : collection && collection.id
                        ? 'Update'
                        : 'Create'}
                </Button>
            </CardActions>

            {isSubmitting && (
                <div className={classes.progress}>
                    <LinearProgress />
                </div>
            )}
        </form>
    )
}

ReelForm.propTypes = {
    /**
     * The name of the redux form
     */
    form: PropTypes.string.isRequired,
    /**
     * Action to change a form value
     */
    change: PropTypes.func.isRequired,
    /**
     * The collection associated with the reel
     */
    collection: PropTypes.object.isRequired,
    /**
     * The source from which the collection will be generated
     */
    source: PropTypes.object.isRequired,
    /**
     * Errors with the submission
     */
    errors: PropTypes.object,
    /**
     * Whether or not a submission is in progress
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Submit handler
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * Call the submit handler explicitly
     */
    remoteSubmit: PropTypes.func,
    /**
     * Whether or not the form is unchanged
     */
    pristine: PropTypes.bool.isRequired,
}

export default reduxForm({
    validate: (values) => {
        const errors = {}
        const requiredFields = ['count']
        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Required'
            }
        })
        return errors
    },
})(ReelForm)
