import FileDrop from '../components/FileDrop'
import { dragEnter, dragLeave } from '../actions/FileDrop'
import { connect } from 'react-redux'

const mapStateToProps = ({ fileDrop }) => ({
    isDragOver: fileDrop.dragOver,
})

const mapDispatchToProps = (dispatch, { onFilesChanged }) => ({
    addFiles: (event) => {
        onFilesChanged(event.target.files)
    },
    dropFiles: (event) => {
        event.preventDefault()
        dispatch(dragLeave(event))
        onFilesChanged(event.dataTransfer.files)
    },
    dragEnter: (event) => dispatch(dragEnter(event)),
    dragLeave: (event) => dispatch(dragLeave(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileDrop)
