const initialState = {
    featuredVideoIds: [],
}

function HomePage(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_FEATURED_VIDEO_IDS':
            return {
                ...state,
                featuredVideoIds: action.videoIds,
            }

        default:
            return state
    }
}

export default HomePage
