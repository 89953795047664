export const userLogin = (userData) => ({
    type: 'USER_LOGIN',
    userData,
})
export const userLogout = () => ({
    type: 'USER_LOGOUT',
})
export const userLoad = (userData) => ({
    type: 'USER_LOAD',
    userData,
})

export const loadUserFromSession = () => {
    return (dispatch) => {
        try {
            let userData = localStorage.getItem('user')
            if (userData) {
                userData = JSON.parse(userData)
                dispatch(userLoad(userData))
            }
            return userData
        } catch (err) {
            console.error('Unable to parse user data')
        }
    }
}

export const submitForm = (form, values) => {
    return (dispatch) => {
        return fetch('/api/v1/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: values.username,
                password: values.password,
            }),
        }).then((res) => {
            if (res.ok) {
                res.json().then((userData) => {
                    dispatch(userLogin(userData))
                })
            } else {
                console.error('Login failed.')
            }
        })
    }
}

export const logout = (history) => {
    return (dispatch) => {
        dispatch(userLogout())

        return fetch('/api/v1/auth/logout', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (!res.ok) {
                console.error('Logout failed.')
            }
            history.push('/')
        })
    }
}
