import React from 'react'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Typography from '@material-ui/core/Typography'

import Link from './Link'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(1, 0),
    },
    grow: {
        flexGrow: '1',
    },
    current: {
        alignSelf: 'center',
        textAlign: 'center',
        width: '48px',
        margin: theme.spacing(0, 1),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
        borderRadius: '100px',
    },
    nearby: {
        alignSelf: 'center',
        textAlign: 'center',
        width: '48px',
        margin: theme.spacing(0, 1),
        color: theme.palette.grey[600],
    },
    nearbyLabel: {
        width: '24px',
    },
}))

const Pagination = ({
    prev,
    next,
    current,
    numPages,
    showNearby,
    showFirstLast,
    onPageChange,
}) => {
    const classes = useStyles()
    const { pathname, search } = useLocation()

    if (!current) {
        return <div className={classes.root} />
    }

    const makeUrl = (pageNum) => {
        const newParams = new URLSearchParams(search)
        newParams.set('page', pageNum)
        return `${pathname}?${newParams.toString()}`
    }

    let prevPages
    let nextPages
    if (showNearby) {
        const neighbors = 3
        const prevPageNums = range(current - neighbors, current)
        const nextPageNums = range(current + 1, current + neighbors + 1)
        const makePages = (pages, filterFn) => {
            return pages.map((pageNum) => (
                <div className={classes.nearby} key={pageNum}>
                    {filterFn(pageNum) && (
                        <Link src={makeUrl(pageNum)} color="inherit">
                            <IconButton
                                classes={{ label: classes.nearbyLabel }}
                                onClick={
                                    onPageChange
                                        ? (evt) => {
                                              evt.preventDefault()
                                              onPageChange(pageNum)
                                          }
                                        : undefined
                                }
                                color="inherit"
                            >
                                <Typography variant="button">
                                    {pageNum}
                                </Typography>
                            </IconButton>
                        </Link>
                    )}
                </div>
            ))
        }
        prevPages = makePages(prevPageNums, (pageNum) => pageNum >= 1)
        nextPages = makePages(nextPageNums, (pageNum) => pageNum <= numPages)
    }

    return (
        <div className={classes.root}>
            {showFirstLast &&
                (current > 1 ? (
                    <Link src={makeUrl(1)} color="inherit">
                        <IconButton
                            color="inherit"
                            onClick={
                                onPageChange
                                    ? (evt) => {
                                          evt.preventDefault()
                                          onPageChange(1)
                                      }
                                    : undefined
                            }
                        >
                            <FirstPageIcon fontSize="large" />
                        </IconButton>
                    </Link>
                ) : (
                    <IconButton disabled color="inherit">
                        <FirstPageIcon fontSize="large" />
                    </IconButton>
                ))}

            {prev ? (
                <Link src={makeUrl(prev)} color="inherit">
                    <IconButton
                        color="inherit"
                        onClick={(evt) => {
                            evt.preventDefault()
                            onPageChange(prev)
                        }}
                    >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                </Link>
            ) : (
                <IconButton disabled color="inherit">
                    <ChevronLeftIcon fontSize="large" />
                </IconButton>
            )}

            <div className={classes.grow} />
            {prevPages}
            <div className={classes.current}>
                <Typography variant="h6">{current}</Typography>
            </div>
            {nextPages}
            <div className={classes.grow} />

            {next ? (
                <Link src={makeUrl(next)} color="inherit">
                    <IconButton
                        color="inherit"
                        onClick={(evt) => {
                            evt.preventDefault()
                            onPageChange(next)
                        }}
                    >
                        <ChevronRightIcon fontSize="large" />
                    </IconButton>
                </Link>
            ) : (
                <IconButton disabled color="inherit">
                    <ChevronRightIcon fontSize="large" />
                </IconButton>
            )}

            {showFirstLast &&
                (current < numPages ? (
                    <Link src={makeUrl(numPages)} color="inherit">
                        <IconButton
                            color="inherit"
                            onClick={(evt) => {
                                evt.preventDefault()
                                onPageChange(numPages)
                            }}
                        >
                            <LastPageIcon fontSize="large" />
                        </IconButton>
                    </Link>
                ) : (
                    <IconButton disabled color="inherit">
                        <LastPageIcon fontSize="large" />
                    </IconButton>
                ))}
        </div>
    )
}

Pagination.propTypes = {
    /**
     * Previous page, if any
     */
    prev: PropTypes.number,
    /**
     * Next page, if any
     */
    next: PropTypes.number,
    /**
     * Current page
     */
    current: PropTypes.number,
    /**
     * Total number of pages
     */
    numPages: PropTypes.number,
    /**
     * Whether or not to show links to first and last pages
     */
    showFirstLast: PropTypes.bool,
    /**
     * Whether or not to show links to nearby pages
     */
    showNearby: PropTypes.bool,
    /**
     * Optional function to call when the page changes
     */
    onPageChange: PropTypes.func,
}

export default Pagination
