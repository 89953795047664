import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

import JobCard from '../components/JobCard/JobCard'
import PageContainer from '../components/PageContainer'
import Page from '../containers/Page'

const JobPage = ({ job, jobId, fetchJob, cancelJob, retryJob }) => {
    const { enqueueSnackbar } = useSnackbar()

    // fetch the job by ID if it isn't already loaded
    const hasJob = job !== undefined
    useEffect(() => {
        if (!hasJob && jobId) {
            fetchJob(jobId)
        }
    }, [hasJob, jobId, fetchJob])

    if (!hasJob) {
        return (
            <Page title="Job Page" pageName="jobPage">
                No job
            </Page>
        )
    }

    const jobTitle = 'Job'
    const jobProps = {
        job,
        cancelJob,
        retryJob,
        enqueueSnackbar,
    }
    return (
        <Page title={jobTitle} pageName="jobPage">
            <PageContainer>
                <JobCard expanded {...jobProps} />
            </PageContainer>
        </Page>
    )
}

JobPage.propTypes = {
    /**
     * The job to render
     */
    job: PropTypes.object,
    /**
     * The ID of the job
     */
    jobId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Fuction used to fetch job data from the server
     */
    fetchJob: PropTypes.func.isRequired,
    /**
     * Handler for cancelling a job
     */
    cancelJob: PropTypes.func,
    /**
     * Handler for retrying a job
     */
    retryJob: PropTypes.func,
}

export default JobPage
