import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close'
import LinkIcon from '@material-ui/icons/Link'
import SeekIcon from '@material-ui/icons/FastForward'
import MagnifyIcon from '@material-ui/icons/ZoomIn'
import ThumbnailIcon from '@material-ui/icons/Image'
import Tooltip from '@material-ui/core/Tooltip'

import Link from './Link'
import TwitterIcon from './TwitterIcon'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        '&:hover .slider': {
            right: 0,
        },
    },
    scroll: { overflow: 'scroll' },
    inline: {
        display: 'inline-flex',
        width: 'auto',
    },
    hidden: {
        display: 'none',
    },
    actionSlider: {
        display: 'flex',
        transition: 'right 0.25s ease',
        position: 'absolute',
        right: '-250px',
        top: theme.spacing(1),
        background: fade(theme.palette.grey[800], 0.4),
        padding: theme.spacing(0.5),
        borderTopLeftRadius: '24px',
        borderBottomLeftRadius: '24px',
    },
    action: {
        marginRight: theme.spacing(0.5),
    },
    actionTooltip: {
        background: fade(theme.palette.grey[800], 0.4),
    },
    media: {
        display: 'inline-block',
        objectFit: 'contain',
    },
    fullWidth: {
        width: '100%',
        height: '100%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const ReelItem = ({
    media,
    source,
    inline,
    hidden,
    onShare,
    onSeek,
    setThumbnail,
    showActions,
}) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const sliderClass = classNames('slider', classes.actionSlider)
    const [isMagnified, setMagnified] = useState(false)

    const makeMediaNode = (mediaType, mediaClasses, onClick) => {
        if (mediaType === 'video') {
            return <video className={mediaClasses} src={media.url} controls />
        } else if (mediaType === 'gif' || mediaType === 'image') {
            return (
                <img
                    className={mediaClasses}
                    src={media.url}
                    alt={`${media.seek}`}
                    onClick={onClick}
                />
            )
        } else if (mediaType === 'sound_bite') {
            // for inspiration see:
            //   https://vuejsexamples.com/vue-html5-audio-visualization-components/
            return <audio className={mediaClasses} src={media.url} controls />
        }
    }
    const mediaNode = makeMediaNode(
        media.media_type,
        classNames(classes.media, {
            [classes.fullWidth]: !inline,
        }),
        () => setMagnified(!isMagnified)
    )
    const modalMediaNode = makeMediaNode(media.media_type, classes.media)

    const actions = (
        <div className={sliderClass}>
            <Tooltip
                title="Magnify"
                placement="top"
                classes={{ tooltip: classes.actionTooltip }}
            >
                <Fab
                    className={classes.action}
                    aria-label="Magnify"
                    size="small"
                    onClick={() => setMagnified(!isMagnified)}
                >
                    <MagnifyIcon fontSize="small" />
                </Fab>
            </Tooltip>
            {media.media_type === 'video' && (
                <Tooltip
                    title="Navigate"
                    placement="top"
                    classes={{ tooltip: classes.actionTooltip }}
                >
                    <Link src={media.publicUrl} color="inherit">
                        <Fab
                            className={classes.action}
                            aria-label="Navigate"
                            size="small"
                            onClick={() => false}
                        >
                            <LinkIcon fontSize="small" />
                        </Fab>
                    </Link>
                </Tooltip>
            )}
            {onSeek && (
                <Tooltip
                    title="Seek"
                    placement="top"
                    classes={{ tooltip: classes.actionTooltip }}
                >
                    <Fab
                        className={classes.action}
                        aria-label="Seek"
                        size="small"
                        onClick={(...args) => onSeek(media.seek, ...args)}
                    >
                        <SeekIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            )}
            {setThumbnail && source && (
                <Tooltip
                    title="Set Thumbnail"
                    placement="top"
                    classes={{ tooltip: classes.actionTooltip }}
                >
                    <Fab
                        className={classes.action}
                        aria-label="Assign Thumbnail"
                        size="small"
                        onClick={(...args) => {
                            setThumbnail(
                                enqueueSnackbar,
                                media.id,
                                source.id,
                                ...args
                            )
                        }}
                    >
                        <ThumbnailIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            )}
            {onShare && (
                <Tooltip
                    title="Share on Twitter"
                    placement="top"
                    classes={{ tooltip: classes.actionTooltip }}
                >
                    <Fab
                        className={classes.action}
                        aria-label="Share"
                        onClick={(...args) => onShare(media, ...args)}
                        size="small"
                    >
                        <TwitterIcon />
                    </Fab>
                </Tooltip>
            )}
        </div>
    )

    const itemClasses = classNames(classes.root, {
        [classes.inline]: inline,
        [classes.hidden]: hidden,
    })
    return (
        <React.Fragment>
            <div className={itemClasses}>
                {mediaNode}
                {showActions && actions}
            </div>
            <Modal
                className={classes.modal}
                open={isMagnified}
                disableRestoreFocus
                onClose={() => setMagnified(false)}
            >
                <Card>
                    <CardHeader
                        action={
                            <IconButton onClick={() => setMagnified(false)}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent classes={{ root: classes.scroll }}>
                        {modalMediaNode}
                    </CardContent>
                </Card>
            </Modal>
        </React.Fragment>
    )
}

ReelItem.propTypes = {
    /**
     * Whether or not to display items inline
     */
    inline: PropTypes.bool,
    /**
     * The media to render
     */
    media: PropTypes.object.isRequired,
    /**
     * The source from which the media was derived, if any.
     */
    source: PropTypes.object,
    /**
     * Handler to seek a video to the specified time
     */
    onSeek: PropTypes.func,
    /**
     * Handler to render share box
     */
    onShare: PropTypes.func,
    /**
     * Handler for setting the item as as the thumbnail for a video
     */
    setThumbnail: PropTypes.func,
    /**
     * When true, the item is hidden
     */
    hidden: PropTypes.bool,
    /**
     * When true, show actions related to the item
     */
    showActions: PropTypes.bool,
}

export default ReelItem
