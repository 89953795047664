export const userLogin = (userData) => ({
    type: 'USER_LOGIN',
    userData,
})
export const userLogout = () => ({
    type: 'USER_LOGOUT',
})
export const storeUser = (userData) => ({
    type: 'STORE_USER',
    userData,
})

export const loadUserFromSession = () => {
    return (dispatch) => {
        try {
            let userData = localStorage.getItem('user')
            if (userData) {
                userData = JSON.parse(userData)
                dispatch(storeUser(userData))
            }
            return userData
        } catch (err) {
            console.error(
                'Unable to parse user data',
                localStorage.getItem('user')
            )
        }
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch(userLogout())

        return fetch('/api/v1/auth/logout', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (!res.ok) {
                console.error('Logout failed.')
            }
        })
    }
}
