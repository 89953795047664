import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

const placeholderChoices = [
    '/static/position-accordion.svg',
    '/static/position-angry-pirate.svg',
    '/static/position-aphrodite.svg',
    '/static/position-aquarius-2.svg',
    '/static/position-aquarius.svg',
    '/static/position-arch.svg',
    '/static/position-barbell-2.svg',
    '/static/position-barbell.svg',
    '/static/position-basket.svg',
    '/static/position-basset-hound-2.svg',
    '/static/position-basset-hound.svg',
    '/static/position-bow.svg',
    '/static/position-bud.svg',
    '/static/position-cancer.svg',
    '/static/position-capricorn.svg',
    '/static/position-captain.svg',
    '/static/position-caramel.svg',
    '/static/position-cello.svg',
    '/static/position-chair.svg',
    '/static/position-crucifixion.svg',
    '/static/position-dragon.svg',
    '/static/position-ecstasy.svg',
    '/static/position-eiffel-tower.svg',
    '/static/position-frog.svg',
    '/static/position-gemini-2.svg',
    '/static/position-gemini.svg',
    '/static/position-italian-chandelier.svg',
    '/static/position-joystick.svg',
    '/static/position-lock.svg',
    '/static/position-london-bridge.svg',
    '/static/position-masturbation.svg',
    '/static/position-narcissus.svg',
    '/static/position-nirvana.svg',
    '/static/position-nun.svg',
    '/static/position-piledriver.svg',
    '/static/position-pisces.svg',
    '/static/position-prison-guard.svg',
    '/static/position-rabbit-ears.svg',
    '/static/position-rainbow.svg',
    '/static/position-reverse-cowgirl.svg',
    '/static/position-ribbon.svg',
    '/static/position-rose.svg',
    '/static/position-scissor.svg',
    '/static/position-spiderman.svg',
    '/static/position-spoon.svg',
    '/static/position-starfish.svg',
    '/static/position-straddle.svg',
    '/static/position-submissive.svg',
    '/static/position-superman.svg',
    '/static/position-train.svg',
    '/static/position-wall.svg',
    '/static/position-waterfall.svg',
    '/static/position-wheelbarrow.svg',
    '/static/position-whisper.svg',
    '/static/position-workout.svg',
    '/static/position-yin-yang.svg',
    '/static/position-zeus.svg',
]

const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
        maxWidth: '100%',
    },
}))

const Placeholder = ({ isPublic, label, classes, base }) => {
    classes = { ...useStyles(), ...classes }
    const idx = Math.floor(Math.random() * placeholderChoices.length)
    base = base === undefined ? '/static' : base
    const src = isPublic
        ? `${base}/placeholder.svg`
        : placeholderChoices[idx].replace('/static', base)

    return <img className={classes.img} alt={label || src} src={src} />
}

Placeholder.propTypes = {
    /**
     * Optionally render a different style loader
     */
    isPublic: PropTypes.bool,
    /**
     * The label to use with the loader. Defaults to 'Loading...'
     */
    label: PropTypes.string,
    /**
     * Style overrides for subcomponents
     */
    classes: PropTypes.object,
    /**
     * Optional base page for static assets. Defaults to '/static'
     */
    base: PropTypes.string,
}

export default Placeholder
