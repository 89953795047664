import { change } from 'redux-form'
import { jobSchema } from '../schema'
import { storeJob } from './Entities'
import {
    makePageLoadStart,
    makePageLoadError,
    makePageLoadSuccess,
    makeUpdateSearchResults,
} from './PageState'
import { doSearch } from './VideoSearchForm'

export const pageLoadStart = makePageLoadStart('jobSearch')
export const pageLoadError = makePageLoadError('jobSearch')
export const pageLoadSuccess = makePageLoadSuccess('jobSearch')
export const updateSearchResults = makeUpdateSearchResults('jobSearch')

export const goToPage = (page) => {
    return (dispatch) => {
        dispatch(change('jobSearch', 'page', page))
        dispatch(doJobSearch())
    }
}

export const doJobSearch = (searchCriteria) => {
    return (dispatch) => {
        return dispatch(
            doSearch(
                'jobSearch',
                '/api/v1/jobs',
                jobSchema,
                updateSearchResults,
                searchCriteria
            )
        )
    }
}

export const fetchJob = (jobId) => {
    return (dispatch) => {
        return fetch(`/api/v1/jobs/${jobId}`)
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((job) => {
                        dispatch(storeJob(job))
                    })
                }
            })
            .catch((error) => console.error(error))
    }
}

export const cancelJob = (jobId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/jobs/${jobId}/cancel`, {
            method: 'POST',
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((data) => {
                        dispatch(storeJob(data.result))
                        enqueueSnackbar('Job cancelled!', {
                            variant: 'success',
                        })
                    })
                }
            })
            .catch((error) => console.error(error))
    }
}

export const retryJob = (jobId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/jobs/${jobId}/retry?force=true`, {
            method: 'POST',
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((data) => {
                        dispatch(storeJob(data.result))
                        enqueueSnackbar('Successfully re-submitted the job!', {
                            variant: 'success',
                        })
                    })
                }
            })
            .catch((error) => console.error(error))
    }
}
