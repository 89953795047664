import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useQuery } from '../utils'
import LoginForm from '../containers/LoginForm'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
    },
    h1: {
        fontSize: '2rem',
    },
    paper: {
        padding: theme.spacing(3),
    },
}))

const LandingPage = ({ background }) => {
    const classes = useStyles()
    const query = useQuery()

    const rootStyles = {}
    if (background) {
        rootStyles.backgroundImage = `url('${background}')`
        rootStyles.backgroundSize = '100% 100%'
    }

    return (
        <div className={classes.root} style={rootStyles}>
            <Paper classes={{ root: classes.paper }}>
                <Typography variant="h1" classes={{ h1: classes.h1 }}>
                    This site requires login.
                </Typography>
                <LoginForm
                    form="landing-login"
                    initialValues={{ next: query.get('next') }}
                />
            </Paper>
        </div>
    )
}

LandingPage.propTypes = {
    /**
     * An optional background image to use for the langing page
     */
    background: PropTypes.string,
}

export default LandingPage
