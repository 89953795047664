import { connect } from 'react-redux'
import { getFormValues, getFormSubmitErrors, isSubmitting } from 'redux-form'

import { submitForm } from '../actions/LoginForm'
import LoginForm from '../components/LoginForm'

const mapStateToProps = (state, { form }) => ({
    values: getFormValues(form)(state) || {},
    errors: getFormSubmitErrors(form)(state) || {},
    isSubmitting: isSubmitting(form)(state) || false,
})

const mapDispatchToProps = (dispatch, { form }) => ({
    onSubmit: (...args) => dispatch(submitForm(form, ...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
