import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ user, children, ...rest }) => {
    const loggedIn = user && user.id

    return (
        <Route
            {...rest}
            render={({ location }) =>
                loggedIn ? children : <Redirect to={{}} />
            }
        />
    )
}

PrivateRoute.propTypes = {
    /**
     * The current user
     */
    user: PropTypes.object,
    /**
     * Children to render if the user is authenticated
     */
    children: PropTypes.node,
}

export default PrivateRoute
