export const makePageLoadStart = (pageName) => {
    return () => ({
        type: `PAGE_LOAD_START_${pageName}`,
    })
}
export const makePageLoadError = (pageName) => {
    return (error) => ({
        type: `PAGE_LOAD_ERROR_${pageName}`,
        error,
    })
}
export const makePageLoadSuccess = (pageName) => {
    return () => ({
        type: `PAGE_LOAD_SUCCESS_${pageName}`,
    })
}

export const makeUpdatePageState = (pageName) => {
    return (data) => ({
        type: `UPDATE_PAGE_STATE_${pageName}`,
        data,
    })
}

export const makeUpdatePagination = (pageName) => {
    return (pagination) => ({
        type: `UPDATE_PAGINATION_${pageName}`,
        pagination,
    })
}

export const makeUpdateSearchResults = (pageName) => {
    return (entityIds, pagination, searchCriteria) => ({
        type: `UPDATE_SEARCH_RESULTS_${pageName}`,
        entityIds,
        pagination,
        searchCriteria,
    })
}
