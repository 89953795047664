import React from 'react'
import PropTypes from 'prop-types'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'

const VisibilityIcon = ({ visibility, ...iconProps }) => {
    if (visibility === 'public') {
        return <PublicIcon {...iconProps} />
    }

    return <LockIcon {...iconProps} />
}

VisibilityIcon.propTypes = {
    visibility: PropTypes.oneOf(['public', 'private']).isRequired,
}

export default VisibilityIcon
