import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import PollableJobCard from '../../containers/PollableJobCard'

const useStyles = makeStyles((theme) => ({
    flexWrap: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        display: 'block',
        flexBasis: '50%',
        flexGrow: 2,
        backgroundColor: theme.palette.grey[200],
        width: '50%',
    },
    full: {
        flexBasis: '100%',
    },
}))

const GenerateCollectionCard = ({ job, ...props }) => {
    const classes = useStyles()
    const link = `/collections/${job.args[0]}`
    const subtitleComponents = [`Collection ID: ${job.args[0]}`]
    let preview

    if (job.state === 'success') {
        const numOperations = job.meta.transform.operations.length
        const outputType =
            job.meta.transform.operations[numOperations - 1].output_type
        // the format of the job output has changed over time, so check both
        // places
        let results = job.result
        if (results.constructor !== Array) {
            if (!job.result.result) {
                console.log(job.result)
                results = []
            } else {
                results = job.result.result.items
            }
        }

        subtitleComponents.push(`Output: ${outputType} (${results.length})`)

        const items = results.map((res, idx) => {
            const itemClass = classNames(classes.item, {
                [classes.full]: (idx + 1) % 5 === 0,
            })
            if (outputType === 'image' || outputType === 'gif') {
                return (
                    <img
                        key={res.url}
                        alt={idx}
                        className={itemClass}
                        src={res.url}
                    />
                )
            } else if (outputType === 'video') {
                return (
                    <video
                        key={res.url}
                        className={itemClass}
                        src={res.url}
                        preload="none"
                        controls
                    />
                )
            } else if (outputType === 'sound_bite') {
                return (
                    <audio
                        key={res.url}
                        className={itemClass}
                        src={res.url}
                        preload="none"
                        controls
                    />
                )
            }

            return <span key={res.url}>{res.url}</span>
        })
        preview = <div className={classes.flexWrap}>{items}</div>
    }

    return (
        <PollableJobCard
            pollDelay={20000}
            job={job}
            subtitle={<span>{subtitleComponents.join(' • ')}</span>}
            link={link}
            preview={preview}
            {...props}
        />
    )
}

GenerateCollectionCard.propTypes = {
    /**
     * The job to render
     */
    job: PropTypes.object.isRequired,
}

export default GenerateCollectionCard
