import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useInterval } from '../utils'
import Stat from './Stat'

/**
 * Helper to compute the diff between to dates
 *
 * @param {str} start  The start date
 * @param {str} end    The end date
 * @return {diff?}
 */
const computeDiff = (start, end) => {
    if (!start) {
        return null
    }

    end = end ? moment.utc(end) : moment.utc()
    start = moment.utc(start)
    return end.diff(start)
}

const Timer = ({ durationFmt, startDate, endDate, active, ...statProps }) => {
    const [delay, setDelay] = useState(null)
    const [diff, setDiff] = useState(null)

    useEffect(() => {
        // only start ticking if the caller has requested an active timer
        // and the duration is currently changing
        if (active && startDate && !endDate) {
            setDelay(256)
        } else {
            setDelay(null)
        }

        return () => {
            setDelay(null)
        }
    }, [active, startDate, endDate, setDelay])
    useInterval(() => {
        setDiff(computeDiff(startDate, endDate))
    }, delay)

    // ensures we compute the diff at least once even if not active
    useEffect(() => {
        if (!diff) {
            setDiff(computeDiff(startDate, endDate))
        }
    }, [diff, setDiff, startDate, endDate])

    if (!durationFmt) {
        durationFmt = 'HH:mm:ss.SSS'
    }
    const duration =
        startDate && endDate
            ? moment.utc(computeDiff(startDate, endDate)).format(durationFmt)
            : diff !== null
            ? moment.utc(diff).format(durationFmt)
            : 'N/A'

    return <Stat {...statProps}>{duration}</Stat>
}

Timer.propTypes = {
    label: PropTypes.string.isRequired,
    durationFmt: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    active: PropTypes.bool,
}

export default Timer
