import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Page from '../containers/Page'
import PageContainer from '../components/PageContainer'
import Placeholder from '../containers/Placeholder'

const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
        width: '75%',
        margin: `${theme.spacing()}px auto`,
    },
}))

const MediaPage = ({ fetchMedia }) => {
    const classes = useStyles()
    const history = useHistory()
    const { id: reqMediaId } = useParams()
    useEffect(() => {
        fetchMedia(reqMediaId, history)
    }, [history, reqMediaId, fetchMedia])

    return (
        <Page title="Media Page" pageName="mediaPage">
            <PageContainer>
                <Placeholder
                    classes={{ img: classes.img }}
                    label="Fetching media..."
                />
            </PageContainer>
        </Page>
    )
}

MediaPage.propTypes = {
    fetchMedia: PropTypes.func,
}

export default MediaPage
