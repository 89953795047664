import {
    randomizeTitle,
    onTagListChange,
    submitForm,
} from '../actions/VideoForm'
import { connect } from 'react-redux'
import {
    submit,
    reduxForm,
    getFormValues,
    getFormSubmitErrors,
    isSubmitting,
    hasSubmitSucceeded,
    hasSubmitFailed,
} from 'redux-form'

import VideoFormComp from '../components/VideoForm'

const mapStateToProps = (state, { form }) => ({
    video: getFormValues(form)(state) || {},
    errors: getFormSubmitErrors(form)(state) || {},
    isSubmitting: isSubmitting(form)(state) || false,
    submitSucceeded: hasSubmitSucceeded(form)(state) || false,
    submitFailed: hasSubmitFailed(form)(state) || false,
})

const mapDispatchToProps = (dispatch, { form }) => ({
    onSubmit: (...args) => dispatch(submitForm(form, ...args)),
    onTagListChange: (...args) => dispatch(onTagListChange(form, ...args)),
    randomizeTitle: (...args) => dispatch(randomizeTitle(form, ...args)),
    remoteSubmit: () => dispatch(submit(form)),
})

const VideoForm = reduxForm({
    validate: (values) => {
        const errors = {}
        const requiredFields = ['title']
        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Required'
            }
        })
        return errors
    },
})(VideoFormComp)

export default connect(mapStateToProps, mapDispatchToProps)(VideoForm)
