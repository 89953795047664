import { submitForm } from '../actions/ReelForm'
import { connect } from 'react-redux'
import {
    change,
    submit,
    getFormValues,
    getFormSubmitErrors,
    isSubmitting,
} from 'redux-form'

import ReelForm from '../components/ReelForm'

const mapStateToProps = (state, { form }) => ({
    collection: getFormValues(form)(state) || {},
    errors: getFormSubmitErrors(form)(state) || {},
    isSubmitting: isSubmitting(form)(state) || false,
    change,
})

const mapDispatchToProps = (dispatch, { form }) => ({
    onSubmit: (...args) => dispatch(submitForm(form, ...args)),
    remoteSubmit: () => dispatch(submit(form)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReelForm)
