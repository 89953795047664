import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid white',
        backgroundColor: 'black',
        padding: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
    subject: {
        position: 'relative',
        border: '1px solid red',
    },
    video: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    handle: {
        position: 'absolute',
        backgroundColor: theme.palette.grey[100],
    },
    handleStartX: {
        top: '-10px',
        left: 'calc(50% - 25px)',
        width: '50px',
        height: '20px',
    },
    handleEndX: {
        position: 'absolute',
        top: 'calc(100% - 10px)',
        left: 'calc(50% - 25px)',
        width: '50px',
        height: '20px',
        backgroundColor: theme.palette.grey[100],
    },
    handleStartY: {
        position: 'absolute',
        top: 'calc(50% - 25px)',
        left: '-10px',
        width: '20px',
        height: '50px',
        backgroundColor: theme.palette.grey[100],
    },
    handleEndY: {
        position: 'absolute',
        top: 'calc(50% - 25px)',
        left: 'calc(100% - 10px)',
        width: '20px',
        height: '50px',
        backgroundColor: theme.palette.grey[100],
    },
    seekBarWindow: {
        width: '200px',
        height: '112px',
        padding: theme.spacing(1),
    },
    seekBarWindowThumb: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
}))

const VideoSlider = withStyles((theme) => ({
    root: {
        color: '#52af77',
        height: 8,
        marginTop: theme.spacing(2),
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        width: '100px',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
}))(Slider)

const Editor = ({ subject, fullWidth }) => {
    const [isListening, setIsListening] = useState(false)
    const [seekValue, setSeekValue] = useState(0)
    const videoEl = useRef()

    useEffect(() => {
        if (videoEl.current && !isListening) {
            setIsListening(true)
            videoEl.current.addEventListener('timeupdate', () => {
                setSeekValue(videoEl.current.currentTime)
            })
        }
    }, [videoEl, isListening, setIsListening])

    const classes = useStyles()
    const rootClass = classNames(classes.root, {
        [classes.fullWidth]: fullWidth,
    })

    const makeHandle = (role) => {
        const handleClass = classNames(classes.handle, {
            [classes.handleStartX]: role === 'startX',
            [classes.handleEndX]: role === 'endX',
            [classes.handleStartY]: role === 'startY',
            [classes.handleEndY]: role === 'endY',
        })

        const dropHandle = () => console.log('dropped handle', role)
        const onDragHandle = () => console.log('dragging handle', role)
        const onDragLeave = () => console.log('leaving handle', role)

        return (
            <div
                key={role}
                className={handleClass}
                onDrop={dropHandle}
                onDragEnter={onDragHandle}
                onDragOver={onDragHandle}
                onDragLeave={onDragLeave}
                onDragEnd={onDragLeave}
            />
        )
    }

    const makeHandles = () => {
        const handles = ['startX', 'endX', 'startY', 'endY'].map((role) =>
            makeHandle(role)
        )
        return handles
    }

    const doSeek = (event, newValue) => {
        if (videoEl.current) {
            videoEl.current.currentTime = newValue
        }
    }

    const asSeek = (value) => {
        console.log('setting seek value', value)
        return moment(value * 1000).format('m:ss')
    }

    return (
        <div className={rootClass}>
            <div className={classes.subject}>
                {makeHandles()}
                <video
                    className={classes.video}
                    src={subject.url}
                    ref={videoEl}
                />
            </div>
            {videoEl.current && (
                <div className={classes.controls}>
                    <IconButton onClick={undefined}>foo</IconButton>
                    <VideoSlider
                        step={0.01}
                        min={0}
                        max={videoEl.current.duration || 100}
                        value={seekValue}
                        valueLabelDisplay="auto"
                        valueLabelFormat={asSeek}
                        onChange={doSeek}
                    />
                </div>
            )}
        </div>
    )
}

Editor.propTypes = {
    /**
     * The subject being edited.
     */
    subject: PropTypes.object.isRequired,
    /**
     * Whether or not to take up the full width of the parent
     */
    fullWidth: PropTypes.bool,
}

export default Editor
