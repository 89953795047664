import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import AppState from './AppState'
import FileDrop from './FileDrop'
import HomePage from './HomePage'
import NavBar from './NavBar'
import UserState from './UserState'
import VideoPage from './VideoPage'
import VideoUploadDialog from './VideoUploadDialog'
import entities from './Entities'
import pageState from './PageState'
import searchResults from './SearchResults'

const reducer = combineReducers({
    appState: AppState,
    // pageState: VideoPage,
    fileDrop: FileDrop,
    form: formReducer,
    navBar: NavBar,
    userState: UserState,
    homePage: HomePage,
    videoPage: VideoPage,
    videoUploadDialog: VideoUploadDialog,
    pageState,
    entities,
    searchResults,
})

const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

export default createStore(
    reducer,
    composeEnhancers(applyMiddleware(logger, thunk))
)
