import { connect } from 'react-redux'

import NoMatchPage from '../components/NoMatchPage'
import { getPublic } from '../selectors'

const mapStateToProps = (state) => ({
    isPublic: getPublic(state),
})

export default connect(
    mapStateToProps,
    undefined
)(NoMatchPage)
