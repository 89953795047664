import moment from 'moment'
/**
 * A Service for dealing with Reels.
 */
class ReelService {
    /**
     * Get the timestamps where item in the reel begins
     *
     * @param {Any}     count          The count value entered by the user
     * @param {Number}  start          The start of the interval
     * @param {Number}  end            The end of the interval
     * @param {Number}  stepDuration   The duration of each step, if any
     * @return {Array}                 The resolved timestamps to use
     */
    getTimestamps(count, start, end, stepDuration) {
        if (!count) {
            return []
        }

        end -= stepDuration || 0

        if (start > end) {
            throw new Error(
                'Start time plus duration must be greater than end time.'
            )
        }

        // if the count is specified as an interval, that interval becomes the gap
        // between steps and the count is computed based on the length of the span
        // and the interval. otherwise, the gap is computed from the integer count
        let stepGap
        const lastIdx = count.length - 1
        if (Object.keys(ReelService.MULTIPLIERS).includes(count[lastIdx])) {
            // treat the count as an interval
            // 2/3m => two thirds of a minute => every 40 seconds
            // 3/2m => one and a half minutes => every 90 seconds
            let magnitude = count.substring(0, lastIdx)
            const unit = count[lastIdx]

            if (magnitude.includes('/')) {
                const magParts = magnitude.split('/')
                if (magParts.length !== 2) {
                    throw new Error(`Count value ${count} is invalid.`)
                }

                magnitude =
                    parseFloat(magParts[0], 10) / parseFloat(magParts[1], 10)
            }

            stepGap = parseFloat(magnitude, 10) * ReelService.MULTIPLIERS[unit]
            count = Math.floor((end - start) / stepGap) + 1
        } else {
            count = parseInt(count, 10)
            if (isNaN(count) || count < 1) {
                throw new Error(`Count value ${count} is invalid`)
            }

            if (count === 1) {
                return [start]
            }

            stepGap = (end - start) / (count - 1)
        }

        return [...Array(count).keys()].map((i) => start + stepGap * i)
    }

    /**
     * Translate the input time into a float, in seconds
     *
     * Supports time values, e.g. 10:08 -> 60 * 10 + 8 -> 608
     * Supports basic units, e.g. 10/3m -> 10 / 3 * 60 -> 200
     * Supports timestamps, e.g. 30 -> 30
     *
     * @param {String}   inputTime  The time value entered by the user
     * @return {Number}             The parsed float value, in seconds
     */
    parseInputTime(inputTime) {
        const isNeg = inputTime[0] === '-'
        inputTime = `${inputTime}`
        if (inputTime.includes(':')) {
            // assume the time is in format hh:mm:ss or mm:ss
            const parts = inputTime.split(':')

            if (parts.length >= 4) {
                return NaN
            }

            if (parts.length === 2) {
                parts.splice(0, 0, '00')
                if (isNeg) {
                    parts[0] = `-${parts[0]}`
                    parts[1] = parts[1].substring(1)
                }
            }

            return moment.duration(parts.join(':')).asSeconds()
        }

        let mult = 1
        let magnitude = inputTime
        const lastIdx = inputTime.length - 1
        if (Object.keys(ReelService.MULTIPLIERS).includes(inputTime[lastIdx])) {
            // assume the input is in the form \d+(s|m|h), such that
            //   1/2m => 1/2 * 60 => 30 (seconds)
            //   12s => 12  * 1 => 12
            //   1/6h => 1/6 * 3600 => 600
            mult = ReelService.MULTIPLIERS[inputTime[lastIdx]]
            magnitude = inputTime.substring(0, lastIdx)
        }

        if (magnitude.includes('/')) {
            const magParts = magnitude.split('/')
            if (magParts.length !== 2) {
                return NaN
            }

            magnitude =
                parseFloat(magParts[0], 10) / parseFloat(magParts[1], 10)
        }

        return parseFloat(magnitude) * mult
    }
}

ReelService.MULTIPLIERS = {
    s: 1,
    m: 60,
    h: 3600,
}

export default ReelService
