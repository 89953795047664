import {
    doVideoSearch,
    onIncludeListChange,
    onExcludeListChange,
} from '../actions/VideoSearchForm'
import { connect } from 'react-redux'
import {
    change,
    submit,
    reset,
    reduxForm,
    getFormValues,
    getFormSubmitErrors,
    isSubmitting,
} from 'redux-form'
import { getPreviousSearchCriteria } from '../selectors'
import VideoSearchForm from '../components/VideoSearchForm'

const mapStateToProps = (state, { form }) => ({
    searchData: getFormValues(form)(state) || {},
    errors: getFormSubmitErrors(form)(state) || {},
    isSubmitting: isSubmitting(form)(state) || false,
    lastSearch: getPreviousSearchCriteria(state, form),
})

const mapDispatchToProps = (dispatch, { form }) => ({
    onSubmit: (...args) => dispatch(doVideoSearch(...args)),
    onIncludeListChange: (...args) => dispatch(onIncludeListChange(...args)),
    onExcludeListChange: (...args) => dispatch(onExcludeListChange(...args)),
    change: (...args) => dispatch(change(form, ...args)),
    remoteSubmit: () => dispatch(submit(form)),
    reset: () => dispatch(reset(form)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({})(VideoSearchForm))
