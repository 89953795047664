import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import RestoreIcon from '@material-ui/icons/Restore'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandIcon from '@material-ui/icons/BurstMode'
import CollapseIcon from '@material-ui/icons/Collections'
import CodeIcon from '@material-ui/icons/Code'

import { useInterval } from '../utils'
import Loader from '../containers/Loader'
import ReelItem from '../containers/ReelItem'
import VisibilityIcon from './VisibilityIcon'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        margin: theme.spacing(1, 0),
    },
    subtitle: {
        display: 'flex',
    },
    stacked: {
        display: 'inline-flex',
        flexDirection: 'column',
    },
    headerBar: {
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
    },
    reelItemContainer: {},
    inline: {
        display: 'inline-flex',
        width: 'auto',
    },
    spacer: {
        alignSelf: 'center',
        margin: theme.spacing(0, 1),
    },
    visibility: {
        alignSelf: 'center',
        fontSize: '16px',
        marginRight: theme.spacing(1),
    },
    reelContent: {
        display: 'flex',
    },
    wrap: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '2px',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
            gridGap: '1px',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
    },
    noWrap: {
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
            background: 'transparent',
        },
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        height: '100%',
    },
    action: {},
    icon: {
        width: '20px',
        height: '20px',
    },
    transform: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
    },
    hidden: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
}))

const Reel = ({
    collection,
    source,
    stacked,
    collapsed,
    noWrap,
    fetchCollection,
    onRegenerate,
    onDelete,
}) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [isCollapsed, setCollapsed] = useState(collapsed)
    const [isStacked, setStacked] = useState(stacked)
    const [folded, setFolded] = useState(true)

    const [stackedIndex, setStackedIndex] = useState(0)
    const [hasExpanded, setHasExpanded] = useState(!stacked)

    const [slideDelay, setSlideDelay] = useState(null)
    useInterval(
        () => setStackedIndex((stackedIndex + 1) % collection.items.length),
        slideDelay
    )

    const pollDelay = ['working', 'pending'].includes(collection.state)
        ? 5000
        : null
    useInterval(fetchCollection, pollDelay)

    const doSlideshow =
        isStacked &&
        (collection.item_type === 'image' || collection.item_type === 'gif')
    const slideDuration = collection.item_type === 'image' ? 350 : 2000

    const reelItemClass = classNames(classes.reelItemContainer, {
        [classes.inline]: noWrap,
    })

    let items = collection.items
        .filter((item, i) => !isStacked || hasExpanded || i === stackedIndex)
        .map((item, i) => {
            return (
                <div
                    key={item.id}
                    className={reelItemClass}
                    onMouseOver={
                        doSlideshow
                            ? () => {
                                  setHasExpanded(true)
                                  setSlideDelay(slideDuration)
                              }
                            : undefined
                    }
                    onMouseOut={
                        doSlideshow ? () => setSlideDelay(null) : undefined
                    }
                >
                    <ReelItem
                        media={item}
                        source={source}
                        hidden={isStacked && i !== stackedIndex}
                        showActions={!isStacked}
                    />
                </div>
            )
        })

    if (items.length === 0) {
        items = (
            <div className={classes.empty}>
                {collection.state === 'failed' ? (
                    <React.Fragment>
                        <Typography variant="subtitle1">Failed:</Typography>
                        <pre>{collection.generate_error}</pre>
                    </React.Fragment>
                ) : (
                    <Loader label="Generating collection..." />
                )}
            </div>
        )
    }

    const rootClass = classNames(classes.root, {
        [classes.stacked]: isStacked,
    })
    const reelClass = classNames({
        [classes.noWrap]: noWrap,
        [classes.wrap]: !noWrap,
    })
    const reelContentClass = classNames(classes.reelContent, {
        [classes.hidden]: isCollapsed,
    })
    const totalSize = collection.items.reduce((acc, item) => acc + item.size, 0)
    let title
    let subtitle
    if (collection.items.length > 0) {
        title = `${collection.items.length} ${collection.item_type}(s)`

        const timestamp = moment.utc(collection.items[0].created_at).fromNow()
        subtitle = (
            <span className={classes.subtitle}>
                {timestamp} by {collection.user.username}
                <div className={classes.spacer}>&bull;</div>
                {(totalSize / 1024 / 1024).toFixed(2)} MB
            </span>
        )
    } else {
        title = 'No items'
        subtitle = ''
    }

    return (
        <div className={rootClass}>
            <Paper className={classes.paper}>
                <div className={classes.headerBar}>
                    <IconButton onClick={() => setCollapsed(!isCollapsed)}>
                        {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </IconButton>
                    <Typography variant="h6">{title}</Typography>
                    <div className={classes.spacer}>&bull;</div>
                    <VisibilityIcon
                        className={classes.visibility}
                        visibility={collection.visibility}
                    />
                    <Typography variant="subtitle2">{subtitle}</Typography>
                </div>

                {!folded && (
                    <div className={classes.transform}>
                        <pre>
                            {JSON.stringify(collection.transform, null, 4)}
                        </pre>
                    </div>
                )}

                <div className={reelContentClass}>
                    <div className={classes.actions}>
                        {isStacked ? (
                            <Tooltip title="Expand">
                                <IconButton
                                    className={classes.action}
                                    color="secondary"
                                    aria-label="Expand"
                                    onClick={() => {
                                        setStacked(false)
                                        setHasExpanded(true)
                                    }}
                                >
                                    <ExpandIcon
                                        className={classes.icon}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Collapse">
                                <IconButton
                                    className={classes.action}
                                    color="secondary"
                                    aria-label="Collapse"
                                    onClick={() => setStacked(true)}
                                >
                                    <CollapseIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Show Transform">
                            <IconButton
                                className={classes.action}
                                color="secondary"
                                aria-label="Fold"
                                onClick={() => setFolded(!folded)}
                            >
                                <CodeIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {onRegenerate && (
                            <Tooltip title="Regenerate">
                                <IconButton
                                    className={classes.action}
                                    color="secondary"
                                    aria-label="Regenerate"
                                    onClick={() =>
                                        onRegenerate(enqueueSnackbar)
                                    }
                                >
                                    <RestoreIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {onDelete && (
                            <Tooltip title="Delete">
                                <IconButton
                                    className={classes.action}
                                    color="secondary"
                                    aria-label="Delete"
                                    onClick={() => onDelete(enqueueSnackbar)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    <div className={reelClass}>{items}</div>
                </div>
            </Paper>
        </div>
    )
}

Reel.propTypes = {
    /**
     * If true, reel will scroll horizontally to prevent wrapping
     */
    noWrap: PropTypes.bool,
    /**
     * If true, only the first item will be displayed
     */
    stacked: PropTypes.bool,
    /**
     * If true, show the header only
     */
    collapsed: PropTypes.bool,
    /**
     * The collection to render
     */
    collection: PropTypes.object.isRequired,
    /**
     * The source from which this collection was generated
     */
    source: PropTypes.object,
    /**
     * Handler for fetching the collection data from the server
     */
    fetchCollection: PropTypes.func,
    /**
     * Regeneration handler
     */
    onRegenerate: PropTypes.func,
    /**
     * Deletion handler
     */
    onDelete: PropTypes.func,
}

export default Reel
