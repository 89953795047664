import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { getCollectionById } from '../selectors'
import ReelComp from '../components/Reel'
import { fetchCollection, onRegenerate, onDelete } from '../actions/Reel'

const mapStateToProps = (state, { collectionId }) => {
    return {
        collection: getCollectionById(state, collectionId),
    }
}

const mapDispatchToProps = (dispatch, { collectionId }) => {
    return {
        onDelete: (...args) => dispatch(onDelete(collectionId, ...args)),
        onRegenerate: (...args) =>
            dispatch(onRegenerate(collectionId, ...args)),
        fetchCollection: (...args) =>
            dispatch(fetchCollection(collectionId, ...args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withSnackbar(ReelComp),
)
