import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import {
    collectionSchema,
    mediaSchema,
    jobSchema,
    getSchemaForType,
} from '../schema'

export const getCurrentUser = (state) => state.userState.user

export const getAppState = (state) => state.appState
export const getPageState = (state, pageName) => state.pageState[pageName]
export const getPageTitle = createSelector(
    getAppState,
    (appState) => appState.pageTitle
)
export const getTitle = createSelector(
    getAppState,
    (appState) => appState.title
)
export const getPublic = createSelector(
    getAppState,
    (appState) => appState.isPublic
)
export const getLogo = createSelector(
    getAppState,
    (appState) => appState.logo
)
export const hasInitializedServiceWorker = createSelector(
    getAppState,
    (appState) => appState.serviceWorker.initialized
)

export const getCurrentRequest = createSelector(
    getAppState,
    (appState) => appState.currentRequest
)
export const getCurrentUri = createSelector(
    getCurrentRequest,
    (req) => (req ? req.uri : '')
)
export const getQueryParams = createSelector(
    getCurrentRequest,
    (req) => (req ? req.queryParams : {})
)

export const getAllEntities = (state) => state.entities
export const getEntitiesByType = (state, entityType) =>
    state.entities[entityType]

export const getFirstMatchingEntity = (state, entityType, matcher) => {
    const schema = getSchemaForType(entityType)
    const candidates = getEntitiesByType(state, entityType)

    let match
    if (candidates) {
        match = Object.keys(candidates).find((key) => {
            return matcher(candidates[key])
        })
    }

    if (match) {
        return denormalize(candidates[match].id, schema, state.entities)
    }
    return null
}
export const getVideos = (state, videoIds) => {
    if (!state.entities || !state.entities.media) {
        return []
    }
    return denormalize(videoIds, [mediaSchema], state.entities)
}
export const getJobs = (state, jobIds) => {
    if (!state.entities || !state.entities.jobs) {
        return []
    }
    return denormalize(jobIds, [jobSchema], state.entities)
}

export const getVideoByIdOrSlug = (state, videoIdOrSlug) => {
    const videoId = parseInt(videoIdOrSlug, 10)

    if (isNaN(videoId)) {
        return getFirstMatchingEntity(
            state,
            'media',
            (entity) => entity.slug === videoIdOrSlug
        )
    }

    try {
        return denormalize(videoIdOrSlug, mediaSchema, state.entities)
    } catch (err) {
        return null
    }
}

export const getRelatedVideos = (state, videoId) => {
    const relatedVideoIds = state.entities.relatedVideosById[videoId]
    if (!relatedVideoIds || !relatedVideoIds.length) {
        return []
    }

    try {
        return denormalize(relatedVideoIds, [mediaSchema], state.entities)
    } catch (err) {
        return []
    }
}

export const getCurrentUserId = (state) => state.entities.users.current
export const getUserById = (state, userId) => state.entities.users[userId]
export const getUsers = (state) => state.entities.users

export const getCollectionById = (state, collectionId) => {
    try {
        return denormalize(collectionId, collectionSchema, state.entities)
    } catch (err) {
        return null
    }
}

export const getJobById = (state, jobId) => {
    try {
        return denormalize(jobId, jobSchema, state.entities)
    } catch (err) {
        return null
    }
}

export const getPaginationData = (state) => state.searchResults.pagination
export const getPreviousSearchCriteria = (state, pageName) =>
    state.pageState[pageName]
        ? state.pageState[pageName].searchCriteria
        : undefined
export const getPreviousSearchParams = (state) =>
    state.searchResults.searchParams
export const getPrevPage = createSelector(
    getPaginationData,
    (pagination) => pagination.prev_page
)
export const getCurrentPage = createSelector(
    getPaginationData,
    (pagination) => pagination.current_page
)
export const getNextPage = createSelector(
    getPaginationData,
    (pagination) => pagination.next_page
)
export const getNumPages = createSelector(
    getPaginationData,
    (pagination) => pagination.num_pages
)

export const getSearchResults = (state) => state.searchResults.ids
export const getVideoResults = createSelector(
    getSearchResults,
    getAllEntities,
    (videoIds, entities) => {
        return denormalize(videoIds, [mediaSchema], entities)
    }
)
export const getFeaturedVideoIds = (state) => state.homePage.featuredVideoIds
export const getFeaturedVideos = createSelector(
    getFeaturedVideoIds,
    getAllEntities,
    (videoIds, entities) => {
        return denormalize(videoIds, [mediaSchema], entities)
    }
)
