import { hasInitializedServiceWorker } from '../selectors'
export const setPageTitle = (title) => ({ type: 'SET_PAGE_TITLE', title })
export const setFaviconUrl = (url) => ({ type: 'SET_FAVICON_URL', url })
export const deferInstallPrompt = (event) => ({
    type: 'DEFER_INSTALL_PROMPT',
    event,
})
export const appInstalled = () => ({ type: 'APP_INSTALLED' })
export const serviceWorkerInitialized = () => ({
    type: 'SERVICE_WORKER_INITIALIZED',
})
export const serviceWorkerSuccess = () => ({ type: 'SERVICE_WORKER_SUCCESS' })
export const serviceWorkerFailed = () => ({ type: 'SERVICE_WORKER_FAILED' })
export const updateAppState = (appState) => ({
    type: 'UPDATE_APP_STATE',
    appState,
})

export const initServiceWorker = () => {
    return (dispatch, getState) => {
        if (hasInitializedServiceWorker(getState())) {
            console.log('not re-initializing service worker')
            return
        }

        dispatch(serviceWorkerInitialized())

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/worker.js').then(
                    (registration) => {
                        dispatch(serviceWorkerSuccess())
                    },
                    (err) => {
                        dispatch(serviceWorkerFailed())
                    }
                )
            })
        }

        window.addEventListener('beforeinstallprompt', (evt) => {
            evt.preventDefault()
            dispatch(deferInstallPrompt(evt))
        })

        window.addEventListener('appinstalled', (evt) => {
            dispatch(appInstalled())
        })
    }
}

export const fetchAppState = () => {
    return (dispatch) => {
        return fetch('/api/v1/app/settings')
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((appState) => {
                        dispatch(updateAppState(appState))
                    })
                }
            })
            .catch((error) => console.error(error))
    }
}

export const updateCurrentRequest = (currentRequest) => ({
    type: 'UPDATE_CURRENT_REQUEST',
    currentRequest,
})
