import { normalize } from 'normalizr'
import { getSchemaForType } from '../schema'

export const updateEntities = (entities) => ({
    type: 'UPDATE_ENTITIES',
    entities,
})

export const replaceEntity = (entityType, entity) => ({
    type: 'REPLACE_ENTITY',
    entityType,
    entity,
})

export const removeEntity = (entityType, entityId) => ({
    type: 'REMOVE_ENTITY',
    entityType,
    entityId,
})

export const appendCollection = (collectionId, sourceId) => ({
    type: 'APPEND_COLLECTION',
    collectionId,
    sourceId,
})

export const removeCollection = (collectionId, sourceId) => ({
    type: 'REMOVE_COLLECTION',
    collectionId,
    sourceId,
})

export const storeNormalizedEntity = (entity, entityType) => {
    return (dispatch) => {
        const normalized = normalize(entity, getSchemaForType(entityType))
        dispatch(updateEntities(normalized.entities))
        dispatch(
            replaceEntity(
                entityType,
                normalized.entities[entityType][normalized.result]
            )
        )
    }
}
export const storeJob = (job) => storeNormalizedEntity(job, 'jobs')
export const storeMedia = (media) => storeNormalizedEntity(media, 'media')
export const storeCollection = (collection) => {
    return (dispatch) => {
        dispatch(storeNormalizedEntity(collection, 'collections'))

        // for collections, we should also append the collection to the
        // source media so the update is reflected immediately
        dispatch(appendCollection(collection.id, collection.source_id))
    }
}
