import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import App from './containers/App'
import store from './reducers'

whyDidYouRender(React, { include: [] })

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)
