import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

/**
 * A React hook for executing a function periodically
 *
 * @param {function} callback  The function to execute
 * @param {int}      delay     The time to wait (in milliseconds) between calls
 */
export function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        /**
         * Execute the latest callback function
         */
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            const id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

/**
 * A React hook to fetch the query parameters
 *
 * @return {URLSearchParams}
 */
export function useQuery() {
    return new URLSearchParams(useLocation().search)
}

/**
 * A React hook to ensure the current query parameters match with
 * the specified params.
 *
 * @param {object} params  The params to sync
 */
export function useSyncedQueryParams(params) {
    // if we have performed a search and the current query params
    // don't reflect it, update the query params
    const history = useHistory()
    useEffect(() => {
        const targetQueryString = Object.keys(params || {})
            .reduce((acc, key) => {
                acc.append(key, params[key])
                return acc
            }, new URLSearchParams())
            .toString()
        if (!targetQueryString) {
            return
        }
        if (history.location.search !== `?${targetQueryString}`) {
            history.push({
                search: `?${targetQueryString}`,
            })
        }
    }, [history, params])
}

/**
 * A React hook to automatically submit a search form
 *
 * @param {object} params  The auto-search params
 * @param {func}   submit  The function to call to submit the form
 * @param {func}   change  The function to call to update form data
 */
export function useAutoSearch(params, submit, change) {
    const [lastAutoSearch, setLastAutoSearch] = useState(null)
    useEffect(() => {
        if (params && !isEqual(params, lastAutoSearch)) {
            setLastAutoSearch(params)
            Object.keys(params).forEach((param) => {
                change(param, params[param])
            })
            submit(params)
        }
    }, [params, submit, change, lastAutoSearch, setLastAutoSearch])
}

/**
 * A React Hook to resolve a stable set of initial search params, preferring
 * query parameters over the specified fallback parameters.
 *
 * @param {object} fallbackParams   The initial search params to use, if the
 *                                  query parameters are empty
 * @return {object}                 The resolved search params. These will be
 *                                  resolved once on the initial render.
 */
export function useResolvedSearchParams(fallbackParams) {
    const [storedParams, setStoredParams] = useState(null)

    // we want to set the default search params when the component is initially
    // loaded. in order of precedence, use:
    //   - the existing stored params, if any
    //   - the query params, if any
    //   - the previous search params, if any
    const query = useQuery()
    const queryParams = {}
    query.forEach((val, key) => {
        queryParams[key] = val
    })
    const resolvedSearchParams = storedParams
        ? storedParams
        : queryParams && Object.keys(queryParams).length > 0
        ? queryParams
        : fallbackParams

    // we store the resolved search params so they do not change
    // after the initial render
    useEffect(() => {
        if (storedParams === null) {
            setStoredParams(resolvedSearchParams)
        }
    }, [resolvedSearchParams, storedParams, setStoredParams])

    return resolvedSearchParams
}
