import { connect } from 'react-redux'

import {
    getPageState,
    getVideos,
    getPreviousSearchCriteria,
} from '../selectors'
import { goToPage } from '../actions/VideoSearchForm'
import VideoSearchPage from '../components/VideoSearchPage'

const mapStateToProps = (state) => {
    const pageName = 'videoSearch'
    const pageState = getPageState(state, pageName)
    const videos = getVideos(state, pageState.entityIds)

    return {
        ...pageState,
        prevSearchCriteria: getPreviousSearchCriteria(state, pageName),
        videos,
    }
}

export default connect(
    mapStateToProps,
    { goToPage }
)(VideoSearchPage)
