import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

const searchTags = (inputValue) => {
    if (!inputValue || inputValue.length < 3) {
        return new Promise((resolve) => resolve([]))
    }
    return new Promise((resolve) => {
        return fetch(`/api/v1/tags?search=${inputValue}`)
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }

                throw new Error(`There was an error fetching tags ${res}`)
            })
            .then((tags) => {
                resolve(
                    tags.map((tag) => ({
                        value: tag.name,
                        label: tag.name,
                    }))
                )
            })
            .catch((error) => {
                console.error(error)
            })
    })
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        height: 'auto',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light'
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}))

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
    <Typography
        color="textSecondary"
        className={selectProps.classes.noOptionsMessage}
        {...innerProps}
    >
        {children}
    </Typography>
)

NoOptionsMessage.propTypes = {
    selectProps: PropTypes.object.isRequired,
    innerProps: PropTypes.object,
    children: PropTypes.node,
}

const inputComponent = ({ inputRef, ...rest }) => (
    <div ref={inputRef} {...rest} />
)

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
}

const Control = ({ selectProps, innerRef, innerProps, children }) => (
    <TextField
        fullWidth
        InputProps={{
            inputComponent,
            inputProps: {
                className: selectProps.classes.input,
                inputRef: innerRef,
                children: children,
                ...innerProps,
            },
        }}
        {...selectProps.textFieldProps}
    />
)

Control.propTypes = {
    selectProps: PropTypes.object.isRequired,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    children: PropTypes.node.isRequired,
    innerProps: PropTypes.object,
}

const Option = ({ innerRef, innerProps, isFocused, isSelected, children }) => (
    <MenuItem
        buttonRef={innerRef}
        selected={isFocused}
        component="div"
        style={{
            fontWeight: isSelected ? 500 : 400,
        }}
        {...innerProps}
    >
        {children}
    </MenuItem>
)

Option.propTypes = {
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    innerProps: PropTypes.object,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

const Placeholder = ({ selectProps, innerProps, children }) => (
    <Typography
        color="textSecondary"
        className={selectProps.classes.placeholder}
        {...innerProps}
    >
        {children}
    </Typography>
)

Placeholder.propTypes = {
    selectProps: PropTypes.object.isRequired,
    innerProps: PropTypes.object,
    children: PropTypes.node.isRequired,
}

const SingleValue = ({ selectProps, innerProps, children }) => (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
        {children}
    </Typography>
)

SingleValue.propTypes = {
    selectProps: PropTypes.object.isRequired,
    innerProps: PropTypes.object,
    children: PropTypes.node.isRequired,
}

const ValueContainer = ({ selectProps, children }) => (
    <div className={selectProps.classes.valueContainer}>{children}</div>
)

ValueContainer.propTypes = {
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
}

const MultiValue = ({ selectProps, removeProps, isFocused, children }) => (
    <Chip
        tabIndex={-1}
        label={children}
        className={classNames(selectProps.classes.chip, {
            [selectProps.classes.chipFocused]: isFocused,
        })}
        onDelete={removeProps.onClick}
        deleteIcon={<CancelIcon {...removeProps} />}
    />
)

MultiValue.propTypes = {
    isFocused: PropTypes.bool,
    selectProps: PropTypes.object.isRequired,
    removeProps: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
}

const Menu = ({ selectProps, innerProps, children }) => (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
        {children}
    </Paper>
)

Menu.propTypes = {
    selectProps: PropTypes.object.isRequired,
    innerProps: PropTypes.object,
    children: PropTypes.node.isRequired,
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

const EditableTagList = ({ label, tags, onCreate, onChange }) => {
    const theme = useTheme()
    const classes = useStyles()
    const selectStyles = {
        input: (base) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }

    if (tags) {
        if (tags.constructor !== Array) {
            tags = tags.split(',').map((s) => s.trim())
        }
        tags = tags.map((tag) => {
            if (typeof tag === 'string') {
                return { label: tag, value: tag }
            }
            return { label: tag.name, value: tag.name }
        })
    }

    return (
        <AsyncCreatableSelect
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
                label: label || 'Tags',
                InputLabelProps: { shrink: true },
            }}
            defaultOptions
            cacheOptions
            loadOptions={searchTags}
            components={components}
            value={tags}
            onCreateOption={onCreate}
            onChange={onChange}
            placeholder="Add tags..."
            isMulti
            isClearable
        />
    )
}

EditableTagList.propTypes = {
    /**
     * The label for the element
     */
    label: PropTypes.string,
    /**
     * List of tags to start with
     */
    tags: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    /**
     * Handler when a new tag is created.
     */
    onCreate: PropTypes.func,
    /**
     * Handler for tag deletion. It will be passed the tag ID
     */
    onChange: PropTypes.func,
}

export default EditableTagList
