import App from '../components/App'
import { connect } from 'react-redux'
import { initServiceWorker, fetchAppState } from '../actions/AppState'
import { storeUser, loadUserFromSession } from '../actions/User'
import { getAppState, getCurrentUser } from '../selectors'

const mapStateToProps = (state) => {
    const { pageTitle, faviconUrl, landingPageBg, title } = getAppState(state)
    return {
        appTitle: title,
        faviconUrl,
        landingPageBg,
        pageTitle,
        user: getCurrentUser(state),
    }
}

export default connect(mapStateToProps, {
    fetchAppState,
    storeUser,
    loadUserFromSession,
    initServiceWorker,
})(App)
