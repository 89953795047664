import { change, SubmissionError } from 'redux-form'

import { submitForm as submitFormCommon } from './Common'
import { deferInstallPrompt } from './AppState'

export const randomizeTitle = (form) => {
    return (dispatch) => {
        return fetch('/api/v1/titles/random')
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }

                throw new Error('Error generating random title', res)
            })
            .then((json) => {
                return dispatch(change(form, 'title', json.title))
            })
            .catch((error) => {
                console.error(error)
            })
    }
}

export const onTagListChange = (form, value, { action, removedValue }) => {
    return (dispatch) => {
        return dispatch(
            change(form, 'tags', value.map((val) => ({ name: val.value }))),
        )
    }
}

export const submitForm = (form, values) => {
    return (dispatch, getState) => {
        const videoId = values.id

        dispatch(submitFormCommon())

        let fetchUrl
        let fetchMethod

        if (videoId) {
            fetchUrl = `/api/v1/videos/${videoId}`
            fetchMethod = 'PUT'
        } else {
            fetchUrl = '/api/v1/videos'
            fetchMethod = 'POST'
        }

        return fetch(fetchUrl, {
            method: fetchMethod,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                resource_id: values.resource_id,
                title: values.title,
                tags: values.tags,
                visibility: values.visibility,
            }),
        }).then((res) => {
            const json = res.json()

            if (res.ok) {
                dispatch(change(form, '_successMsg', 'Changes Saved!'))
                setTimeout(() => {
                    dispatch(change(form, '_successMsg', ''))
                }, 5000)
                return json.then((video) => {
                    for (let i in video) {
                        dispatch(change(form, i, video[i]))
                    }

                    // check for "Add to home screen" capability
                    const { appState } = getState()
                    if (appState.deferredPrompt) {
                        appState.deferredPrompt.prompt()
                        appState.deferredPrompt.userChoice.then(
                            (choiceResult) => {
                                if (choiceResult.outcome === 'accepted') {
                                    console.log('Thanks for playing')
                                } else {
                                    console.log('No dice')
                                }
                                dispatch(deferInstallPrompt(null))
                            },
                        )
                    }
                })
            }

            dispatch(change(form, '_failedMsg', 'Submission Failed'))
            setTimeout(() => {
                dispatch(change(form, '_failedMsg', ''))
            }, 5000)
            return json.then((error_data) => {
                throw new SubmissionError(error_data.errors)
            })
        })
    }
}
