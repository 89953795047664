import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { SnackbarProvider } from 'notistack'

import {
    createMuiTheme,
    MuiThemeProvider,
    makeStyles,
} from '@material-ui/core/styles'
import deepPurple from '@material-ui/core/colors/deepPurple'
import pink from '@material-ui/core/colors/pink'
import CssBaseline from '@material-ui/core/CssBaseline'

import LandingPage from './LandingPage'
import { drawerWidth } from './NavBar'
import NavBar from '../containers/NavBar'
import VideoUploadDialog from '../containers/VideoUploadDialog'
import ScrollToTop from './ScrollToTop'

import HomePage from '../containers/HomePage'
import JobPage from '../containers/JobPage'
import JobSearchPage from '../containers/JobsPage'
import PrivateRoute from '../containers/PrivateRoute'
import VideoPage from '../containers/VideoPage'
import MediaPage from '../containers/MediaPage'
import RandomVideoPage from '../containers/RandomVideoPage'
import VideoSearchPage from '../containers/VideoSearchPage'
import NoMatchPage from '../containers/NoMatchPage'

export const theme = createMuiTheme({
    palette: {
        primary: deepPurple,
        secondary: pink,
    },
})

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '64px',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '56px',
        },
    },
    flex: { display: 'flex' },
    pageContent: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
}))

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/media/:id',
        component: MediaPage,
    },
    {
        path: '/videos',
        component: VideoSearchPage,
    },
    {
        path: '/videos/random',
        component: RandomVideoPage,
    },
    {
        path: '/videos/:id',
        component: VideoPage,
    },
    {
        path: '/jobs',
        component: JobSearchPage,
    },
    {
        path: '/jobs/:id',
        component: JobPage,
    },
    {
        path: '*',
        component: NoMatchPage,
    },
]

const App = ({
    fetchAppState,
    user,
    storeUser,
    loadUserFromSession,
    appTitle,
    pageTitle,
    faviconUrl,
    landingPageBg,
    withServiceWorker,
    initializeServiceWorker,
}) => {
    // attempt one update of the app state from the server
    // (though initial app state is stored with the client)
    useEffect(() => {
        fetchAppState()
    }, [fetchAppState])

    // register service worker, if enabled
    useEffect(() => {
        if (withServiceWorker) {
            initializeServiceWorker()
        }
    }, [withServiceWorker, initializeServiceWorker])

    // load a user from the session state or from the prop
    const [hasFetchedSessionUser, setHasFetchedSessionUser] = useState(false)
    useEffect(() => {
        if (!hasFetchedSessionUser) {
            setHasFetchedSessionUser(true)
            if (user) {
                storeUser(user)
            } else {
                loadUserFromSession()
            }
        }
    }, [
        hasFetchedSessionUser,
        loadUserFromSession,
        setHasFetchedSessionUser,
        storeUser,
        user,
    ])

    const classes = useStyles()
    let body
    if (user && user.id) {
        body = (
            <div id="app" className={classes.root}>
                <NavBar />
                <div className={classes.flex}>
                    <div id="page-content" className={classes.pageContent}>
                        <Switch>
                            {routes.map((route, idx) => (
                                <PrivateRoute key={idx} exact {...route} />
                            ))}
                        </Switch>
                    </div>
                </div>
                <VideoUploadDialog />
            </div>
        )
    } else {
        body = <LandingPage background={landingPageBg} />
    }
    return (
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <Router>
                    <CssBaseline />
                    <ScrollToTop />
                    <Helmet>
                        <title>
                            {pageTitle} &bull; {appTitle}
                        </title>
                        <link rel="shortcut icon" href={faviconUrl} />
                    </Helmet>

                    {body}
                </Router>
            </SnackbarProvider>
        </MuiThemeProvider>
    )
}

App.propTypes = {
    /**
     * Method to fetch and update app state
     */
    fetchAppState: PropTypes.func.isRequired,
    /**
     * The logged in user, if any
     */
    user: PropTypes.object,
    /**
     * Handler to store the user object in the session
     */
    storeUser: PropTypes.func.isRequired,
    /**
     * Handler to attempt loading the user from the session
     */
    loadUserFromSession: PropTypes.func.isRequired,
    /**
     * The title of the app
     */
    appTitle: PropTypes.string.isRequired,
    /**
     * The title of the current page
     */
    pageTitle: PropTypes.string,
    /**
     * The URL of the favicon
     */
    faviconUrl: PropTypes.string.isRequired,
    /**
     * The background image to use for the landing page
     */
    landingPageBg: PropTypes.string,
    /**
     * Whether or not to register a service worker
     */
    withServiceWorker: PropTypes.bool,
    /**
     * Handler to capture service worker success
     */
    initializeServiceWorker: PropTypes.func,
}

export default App
