const initialState = {
    // title of the application, fetched at runtime
    title: '',
    isPublic: true,
    pageTitle: '',
    faviconUrl: '/static/favicon.ico',
    loaderUrl: null,
    logo: '/static/logos.png',
    currentRequest: null,
    serviceWorker: {
        initialized: false,
        status: null,
        deferredPrompt: null,
        appInstalled: false,
    },
}

/**
 * Reducer for application-level state
 *
 * @param {object} state   The current state
 * @param {object} action  The action being dispatched
 * @return {object}  The new state
 */
function AppState(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_APP_STATE':
            return {
                ...state,
                ...action.appState,
            }

        case 'UPDATE_CURRENT_REQUEST':
            return {
                ...state,
                currentRequest: action.currentRequest,
            }

        case 'SET_PAGE_TITLE':
            return {
                ...state,
                pageTitle: action.title,
            }

        case 'SET_FAVICON_URL':
            return {
                ...state,
                faviconUrl: action.url,
            }

        case 'DEFER_INSTALL_PROMPT':
            return {
                ...state,
                serviceWorker: {
                    ...state.serviceWorker,
                    deferredPrompt: action.event,
                },
            }

        case 'APP_INSTALLED':
            return {
                ...state,
                serviceWorker: {
                    ...state.serviceWorker,
                    appInstalled: true,
                },
            }

        case 'SERVICE_WORKER_INITIALIZED':
            return {
                ...state,
                serviceWorker: {
                    ...state.serviceWorker,
                    initialized: true,
                },
            }

        case 'SERVICE_WORKER_SUCCESS':
            return {
                ...state,
                serviceWorker: {
                    ...state.serviceWorker,
                    status: 'success',
                },
            }

        case 'SERVICE_WORKER_FAILED':
            return {
                ...state,
                serviceWorker: {
                    ...state.serviceWorker,
                    status: 'failed',
                },
            }

        default:
            return state
    }
}

export default AppState
