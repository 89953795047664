import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import PageContainer from '../components/PageContainer'
import Placeholder from '../components/Placeholder'
import Page from '../containers/Page'

const useStyles = makeStyles((theme) => ({
    img: {
        display: 'block',
        width: '50%',
        margin: `${theme.spacing()}px auto`,
    },
}))

const NoMatchPage = ({ isPublic }) => {
    const classes = useStyles()
    return (
        <Page title="Not Found" pageName="notFound">
            <PageContainer>
                <Typography variant="h2" gutterBottom>
                    Not Found
                </Typography>
                <Typography variant="p">
                    The requested page does not exist.
                </Typography>
                <Placeholder label="Not Found" classes={{ img: classes.img }} />
            </PageContainer>
        </Page>
    )
}

NoMatchPage.propTypes = {
    isPublic: PropTypes.bool,
}

export default NoMatchPage
