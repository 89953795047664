import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import PageContainer from './PageContainer'
import Carousel from './Carousel'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    feature: {
        display: 'flex',
        backgroundColor: theme.palette.grey[500],
        boxShadow: `inset 0 -10px 20px -6px ${theme.palette.grey[800]}`,
        width: '100%',
    },
}))

const HomePage = ({ featuredVideos, fetchFeaturedVideos, setPageTitle }) => {
    useEffect(() => {
        setPageTitle('Home')
    }, [setPageTitle])

    const [hasFetched, setHasFetched] = useState(false)
    useEffect(() => {
        if (!hasFetched) {
            setHasFetched(true)
            fetchFeaturedVideos()
        }
    }, [hasFetched, setHasFetched, fetchFeaturedVideos])

    const makeCarousel = (focusSize) => (
        <Carousel focusSize={focusSize} videos={featuredVideos} />
    )

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.feature}>
                <PageContainer noPad>
                    {featuredVideos && (
                        <>
                            <Hidden smUp implementation="js">
                                {makeCarousel('xl')}
                            </Hidden>
                            <Hidden xsDown mdUp implementation="js">
                                {makeCarousel('lg')}
                            </Hidden>
                            <Hidden smDown implementation="js">
                                {makeCarousel('md')}
                            </Hidden>
                        </>
                    )}
                </PageContainer>
            </div>

            <PageContainer />
        </div>
    )
}

HomePage.propTypes = {
    /**
     * The videos to feature on the page
     */
    featuredVideos: PropTypes.array,
    /**
     * Handler to call to update the page title
     */
    setPageTitle: PropTypes.func.isRequired,
    /**
     * Function to call to fetch video data from the API
     */
    fetchFeaturedVideos: PropTypes.func.isRequired,
}

export default HomePage
