const initialState = {
    isLoading: false,
    error: null,
}

function VideoPage(state = initialState, action) {
    switch (action.type) {
        case 'PAGE_LOAD_START':
            return Object.assign({}, state, {
                isLoading: true,
            })

        case 'PAGE_LOAD_SUCCESS':
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
            })

        case 'PAGE_LOAD_ERROR':
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
            })

        default:
            return state
    }
}

export default VideoPage
