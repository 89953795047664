import { combineReducers } from 'redux'

const initialState = {
    isLoading: false,
    loadError: null,
    searchCriteria: {},
    entityIds: [],
    pagination: {},
}

export const makePageStateReducer = (pageName) => {
    return (state = initialState, action) => {
        switch (action.type) {
            case `INITIALIZE_PAGE_${pageName}`:
                return { ...initialState }

            case `UPDATE_PAGE_STATE_${pageName}`:
                return {
                    ...state,
                    ...action.data,
                }

            case `UPDATE_PAGINATION_${pageName}`:
                return {
                    ...state,
                    // pagination: action.pagination,
                    pagination: {
                        ...action.pagination,
                        prev: action.pagination.prev_page,
                        next: action.pagination.next_page,
                        current: action.pagination.current_page,
                        numPages: action.pagination.num_pages,
                    },
                }

            case `UPDATE_SEARCH_RESULTS_${pageName}`:
                return {
                    ...state,
                    entityIds: action.entityIds,
                    pagination: {
                        ...action.pagination,
                        prev: action.pagination.prev_page,
                        next: action.pagination.next_page,
                        current: action.pagination.current_page,
                        numPages: action.pagination.num_pages,
                    },
                    searchCriteria: action.searchCriteria,
                }

            case `PAGE_LOAD_START_${pageName}`:
                return { ...state, isLoading: true }

            case `PAGE_LOAD_SUCCESS_${pageName}`:
                return { ...state, isLoading: false, loadError: null }

            case `PAGE_LOAD_ERROR_${pageName}`:
                return { ...state, isLoading: false, loadError: action.error }

            default:
                return state
        }
    }
}

const PageState = combineReducers({
    videoPage: makePageStateReducer('videoPage'),
    videoSearch: makePageStateReducer('videoSearch'),
    jobPage: makePageStateReducer('jobPage'),
    jobSearch: makePageStateReducer('jobSearch'),
})

export default PageState
