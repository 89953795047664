import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import { useInterval } from '../utils'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: theme.spacing(2),
    },
    other: {
        padding: theme.spacing(2),
        backgroundColor: '#F1F1F1',
        borderRadius: theme.shape.borderRadius,
        outline: `1px dashed #5A5A5D`,
        outlineOffset: theme.spacing(-0.75),
    },
    standard: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '128px',
    },
    large: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '256px',
    },
    img: {
        display: 'block',
        width: '100%',
    },
    label: {
        display: 'block',
        textAlign: 'center',
        color: '#5A5A5D',
        width: '100%',
        fontVariant: 'small-caps',
    },
    largeLabel: {
        lineHeight: '2em',
        verticalAlign: 'baseline',
    },
}))

const Loader = ({ size, label, variant, src, withSuffix }) => {
    const classes = useStyles()
    const loaderClass = classNames({
        [classes.standard]: size === 'standard',
        [classes.large]: size === 'large',
    })
    const labelClass = classNames(classes.label, {
        [classes.largeLabel]: size === 'large',
    })

    const [suffix, setSuffix] = useState('')
    useInterval(
        () => {
            if (suffix === '') {
                setSuffix('.')
            } else if (suffix === '.') {
                setSuffix('..')
            } else if (suffix === '..') {
                setSuffix('...')
            } else if (suffix === '...') {
                setSuffix('')
            }
        },
        withSuffix ? 750 : null
    )

    const labelWithSuffix = label + suffix

    if (src) {
        return (
            <div className={classes.root}>
                <img className={classes.img} alt={label} src={src} />
                <Typography className={labelClass} variant="subtitle1">
                    {labelWithSuffix}
                </Typography>
            </div>
        )
    }

    if (variant === 'circular') {
        return (
            <div className={classes.root}>
                <CircularProgress
                    classes={{ root: loaderClass }}
                    color="secondary"
                    size="40"
                />
                <Typography className={labelClass} variant="subtitle1">
                    {labelWithSuffix}
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Typography className={labelClass} variant="subtitle1">
                {labelWithSuffix}
            </Typography>
            <LinearProgress classes={{ root: loaderClass }} color="secondary" />
        </div>
    )
}

Loader.propTypes = {
    /**
     * The URL of the loader to render.
     */
    src: PropTypes.string,
    /**
     * Variant of the loader, used when falling back. Defaults to linear
     */
    variant: PropTypes.oneOf(['linear', 'circular']),
    /**
     * The size of the loader
     */
    size: PropTypes.oneOf(['standard', 'large']),
    /**
     * The label to use with the loader
     */
    label: PropTypes.string,
    /**
     * Whether or not to display a ticking elipsis
     */
    withSuffix: PropTypes.bool,
}

Loader.defaultProps = {
    size: 'standard',
    label: 'Loading',
    variant: 'linear',
    withSuffix: true,
}

export default Loader
