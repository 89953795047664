import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from './ErrorBoundary'
import PageContainer from './PageContainer'

import Loader from '../containers/Loader'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const Page = ({
    title,
    favicon,
    isLoading,
    loadError,
    setPageTitle,
    setFaviconUrl,
    children,
}) => {
    useEffect(() => {
        if (title) {
            setPageTitle(title)
        }
    }, [title, setPageTitle])

    useEffect(() => {
        if (favicon) {
            setFaviconUrl(favicon)
        }
    }, [favicon, setFaviconUrl])

    const classes = useStyles()

    if (isLoading) {
        return (
            <div className={classes.root}>
                <PageContainer>
                    <Loader label="Loading page..." size="large" />
                </PageContainer>
            </div>
        )
    }

    if (loadError) {
        return (
            <div className={classes.root}>
                <PageContainer>
                    Error loading the page: {loadError}
                </PageContainer>
            </div>
        )
    }

    return (
        <ErrorBoundary>
            <div className={classes.root}>{children}</div>
        </ErrorBoundary>
    )
}

Page.propTypes = {
    /**
     * Page name representing the slice of state to use for page data
     */
    pageName: PropTypes.string,
    /**
     * Title of the page
     */
    title: PropTypes.string,
    /**
     * URL for the favicon to use
     */
    favicon: PropTypes.string,
    /**
     * Whether or not the page is currently loading
     */
    isLoading: PropTypes.bool.isRequired,
    /**
     * The error encountered while loading, if any
     */
    loadError: PropTypes.string,
    /**
     * Handler to set the page title
     */
    setPageTitle: PropTypes.func.isRequired,
    /**
     * Handler to set the favicon
     */
    setFaviconUrl: PropTypes.func.isRequired,
    /**
     * The body of the page to render
     */
    children: PropTypes.node.isRequired,
}

export default Page
