import { connect } from 'react-redux'

import JobsPage from '../components/JobsPage'
import { getPageState, getJobs, getPreviousSearchCriteria } from '../selectors'
import { goToPage, cancelJob, retryJob } from '../actions/JobsPage'

const mapStateToProps = (state) => {
    const pageName = 'jobSearch'
    const pageState = getPageState(state, pageName)
    const jobs = getJobs(state, pageState.entityIds)

    return {
        ...pageState,
        prevSearchCriteria: getPreviousSearchCriteria(state, pageName),
        jobs,
    }
}

const actionCreators = {
    cancelJob,
    retryJob,
    goToPage,
}

export default connect(
    mapStateToProps,
    actionCreators
)(JobsPage)
