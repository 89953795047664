import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Select, TextField } from 'redux-form-material-ui'

import { makeStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'

import { useAutoSearch } from '../utils'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        progress: {
            display: 'block',
            width: '100%',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        success: {
            display: 'flex',
            alignItems: 'center',
            color: green[500],
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.sharp,
            }),
        },
        error: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.error.main,
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.sharp,
            }),
        },
        submitIcon: {
            marginRight: theme.spacing(0.25),
        },
        grow: {
            flexGrow: 1,
        },
    }
})

const JobSearchForm = ({
    autoSearchParams,
    searchData,
    lastSearch,
    onSubmit,
    reset,
    change,
    remoteSubmit,
    isSubmitting,
    pristine,
}) => {
    // perform an immediate search whenever autoSearchParams are modified
    useAutoSearch(autoSearchParams, onSubmit, change)

    const classes = useStyles()
    return (
        <form className={classes.root} onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <Field
                            name="func_name"
                            label="Job Name"
                            placeholder="name"
                            component={TextField}
                            fullWidth
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="output_type">
                            Output Type
                        </InputLabel>
                        <Field
                            name="output_type"
                            label="Output Type"
                            component={Select}
                        >
                            <MenuItem value="gif">Gif</MenuItem>
                            <MenuItem value="image">Image</MenuItem>
                            <MenuItem value="sound_bite">Sound Bite</MenuItem>
                            <MenuItem value="video">Video</MenuItem>
                        </Field>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Field name="state" label="State" component={Select}>
                            <MenuItem value="created">Created</MenuItem>
                            <MenuItem value="queued">Queued</MenuItem>
                            <MenuItem value="started">Started</MenuItem>
                            <MenuItem value="success">Success</MenuItem>
                            <MenuItem value="failed">Failed</MenuItem>
                            <MenuItem value="dropped">Dropped</MenuItem>
                            <MenuItem value="cancelled">Cancelled</MenuItem>
                        </Field>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="sort">Sort</InputLabel>
                        <Field name="sort" label="Sort" component={Select}>
                            <MenuItem value="last_activity">
                                Last Activity
                            </MenuItem>
                            <MenuItem value="duration">Duration</MenuItem>
                        </Field>
                    </FormControl>
                </Grid>
            </Grid>

            <CardActions className={classes.actions}>
                {searchData._successMsg && (
                    <div className={classes.success}>
                        <CheckCircleOutlineIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {searchData._successMsg}
                        </Typography>
                    </div>
                )}
                {searchData._failedMsg && (
                    <div className={classes.error}>
                        <ErrorIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {searchData._failedMsg}
                        </Typography>
                    </div>
                )}
                <div className={classes.grow} />
                <Button variant="contained" onClick={reset}>
                    Reset
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={remoteSubmit}
                >
                    {isSubmitting ? 'Searching...' : 'Search'}
                </Button>
            </CardActions>
            {isSubmitting && (
                <div className={classes.progress}>
                    <LinearProgress />
                </div>
            )}
        </form>
    )
}

JobSearchForm.propTypes = {
    /**
     * If passed, perform an initial search with the provided params
     */
    autoSearchParams: PropTypes.object,
    /**
     * The most recently performed search
     */
    lastSearch: PropTypes.object,
    /**
     * The searchData associated with the form
     */
    searchData: PropTypes.object.isRequired,
    /**
     * Errors with the submission
     */
    errors: PropTypes.object,
    /**
     * Whether or not a submission is in progress
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Submit handler
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * Whether or not the form is unchanged
     */
    pristine: PropTypes.bool.isRequired,
    /**
     * Handler to reset form data to initial values
     */
    reset: PropTypes.func.isRequired,
    /**
     * Handler to change a form value
     */
    change: PropTypes.func.isRequired,
    /**
     * Submit function
     */
    remoteSubmit: PropTypes.func.isRequired,
}

export default JobSearchForm
