import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
    error: {
        border: '1px solid red',
        padding: theme.spacing(2),
        backgroundColor: 'pink',
        color: 'red',
    },
})

/**
 * An error boundary implementation
 */
class ErrorBoundary extends React.Component {
    /**
     * Component constructor.
     *
     * @param {object} props - The component props
     */
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    /**
     * Handler to generate new state upon error.
     *
     * @param {object} error - The error object
     * @return {object} - The derived state
     */
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error,
        }
    }

    /**
     * Handler to use when an error has been caught.
     *
     * @param {object} error - The error object
     */
    componentDidCatch(error) {
        console.log('Error captured in error boundary')
        console.error(error)
    }

    /**
     * Render the component.
     *
     * @return {node} - The component markup
     */
    render() {
        const { classes, children, fallback } = this.props
        const { hasError, error } = this.state

        if (hasError) {
            return (
                <>
                    <div className={classes.error}>
                        <Typography variant="p">
                            Something went wrong: {error.message}
                        </Typography>
                    </div>
                    {fallback}
                </>
            )
        }

        return children
    }
}

ErrorBoundary.propTypes = {
    /**
     * CSS classes
     */
    classes: PropTypes.object.isRequired,
    /**
     * The content to render when there is no error
     */
    children: PropTypes.node.isRequired,
    /**
     * An optional fallback element to render instead
     */
    fallback: PropTypes.node,
}

export default withStyles(styles)(ErrorBoundary)
