import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import ReelItem from '../components/ReelItem'
import { onSeek, onShare, setThumbnail } from '../actions/ReelItem'

const actionCreators = {
    onSeek,
    onShare,
    setThumbnail,
}

export default connect(undefined, actionCreators)(withSnackbar(ReelItem))
