import React from 'react'
import PropTypes from 'prop-types'

import Page from '../containers/Page'
import VideoSearchForm from '../containers/VideoSearchForm'

import PageContainer from './PageContainer'
import Pagination from './Pagination'
import VideoResultsGridList from './VideoResultsGridList'
import { useSyncedQueryParams, useResolvedSearchParams } from '../utils'

const VideoSearchPage = ({
    videos,
    pagination,
    prevSearchCriteria,
    goToPage,
}) => {
    // if we have performed a search and the current query params
    // don't reflect it, update the query params
    useSyncedQueryParams(prevSearchCriteria)
    const autoSearchParams = useResolvedSearchParams(prevSearchCriteria)

    return (
        <Page title="Video Search" pageName="videoSearch">
            <PageContainer>
                <VideoSearchForm
                    form="videoSearch"
                    autoSearchParams={autoSearchParams}
                />
                <Pagination
                    showNearby
                    {...pagination}
                    onPageChange={goToPage}
                />
                <VideoResultsGridList videos={videos} />
                <Pagination
                    showNearby
                    {...pagination}
                    onPageChange={goToPage}
                />
            </PageContainer>
        </Page>
    )
}

VideoSearchPage.propTypes = {
    /**
     * The videos to render
     */
    videos: PropTypes.array,
    /**
     * Previous search performed this session, if any
     */
    prevSearchCriteria: PropTypes.object,
    /**
     * Pagination data for the search results
     */
    pagination: PropTypes.object.isRequired,
    /**
     * Function to call when changing page of search results
     */
    goToPage: PropTypes.func.isRequired,
}

export default VideoSearchPage
