import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import LinkIcon from '@material-ui/icons/Link'
import RestoreIcon from '@material-ui/icons/Restore'

import Loader from '../../containers/Loader'
import Stat from '../Stat'
import Link from '../Link'
import JobStateIcon from './JobStateIcon'
import JobStateStepper from './JobStateStepper'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    body: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        position: 'relative',
    },
    footer: {},
    paper: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2, 4),
        padding: theme.spacing(1),
        outline: 0,
    },
    meta: {},
    error: {},
    actions: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    statusIcon: {
        marginRight: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    prewrap: {
        background: theme.palette.grey[200],
        maxHeight: '450px',
        overflow: 'scroll',
        width: '100%',
        padding: theme.spacing(1),
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        margin: `0 0 ${theme.spacing(1)}px 0`,
    },
    stats: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    videoCompare: {},
    before: {
        width: '50%',
        '& video': {
            display: 'block',
            width: '100%',
        },
    },
    after: {
        width: '50%',
        '& video': {
            display: 'block',
            width: '100%',
        },
    },
    inlineReel: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    reelItem: {
        width: '50%',
        backgroundColor: theme.palette.grey[200],
    },
}))

const JobCardBase = ({
    job,
    link,
    title,
    subtitle,
    preview,
    expanded,
    cancelJob,
    retryJob,
    enqueueSnackbar,
}) => {
    const classes = useStyles()
    const [isCollapsed, setCollapsed] = useState(!expanded)
    const [showMeta, setShowMeta] = useState(false)

    const [modName, funcName] = job.func_name.split('.').slice(-2)
    const mayRetry = ['dropped', 'failed', 'cancelled', 'success'].includes(
        job.state
    )
    const mayCancel = ['queued', 'started'].includes(job.state)
    const isWorking = ['created', 'queued', 'started'].includes(job.state)

    const jobActions = (
        <div className={classes.actions}>
            {link && (
                <Link src={link}>
                    <IconButton>
                        <LinkIcon />
                    </IconButton>
                </Link>
            )}

            <Tooltip title={showMeta ? 'Hide Metadata' : 'Show Metadata'}>
                <IconButton onClick={() => setShowMeta(!showMeta)}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>

            {mayRetry && (
                <Tooltip title="Retry">
                    <IconButton
                        onClick={() => retryJob(job.id, enqueueSnackbar)}
                    >
                        <RestoreIcon />
                    </IconButton>
                </Tooltip>
            )}

            {mayCancel && (
                <Tooltip title="Cancel">
                    <IconButton
                        onClick={() => cancelJob(job.id, enqueueSnackbar)}
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    )

    const jobFooter = (
        <div className={classes.footer}>
            <JobStateStepper job={job} />
            {/*  */}
            {/* <Paper elevation={0}> */}
            {/*     <div className={classes.stats}> */}
            {/*         <Timer */}
            {/*             vertical */}
            {/*             label="Time To Completion" */}
            {/*             startDate={job.last_retried_at || job.created_at} */}
            {/*             endDate={job.ended_at} */}
            {/*             active={ */}
            {/*                 job.state === 'started' || job.state === 'queued' */}
            {/*             } */}
            {/*         /> */}
            {/*         <Timer */}
            {/*             vertical */}
            {/*             label="Time in Queue" */}
            {/*             startDate={job.enqueued_at} */}
            {/*             endDate={job.started_at} */}
            {/*             active={job.state === 'queued'} */}
            {/*         /> */}
            {/*         <Timer */}
            {/*             vertical */}
            {/*             label="Execution Time" */}
            {/*             startDate={job.started_at} */}
            {/*             endDate={job.ended_at} */}
            {/*             active={job.state === 'started'} */}
            {/*         /> */}
            {/*     </div> */}
            {/* </Paper> */}
        </div>
    )

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
                onClick={() => setCollapsed(!isCollapsed)}
            >
                <div className={classes.statusIcon}>
                    <JobStateIcon jobState={job.state} />
                </div>
                <div>
                    <Typography variant="h5" noWrap>
                        {title || funcName}
                    </Typography>
                    <Typography variant="subtitle1" noWrap>
                        {subtitle || modName}
                    </Typography>
                </div>
                <div className={classes.grow} />
                <div>
                    <Stat label="Args">{JSON.stringify(job.args)}</Stat>
                    <Stat label="Kwargs">{JSON.stringify(job.kwargs)}</Stat>
                </div>
            </div>

            {!isCollapsed && (
                <div className={classes.body}>
                    {jobActions}

                    {showMeta && (
                        <div className={classes.meta}>
                            <Typography variant="subtitle1">
                                Job Metadata
                            </Typography>
                            <div className={classes.prewrap}>
                                {JSON.stringify(job.meta, null, 4)}
                            </div>
                        </div>
                    )}

                    {job.state === 'failed' && job.result && job.result.error && (
                        <div className={classes.error}>
                            <Typography variant="subtitle1">
                                Job Error
                            </Typography>
                            <div className={classes.prewrap}>
                                {job.result.error.split('\n').map((errline) => {
                                    return (
                                        <>
                                            {errline}
                                            <br />
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {job.state !== 'failed' && job.result && (
                        <div>
                            <Typography variant="subtitle1">
                                Job Result
                            </Typography>
                            <div className={classes.prewrap}>
                                {JSON.stringify(job.result, null, 4)}
                            </div>
                        </div>
                    )}

                    {isWorking && (
                        <div className={classes.prewrap}>
                            <Loader />
                        </div>
                    )}

                    {preview}

                    <div className={classes.grow} />

                    {jobFooter}
                </div>
            )}
        </div>
    )
}

JobCardBase.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    job: PropTypes.object.isRequired,
    link: PropTypes.string,
    expanded: PropTypes.bool,
    preview: PropTypes.node,
    cancelJob: PropTypes.func,
    retryJob: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
}

export default JobCardBase
