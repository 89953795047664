import { normalize } from 'normalizr'
import { mediaSchema } from '../schema'
import { updateEntities } from './Entities'
import { pageLoadStart, pageLoadError, pageLoadSuccess } from './VideoPage'
import { logout } from './User'

export const updateFeaturedVideoIds = (videoIds) => ({
    type: 'UPDATE_FEATURED_VIDEO_IDS',
    videoIds,
})

export const fetchFeaturedVideos = () => {
    return (dispatch) => {
        dispatch(pageLoadStart())

        return fetch('/api/v1/videos/featured')
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())

                    return json.then((videos) => {
                        const normalized = normalize(videos, [mediaSchema])
                        dispatch(updateEntities(normalized.entities))
                        dispatch(updateFeaturedVideoIds(normalized.result))
                    })
                }

                if (res.status === 401) {
                    logout()
                }

                dispatch(pageLoadError('Bad response from server'))
            })
            .catch((error) => console.error(error))
    }
}
