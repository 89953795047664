import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import JobSearchForm from '../containers/JobSearchForm'
import JobCard from '../components/JobCard/JobCard'
import Pagination from '../components/Pagination'
import PageContainer from '../components/PageContainer'
import Page from '../containers/Page'

import { useResolvedSearchParams, useSyncedQueryParams } from '../utils'

const useStyles = makeStyles((theme) => ({
    results: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
    },
}))

const JobSearchPage = ({
    jobs,
    pagination,
    prevSearchCriteria,
    goToPage,
    cancelJob,
    retryJob,
}) => {
    const classes = useStyles()

    // if we have performed a search and the current query params
    // don't reflect it, update the query params
    useSyncedQueryParams(prevSearchCriteria)
    const autoSearchParams = useResolvedSearchParams(prevSearchCriteria)

    const jobCards = jobs.map((job, i) => {
        const props = {
            job,
            cancelJob,
            retryJob,
        }
        return <JobCard key={job.id} {...props} />
    })

    return (
        <Page title="Jobs" pageName="jobSearch">
            <PageContainer>
                <JobSearchForm
                    form="jobSearch"
                    autoSearchParams={autoSearchParams}
                />
                <Pagination
                    showNearby
                    {...pagination}
                    onPageChange={goToPage}
                />
                <div className={classes.results}>{jobCards}</div>
                <Pagination
                    showNearby
                    {...pagination}
                    onPageChange={goToPage}
                />
            </PageContainer>
        </Page>
    )
}

JobSearchPage.propTypes = {
    /**
     * The jobs to render
     */
    jobs: PropTypes.array.isRequired,
    /**
     * The most recently submitted search criteria
     */
    prevSearchCriteria: PropTypes.object,
    /**
     * Pagination data for the search results
     */
    pagination: PropTypes.object.isRequired,
    /**
     * Function to call when changing page of search results
     */
    goToPage: PropTypes.func.isRequired,
    /**
     * Handler for cancelling a job
     */
    cancelJob: PropTypes.func,
    /**
     * Handler for retrying a job
     */
    retryJob: PropTypes.func,
}

export default JobSearchPage
