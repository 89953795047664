import { connect } from 'react-redux'
import PrivateRoute from '../components/PrivateRoute'
import { getCurrentUser } from '../selectors'

const mapStateToProps = (state) => {
    return {
        user: getCurrentUser(state),
    }
}

export default connect(
    mapStateToProps,
    undefined
)(PrivateRoute)
