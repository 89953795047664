import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: 'none',
    },
    button: {
        display: 'flex',
        padding: '6px',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[2],
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    inherit: {
        color: 'inherit',
        alignItems: 'center',
    },
}))

const Link = ({ src, color, variant, children }) => {
    const classes = useStyles()
    const className = classNames('navigate', classes.root, {
        [classes.inherit]: color === 'inherit',
        [classes.button]: variant === 'button',
    })
    return (
        <RouterLink className={className} to={src}>
            {children}
        </RouterLink>
    )
}

// const Link = React.forwardRef(
//     ({ classes, children, src, color, variant }, ref) => {
//         const className = classNames('navigate', classes.root, {
//             [classes.inherit]: color === 'inherit',
//             [classes.button]: variant === 'button',
//         })
//         return (
//             <a className={className} href={src} ref={ref}>
//                 {children}
//             </a>
//         )
//     },
// )

Link.propTypes = {
    children: PropTypes.node.isRequired,
    src: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['inherit']),
    variant: PropTypes.oneOf(['button']),
}

export default Link
