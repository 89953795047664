import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { useInterval } from '../utils'
import { fetchJob } from '../actions/JobsPage'
import JobCardBase from '../components/JobCard/JobCardBase'

const PollableJobCard = ({ job, fetchJob, pollDelay, ...jobCardProps }) => {
    pollDelay =
        job.state === 'queued' || job.state === 'started' ? pollDelay : null

    useInterval(() => {
        fetchJob(job.id)
    }, pollDelay)

    return <JobCardBase job={job} {...jobCardProps} />
}

PollableJobCard.propTypes = {
    /**
     * The job to render
     */
    job: PropTypes.object.isRequired,
    /**
     * Function to fetch job data from the server
     */
    fetchJob: PropTypes.func,
    /**
     * The time to wait between fetch attempts, in ms
     */
    pollDelay: PropTypes.number,
}

export default connect(
    null,
    { fetchJob }
)(PollableJobCard)
