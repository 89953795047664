import React from 'react'
import PropTypes from 'prop-types'

import { green, red } from '@material-ui/core/colors'
import CancelledIcon from '@material-ui/icons/RemoveCircleOutline'
import CreatedIcon from '@material-ui/icons/AddCircleOutline'
import FailedIcon from '@material-ui/icons/ErrorOutline'
import QueuedIcon from '@material-ui/icons/Schedule'
import StartedIcon from '@material-ui/icons/PlayCircleOutline'
import SuccessIcon from '@material-ui/icons/CheckCircleOutline'
import UnknownIcon from '@material-ui/icons/HelpOutline'

const JobStateIcon = ({ jobState }) => {
    let icon
    const iconProps = {
        alt: jobState,
        fontSize: 'large',
    }

    if (jobState === 'created') {
        icon = <CreatedIcon {...iconProps} />
    } else if (jobState === 'queued') {
        icon = <QueuedIcon {...iconProps} />
    } else if (jobState === 'started') {
        icon = <StartedIcon {...iconProps} />
    } else if (jobState === 'success') {
        icon = <SuccessIcon style={{ color: green[500] }} {...iconProps} />
    } else if (jobState === 'failed') {
        icon = <FailedIcon style={{ color: red[500] }} {...iconProps} />
    } else if (jobState === 'cancelled') {
        icon = <CancelledIcon {...iconProps} />
    } else {
        icon = <UnknownIcon {...iconProps} />
    }

    return icon
}

JobStateIcon.propTypes = {
    /**
     * The state whose icon to render
     */
    jobState: PropTypes.string.isRequired,
}

export default JobStateIcon
