import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/MoreVert'

import Stat from './Stat'
import TagList from './TagList'
import MediaResultCard from './MediaResultCard'
import VideoForm from '../containers/VideoForm'
import VisibilityIcon from './VisibilityIcon'

const useStyles = makeStyles((theme) => ({
    root: {},
    digitals: {
        display: 'inline-grid',
        width: 'calc(100% - 48px)',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridGap: theme.spacing(3),
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            gridGap: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            gridGap: theme.spacing(1),
        },
    },
    source: {
        maxWidth: '250px',
    },
}))

const VideoDetails = ({
    editable,
    video,
    reencodeVideo,
    updateMetadata,
    generateSpectrogram,
}) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [isEditable, setEditable] = useState(editable)
    const [menuAnchor, setMenuAnchor] = useState(null)
    const duration = moment
        .utc(video.duration * 1000)
        .format('HH:mm:ss')
        .replace('00:', '')
    const size = `${(video.size / 1024 / 1024).toFixed(2)} MB`

    if (isEditable) {
        return (
            <div className={classes.root}>
                <VideoForm
                    form={`video-form-${video.id}`}
                    initialValues={video}
                    onCancel={() => setEditable(false)}
                />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.digitals}>
                <Stat label="Duration">{duration}</Stat>
                <Stat label="Size">{size}</Stat>
                <Stat label="Resolution">{video.resolution || 'N/A'}</Stat>
                {video.user && (
                    <Stat label="Uploader">{video.user.username}</Stat>
                )}
                <Stat label="Visibility">
                    <VisibilityIcon visibility={video.visibility} />
                </Stat>
            </div>
            <IconButton
                aria-owns={menuAnchor ? 'video-options' : undefined}
                aria-haspopup="true"
                onClick={(evt) => setMenuAnchor(evt.target)}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id="video-options"
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={() => setEditable(true)}>Edit</MenuItem>
                <MenuItem
                    onClick={() => updateMetadata(video.id, enqueueSnackbar)}
                >
                    Update Metadata
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        reencodeVideo(video.id, enqueueSnackbar, 'av1')
                    }
                >
                    Re-encode to AV1
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        reencodeVideo(video.id, enqueueSnackbar, 'hevc')
                    }
                >
                    Re-encode to HEVC
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        reencodeVideo(video.id, enqueueSnackbar, 'h264')
                    }
                >
                    Re-encode to H.264
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        generateSpectrogram(video.id, enqueueSnackbar)
                    }
                >
                    Generate Spectrogram
                </MenuItem>
            </Menu>
            <TagList tags={video.tags} />
            {video.source && (
                <div className={classes.source}>
                    <Typography variant="button">Source Media:</Typography>
                    <MediaResultCard media={video.source} />
                </div>
            )}
        </div>
    )
}

VideoDetails.propTypes = {
    /**
     * The video whose details to render
     */
    video: PropTypes.object.isRequired,
    /**
     * Whether or not to render an editable view
     */
    editable: PropTypes.bool,
    /**
     * Handler to update metadata for the media
     */
    updateMetadata: PropTypes.func,
    /**
     * Handler to trigger job to re-encode video
     */
    reencodeVideo: PropTypes.func,
    /**
     * Handler to trigger job to generate spectrogram
     */
    generateSpectrogram: PropTypes.func,
}

export default VideoDetails
