import { connect } from 'react-redux'
import NavBar from '../components/NavBar'
import {
    getCurrentUser,
    getPageTitle,
    getLogo,
    getTitle,
    getCurrentUri,
} from '../selectors'
import { logout } from '../actions/LoginForm'

const mapStateToProps = (state) => ({
    logo: getLogo(state),
    title: getTitle(state),
    pageTitle: getPageTitle(state),
    user: getCurrentUser(state),
    currentUri: getCurrentUri(state),
})

const actionCreators = { logout }

export default connect(
    mapStateToProps,
    actionCreators
)(NavBar)
