import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import JobsIcon from '@material-ui/icons/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import MovieIcon from '@material-ui/icons/Movie'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ShuffleIcon from '@material-ui/icons/Shuffle'

import Link from './Link'
import KeywordSearchForm from '../containers/KeywordSearchForm'

export const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        flexShrink: 0,
    },
    logo: {
        height: '40px',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            height: '30px',
        },
    },
    grow: {
        flexGrow: 1,
    },
    appId: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.grey[600],
    },
    appEmphasis: {
        fontWeight: 700,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    title: {
        maxWidth: 'calc(100% - 48px)',
    },
}))

const NavBar = ({ logo, pageTitle, title, user, logout, currentUri }) => {
    const [menuAnchor, setMenuAnchor] = useState(null)
    const [collapsed, setCollapsed] = useState(true)
    const history = useHistory()

    const classes = useStyles()
    const titleWords = title.split(/(?=[A-Z])/)

    const makeMenuItem = (onClick, label, link, children) => {
        const active = link === currentUri
        const item = (
            <ListItem button selected={active} onClick={onClick}>
                <ListItemIcon>{children}</ListItemIcon>
                <ListItemText primary={label} />
            </ListItem>
        )
        if (active) {
            return item
        }
        return (
            <Link src={link} color="inherit">
                {item}
            </Link>
        )
    }
    const makeDrawerContents = (onClick) => (
        <>
            <div className={classes.appId}>
                <img className={classes.logo} alt={title} src={logo} />
                <Typography variant="h5" color="inherit" noWrap>
                    <span className={classes.appEmphasis}>{titleWords[0]}</span>
                    {titleWords.slice(1).join('')}
                </Typography>
            </div>
            <Divider />
            <List>
                <Hidden mdUp implementation="js">
                    <ListItem>
                        <KeywordSearchForm searchUrl="/videos" />
                    </ListItem>
                </Hidden>
                {makeMenuItem(onClick, 'Home', '/', <HomeIcon />)}
                {makeMenuItem(onClick, 'Videos', '/videos', <MovieIcon />)}
                {user &&
                    user.username === 'admin' &&
                    makeMenuItem(onClick, 'Jobs', '/jobs', <JobsIcon />)}
            </List>
            <Divider />
        </>
    )
    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        className={classes.menuButton}
                        onClick={() => setCollapsed(false)}
                        aria-label="Open drawer"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        {pageTitle}
                    </Typography>
                    <div className={classes.grow} />
                    <Hidden smDown implementation="css">
                        <KeywordSearchForm searchUrl="/videos" />
                    </Hidden>
                    <Link src="/videos/random" color="inherit">
                        <IconButton color="inherit">
                            <ShuffleIcon />
                        </IconButton>
                    </Link>
                    <IconButton
                        aria-owns={menuAnchor ? 'login-options' : undefined}
                        aria-haspopup="true"
                        onClick={(evt) => setMenuAnchor(evt.target)}
                        color="inherit"
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        id="login-options"
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={() => setMenuAnchor(null)}
                    >
                        {user && user.id ? (
                            [
                                <MenuItem key={0} onClick={undefined}>
                                    Profile
                                </MenuItem>,
                                <MenuItem
                                    key={1}
                                    onClick={() => logout(history)}
                                >
                                    Sign out
                                </MenuItem>,
                            ]
                        ) : (
                            <MenuItem onClick={undefined}>Sign in</MenuItem>
                        )}
                    </Menu>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={!collapsed}
                        onClose={() => setCollapsed(true)}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {makeDrawerContents(() => setCollapsed(true))}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {makeDrawerContents(() => setCollapsed(true))}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}

NavBar.propTypes = {
    /**
     * Page title
     */
    pageTitle: PropTypes.string.isRequired,
    /**
     * Name of the application
     */
    title: PropTypes.string.isRequired,
    /**
     * App logo
     */
    logo: PropTypes.string.isRequired,
    /**
     * User state
     */
    user: PropTypes.object.isRequired,
    /**
     * Fuction to log out the logged-in user
     */
    logout: PropTypes.func.isRequired,
    /**
     * The URI active in the browser
     */
    currentUri: PropTypes.string,
}

export default NavBar
