import { change, getFormValues, SubmissionError } from 'redux-form'
import { normalize } from 'normalizr'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import { mediaSchema } from '../schema'
import { getPreviousSearchCriteria } from '../selectors'
import { updateEntities } from './Entities'
import { makeUpdateSearchResults } from './PageState'

export const updateSearchResults = makeUpdateSearchResults('videoSearch')

export const goToPage = (page) => {
    return (dispatch) => {
        dispatch(change('videoSearch', 'page', page))
        dispatch(doVideoSearch())
    }
}

export const onIncludeListChange = (
    value,
    { action, removedValue },
    skipSearch
) => {
    return (dispatch) => {
        let newTags = []
        if (value) {
            if (value.constructor === Array) {
                newTags = newTags.concat(value)
            } else {
                newTags = newTags.concat(value.split(',').map((s) => s.trim()))
            }
        }
        dispatch(
            change(
                'videoSearch',
                'include_tags',
                newTags.map((val) => val.value)
            )
        )
        if (!skipSearch) {
            dispatch(doVideoSearch())
        }
    }
}

export const onExcludeListChange = (
    value,
    { action, removedValue },
    skipSearch
) => {
    return (dispatch) => {
        let newTags = []
        if (value) {
            if (value.constructor === Array) {
                newTags = newTags.concat(value)
            } else {
                newTags = newTags.concat(value.split(',').map((s) => s.trim()))
            }
        }
        dispatch(
            change(
                'videoSearch',
                'exclude_tags',
                newTags.map((val) => val.value)
            )
        )
        if (!skipSearch) {
            dispatch(doVideoSearch())
        }
    }
}

export const doVideoSearch = (searchCriteria) => {
    return (dispatch) => {
        return dispatch(
            doSearch(
                'videoSearch',
                '/api/v1/videos/search',
                mediaSchema,
                updateSearchResults,
                searchCriteria
            )
        )
    }
}

/**
 * Perform a search
 *
 * @param {str}    form      The form name
 * @param {str}    endpoint  The search endpoint to use
 * @param {schema} schema    The normalizr schema to use to normalize results
 * @param {func}   updateFn  The function to call with the results of the
 *                           search. This function will be called with the
 *                           normalized search result, pagination data for the
 *                           result set, and the search criteria that was used.
 * @param {object} searchCriteria  The search criteria to use. If omitted, the
 *                                 current values of the named form will be used.
 * @return {func}  A thunk middleware compatible async dispatcher
 */
export const doSearch = (form, endpoint, schema, updateFn, searchCriteria) => {
    return (dispatch, getState) => {
        const state = getState()

        // fetch the current form state if no searchCriteria are passed in
        if (!searchCriteria || Object.keys(searchCriteria).length === 0) {
            searchCriteria = getFormValues(form)(state) || {}
        }

        // if the new search params differ from the last (other than the page
        // number) force the page back to the first
        const prevParams = getPreviousSearchCriteria(state, form)
        if (
            !isEqual(omit(prevParams, ['page']), omit(searchCriteria, ['page']))
        ) {
            searchCriteria.page = 1
        }

        // convert the search criteria to a query string
        const qs = Object.keys(searchCriteria)
            .reduce((acc, key) => {
                acc.append(key, searchCriteria[key])
                return acc
            }, new URLSearchParams())
            .toString()

        return fetch(`${endpoint}?${qs}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            const json = res.json()

            if (res.ok) {
                return json.then((data) => {
                    const normalized = normalize(data.results, [schema])
                    dispatch(updateEntities(normalized.entities))
                    dispatch(
                        updateFn(
                            normalized.result,
                            data.pagination,
                            searchCriteria
                        )
                    )
                })
            }

            return json.then((errorData) => {
                throw new SubmissionError(errorData.errors)
            })
        })
    }
}
