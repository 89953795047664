import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'

import Page from '../containers/Page'
import Reel from '../containers/Reel'
import ReelForm from '../containers/ReelForm'
import TwitterMeta from '../containers/TwitterMeta'

import Editor from './Editor'
import ErrorBoundary from './ErrorBoundary'
import PageContainer from './PageContainer'
import VideoDetails from './VideoDetails'
import VideoPlayer from './VideoPlayer'
import VideoResultsGridList from './VideoResultsGridList'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spectro: {
        display: 'block',
        margin: 0,
        padding: '0 12px',
        width: '100%',
        height: '60px',
    },
    theaterPane: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[500],
        boxShadow: `inset 0 -10px 20px -6px ${theme.palette.grey[800]}`,
        width: '100%',
    },
    paper: {
        width: '640px',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(2),
    },
    h3: {
        fontSize: '1.2rem',
    },
}))

const VideoPage = ({
    video,
    fetchVideo,
    generateSpectrogram,
    reencodeVideo,
    updateMetadata,
    submitReelForm,
}) => {
    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)
    const videoEl = useRef()

    // fetch the specified video from the server whenever the requested video
    // ID changes
    const { id: reqVideoId } = useParams()
    const { id: curVideoId, slug: curVideoSlug, title } = video || {}
    useEffect(() => {
        fetchVideo(reqVideoId)
    }, [reqVideoId, fetchVideo])

    // update the URL to reflect the current video, if necessary
    const history = useHistory()
    useEffect(() => {
        if (curVideoId && reqVideoId === `${curVideoId}`) {
            history.replace(
                history.location.pathname.replace(
                    `/${reqVideoId}`,
                    `/${curVideoSlug}`
                )
            )
        }
    }, [reqVideoId, curVideoId, curVideoSlug, history])

    if (!video || video.media_type !== 'video') {
        return (
            <Page title="Video Page" pageName="videoPage">
                No video
            </Page>
        )
    }

    const reels = video.collections
        ? video.collections.map((collection) => (
              <ErrorBoundary key={collection.id}>
                  <Reel
                      noWrap
                      stacked
                      collapsed
                      collectionId={collection.id}
                      source={video}
                  />
              </ErrorBoundary>
          ))
        : null

    // hackish way to get the spectrogram, if there is one
    let spectrogram
    if (video.collections) {
        const spectro = video.collections.find((collection) => {
            const numItems = collection.items.length
            const numOps = collection.transform.spec.operations.length
            const lastOp = collection.transform.spec.operations[numOps - 1]
            return (
                numItems === 1 &&
                numOps === 2 &&
                lastOp.filters.includes('spectrogram')
            )
        })
        if (spectro) {
            spectrogram = (
                <img
                    className={classes.spectro}
                    src={spectro.items[0].url}
                    alt="spectrogram"
                />
            )
        }
    }

    return (
        <Page title={title} pageName="videoPage">
            <TwitterMeta media={video} />

            <div className={classes.theaterPane}>
                <PageContainer id="primary" noPad>
                    {isEditing ? (
                        <Editor subject={video} fullWidth />
                    ) : (
                        <VideoPlayer
                            id="primary-video"
                            ref={videoEl}
                            video={video}
                            submitReelForm={submitReelForm}
                            autoPlay
                            controls
                            loop
                        />
                    )}
                    {spectrogram}
                    <IconButton onClick={() => setIsEditing(!isEditing)}>
                        <EditIcon />
                    </IconButton>
                </PageContainer>
            </div>

            <PageContainer>
                <VideoDetails
                    video={video}
                    generateSpectrogram={generateSpectrogram}
                    reencodeVideo={reencodeVideo}
                    updateMetadata={updateMetadata}
                />
                <Divider variant="middle" />
                {reels && <div className={classes.reels}>{reels}</div>}
                <Divider variant="middle" />
                <Paper classes={{ root: classes.paper }}>
                    <Typography variant="h3" classes={{ h3: classes.h3 }}>
                        Create A New Reel
                    </Typography>
                    {video && (
                        <ReelForm
                            form={`reelForm-source${video.id}`}
                            source={video}
                            initialValues={{
                                source_id: video.id,
                                source_type: video.media_type,
                            }}
                        />
                    )}
                </Paper>
                <Typography variant="h5">More Like This</Typography>
                <VideoResultsGridList videos={video.related || []} />
            </PageContainer>
        </Page>
    )
}

VideoPage.propTypes = {
    /**
     * The video to render
     */
    video: PropTypes.object,
    /**
     * Function to fetch a video from the backend
     */
    fetchVideo: PropTypes.func.isRequired,
    /**
     * Handler to call to update video metadata
     */
    updateMetadata: PropTypes.func.isRequired,
    /**
     * Handler to trigger job to re-encode video
     */
    reencodeVideo: PropTypes.func,
    /**
     * Handler to trigger job to generate spectrogram
     */
    generateSpectrogram: PropTypes.func,
    /**
     * Handler to submit a form to generate a new reel
     */
    submitReelForm: PropTypes.func,
}

export default VideoPage
