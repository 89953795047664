import { normalize } from 'normalizr'
import { collectionSchema } from '../schema'
import { updateEntities, removeCollection, replaceEntity } from './Entities'

export const addCollection = (collection) => {
    return (dispatch) => {
        const normalized = normalize(collection, collectionSchema)
        dispatch(updateEntities(normalized.entities))
        // this is a dumb way to 'overwrite' the particular video
        // in question, rather than merging with the usual strategy
        dispatch(
            replaceEntity(
                'collections',
                normalized.entities.collections[normalized.result]
            )
        )
    }
}

export const fetchCollection = (collectionId) => {
    return (dispatch) => {
        return fetch(`/api/v1/collections/${collectionId}`)
            .then((res) => {
                const json = res.json()
                if (res.ok) {
                    return json.then((collection) => {
                        dispatch(addCollection(collection))
                    })
                }
            })
            .catch((error) => console.error(error))
    }
}

export const onRegenerate = (collectionId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/collections/${collectionId}/regenerate`, {
            method: 'POST',
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((collection) => {
                        dispatch(addCollection(collection))
                    })
                }

                return json.then((error) =>
                    enqueueSnackbar(`Failed to regenerate: ${error}`, {
                        variant: 'error',
                    })
                )
            })
            .catch((error) => console.error(error))
    }
}

export const onDelete = (collectionId, enqueueSnackbar) => {
    return (dispatch) => {
        return fetch(`/api/v1/collections/${collectionId}`, {
            method: 'DELETE',
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((collection) => {
                        enqueueSnackbar('Successfully deleted!', {
                            variant: 'success',
                        })
                        dispatch(
                            removeCollection(
                                collection.id,
                                collection.source_id
                            )
                        )
                    })
                }

                return json.then((error) =>
                    enqueueSnackbar(`Failed to delete: ${error}`, {
                        variant: 'error',
                    })
                )
            })
            .catch((error) => console.error(error))
    }
}
