import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const TwitterMeta = ({ appState, media }) => {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={appState.twitter_handle} />
            {media.user && media.user.twitter_handle && (
                <meta
                    name="twitter:creator"
                    content={media.user.twitter_handle}
                />
            )}
            <meta name="twitter:title" content={media.title} />
            <meta
                name="twitter:image"
                content={
                    media.media_type === 'video' ? media.thumbnail : media.url
                }
            />
            <meta
                name="twitter:image:alt"
                content={
                    media.title +
                    ': ' +
                    media.tags.map((tag) => tag && tag.name).join(', ')
                }
            />
        </Helmet>
    )
}

TwitterMeta.propTypes = {
    /**
     * The current application state
     */
    appState: PropTypes.object.isRequired,
    /**
     * The media object for which to generate meta
     */
    media: PropTypes.object,
}

export default TwitterMeta
