import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    center: {
        justifyContent: 'center',
    },
    noWrap: {
        flexWrap: 'nowrap',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
            background: 'transparent',
        },
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    dense: {
        margin: theme.spacing(0.25),
        padding: theme.spacing(0.5, 0.25),
        height: '24px',
        borderRadius: '12px',
    },
}))

const TagList = ({ tags, align, noWrap, dense, onDelete }) => {
    const classes = useStyles()
    const className = classNames(classes.root, {
        [classes.noWrap]: noWrap,
        [classes.center]: align === 'center',
    })
    const chipClassName = classNames(classes.chip, {
        [classes.dense]: dense,
    })
    const tagList = tags
        .sort((a, b) => {
            const aname = a.name.toLowerCase()
            const bname = b.name.toLowerCase()

            if (aname > bname) {
                return 1
            } else if (aname < bname) {
                return -1
            }

            return 0
        })
        .map((tag) => {
            return (
                <Chip
                    key={tag.id}
                    className={chipClassName}
                    label={tag.name}
                    onDelete={onDelete}
                />
            )
        })
    return <div className={className}>{tagList}</div>
}

TagList.propTypes = {
    /**
     * How to align the tags within the list
     */
    align: PropTypes.oneOf(['center']),
    /**
     * Whether or not to wrap tags
     */
    noWrap: PropTypes.bool,
    /**
     * Conserve space
     */
    dense: PropTypes.bool,
    /**
     * List of Tags to render
     */
    tags: PropTypes.array.isRequired,
    /**
     * Handler for tag deletion. It will be passed the tag ID
     */
    onDelete: PropTypes.func,
}

export default TagList
