const initialState = {
    ids: [],
    pagination: {},
    searchParams: {},
}

export default function SearchResults(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_SEARCH_RESULTS':
            return {
                ...state,
                ids: action.ids,
                pagination: action.pagination,
                searchParams: action.searchParams,
            }

        default:
            return state
    }
}
