import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'

import Link from './Link'
import TagList from './TagList'
import VisibilityIcon from './VisibilityIcon'

const styles = (theme) => ({
    root: {},
    headerContent: {
        width: 'calc(100% - 56px)',
    },
    subHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    subHeaderIcon: {
        marginRight: theme.spacing(0.5),
    },
    thumbContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
    },
    thumb: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
})

const MediaResultCard = ({ classes, media }) => {
    const thumb =
        media.media_type === 'video'
            ? media.thumbnail
            : media.media_type === 'image' || media.media_type === 'gif'
            ? media.url
            : null

    return (
        <Card className={classes.root}>
            <Link src={`/videos/${media.slug}`}>
                <CardHeader
                    avatar={
                        <Avatar
                            aria-label={`Uploaded By ${media.user.username}`}
                        >
                            {media.user.username[0]}
                        </Avatar>
                    }
                    classes={{ content: classes.headerContent }}
                    title={media.title}
                    titleTypographyProps={{ noWrap: true }}
                    subheader={
                        <div className={classes.subHeader}>
                            <VisibilityIcon
                                className={classes.subHeaderIcon}
                                fontSize="inherit"
                                visibility={media.visibility}
                            />{' '}
                            &bull;{' '}
                            {moment
                                .utc(media.created_at)
                                .format('MMMM Do, YYYY')}
                        </div>
                    }
                />
                <CardActionArea>
                    {thumb && (
                        <div className={classes.thumbContainer}>
                            <CardMedia
                                component="img"
                                src={thumb}
                                classes={{ media: classes.thumb }}
                            />
                        </div>
                    )}
                    <CardContent>
                        <TagList noWrap tags={media.tags} />
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    )
}

MediaResultCard.propTypes = {
    classes: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
}

export default withStyles(styles)(MediaResultCard)
