import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const advancedUploadSupported = (function() {
    const div = document.createElement('div')
    return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
    )
})()

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            height: '300px',
        },
        [theme.breakpoints.down('md')]: {
            height: '150px',
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        outline: `2px dashed ${theme.palette.primary[300]}`,
        outlineOffset: -theme.spacing(1),
        backgroundColor: theme.palette.primary[100],
        borderRadius: theme.shape.borderRadius,
    },
    labelDragOver: {
        outline: `3px dashed ${theme.palette.primary[200]}`,
        outlineOffset: theme.spacing(-1.5),
        backgroundColor: theme.palette.primary[50],
    },
    labelMessagePrimary: {
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    icon: {
        display: 'block',
        fontSize: '64px',
        [theme.breakpoints.down('md')]: {
            fontSize: '48px',
        },
        color: theme.palette.primary[300],
        marginBottom: theme.spacing(1),
    },
    fileInput: {
        opacity: '0',
        height: '0',
    },
})

const FileDrop = ({
    classes,
    isDragOver,
    addFiles,
    dropFiles,
    dragEnter,
    dragLeave,
}) => {
    const labelClassName = classNames(classes.label, {
        [classes.labelDragOver]: isDragOver,
    })
    const iconClassName = classNames('fas', 'fa-file-import', classes.icon)
    if (advancedUploadSupported) {
        return (
            <form
                id="file-drop"
                className={classes.root}
                onDrop={dropFiles}
                onDragEnter={dragEnter}
                onDragOver={dragEnter}
                onDragLeave={dragLeave}
                onDragEnd={dragLeave}>
                <label className={labelClassName} htmlFor="file-drop-input">
                    <i className={iconClassName} />
                    <input
                        id="file-drop-input"
                        type="file"
                        className={classes.fileInput}
                        name="files"
                        onChange={addFiles}
                        multiple
                    />
                    <Typography
                        color="inherit"
                        variant="caption"
                        align="center">
                        <span className={classes.labelMessagePrimary}>
                            Choose files
                        </span>{' '}
                        or drag them here to upload...
                    </Typography>
                </label>
            </form>
        )
    }

    return (
        <form id="file-drop" className={classes.root}>
            <input
                id="file-drop-input"
                type="file"
                className={classes.fileInput}
                name="files"
                onChange={addFiles}
                multiple
            />
            <label className={labelClassName} htmlFor="file-drop-input">
                <Button variant="contained" color="secondary">
                    Choose files
                </Button>
            </label>
        </form>
    )
}

FileDrop.propTypes = {
    //
    // PRIVATE API
    //

    /**
     * Whether or not files are currently dragged over the dropzone
     */
    isDragOver: PropTypes.bool.isRequired,
    /**
     * Function to call when the file input is modified
     */
    addFiles: PropTypes.func.isRequired,
    /**
     * Function to call when files are dropped in the drop zone
     */
    dropFiles: PropTypes.func.isRequired,
    /**
     * Handler for the dragenter and dragover events
     */
    dragEnter: PropTypes.func.isRequired,
    /**
     * Handler for the dragleave and dragend events
     */
    dragLeave: PropTypes.func.isRequired,

    //
    // PUBLIC API
    //

    /**
     * Handler to be called when files have been added
     */
    onFilesChanged: PropTypes.func,
}

export default withStyles(styles)(FileDrop)
