import { connect } from 'react-redux'

import { getFeaturedVideos } from '../selectors'
import { setPageTitle } from '../actions/AppState'
import { fetchFeaturedVideos } from '../actions/HomePage'
import HomePage from '../components/HomePage'

const mapStateToProps = (state) => {
    return {
        featuredVideos: getFeaturedVideos(state),
    }
}

const actionCreators = { setPageTitle, fetchFeaturedVideos }

export default connect(mapStateToProps, actionCreators)(HomePage)
