const initialState = {
    dragOver: false,
}

function FileDrop(state = initialState, action) {
    switch (action.type) {
        case 'FILE_DROP_DRAGENTER':
            return Object.assign({}, state, {
                dragOver: true,
            })

        case 'FILE_DROP_DRAGLEAVE':
            return Object.assign({}, state, {
                dragOver: false,
            })

        default:
            return state
    }
}

export default FileDrop
