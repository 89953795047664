import { change, reset, SubmissionError } from 'redux-form'
import { storeCollection } from './Entities'

export const submitForm = (form, values) => {
    return (dispatch, getState) => {
        const sourceId = values.source_id

        const collectionData = {
            visibility: values.visibility,
            transform: {
                spec: {
                    input_type: values.source_type,
                    operations: [
                        {
                            output_type: values.item_type,
                            count: values.count,
                            start: values.start,
                            end: values.end,
                            width: values.width && +values.width,
                            height: values.height && +values.height,
                            duration: values.duration,
                        },
                    ],
                },
            },
        }

        return fetch(`/api/v1/media/${sourceId}/collections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(collectionData),
        }).then((res) => {
            const json = res.json()

            if (res.ok) {
                dispatch(change(form, '_successMsg', 'Collection created!'))
                setTimeout(() => {
                    dispatch(change(form, '_successMsg', ''))
                }, 5000)
                return json.then((collection) => {
                    dispatch(storeCollection(collection))
                    dispatch(reset(form))
                })
            }

            dispatch(change(form, '_failedMsg', 'Submission Failed'))
            setTimeout(() => {
                dispatch(change(form, '_failedMsg', ''))
            }, 5000)
            return json.then((errData) => {
                throw new SubmissionError(errData.errors)
            })
        })
    }
}
