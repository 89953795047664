import { schema } from 'normalizr'
import moment from 'moment'

export const jobSchema = new schema.Entity('jobs')
export const userSchema = new schema.Entity('users')
export const tagSchema = new schema.Entity('tags')
export const transformSchema = new schema.Entity('transforms')
export const resourceSchema = new schema.Entity('resources')

export const collectionSchema = new schema.Entity('collections', {
    user: userSchema,
    transform: transformSchema,
})

export const mediaSchema = new schema.Entity(
    'media',
    {
        collections: [collectionSchema],
        resources: [resourceSchema],
        tags: [tagSchema],
        user: userSchema,
    },
    {
        processStrategy: (value, parent, key) => {
            // sort tags by name
            const tags = value.tags || []
            tags.sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            )
            // sort collections by type, then by create time
            const collections = value.collections || []
            collections.sort((a, b) => {
                if (a.item_type < b.item_type) {
                    return -1
                } else if (a.item_type > b.item_type) {
                    return 1
                }

                return moment(a.created_at) < moment(b.created_at)
            })
            const publicUrl = `/${value.media_type}s/${
                value.slug ? value.slug : value.id
            }`
            return Object.assign({}, value, {
                ...value,
                collections,
                tags,
                publicUrl,
            })
        },
    }
)

mediaSchema.define({
    related: [mediaSchema],
})

collectionSchema.define({
    source: mediaSchema,
    items: [mediaSchema],
})

export const getSchemaForType = (entityType) => {
    if (entityType === 'tags') {
        return tagSchema
    } else if (entityType === 'users') {
        return userSchema
    } else if (entityType === 'transforms') {
        return transformSchema
    } else if (entityType === 'resources') {
        return resourceSchema
    } else if (entityType === 'collections') {
        return collectionSchema
    } else if (entityType === 'media') {
        return mediaSchema
    } else if (entityType === 'jobs') {
        return jobSchema
    }

    throw Error(`Unrecognized entity type ${entityType}`)
}
