export const setThumbnail = (enqueueSnackbar, imageId, sourceId) => {
    return (dispatch) => {
        return fetch(`/api/v1/videos/${sourceId}/thumbnail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                image_id: imageId,
            }),
        })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    return json.then((res) => {
                        enqueueSnackbar('Updated thumbnail!', {
                            variant: 'success',
                        })
                    })
                }

                return json.then((error) => {
                    enqueueSnackbar(
                        'Failed to update thumbnail: ' +
                            error.errors.join(', '),
                        { variant: 'error' },
                    )
                })
            })
            .catch((error) => console.error(error))
    }
}

export const onSeek = function(seekValue) {
    return (dispatch) => {
        const video = document.getElementById('primary-video')
        video.currentTime = seekValue
        window.scrollTo(0, 0)
    }
}
export const onShare = (media, event) => {
    return (dispatch) => {
        event.preventDefault()
        const encoded_media_url = encodeURIComponent(media.url)
        window.open(`https://twitter.com/share?url=${encoded_media_url}`)
    }
}
