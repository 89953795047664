import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import ReelService from '../services/ReelService'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '30px',
        alignItems: 'center',
        width: '100%',
    },
    gutter: {
        position: 'relative',
        height: '10px',
        width: '100%',
        // borderRadius: '5px',
        backgroundColor: theme.palette.grey[300],
    },
    gapSpan: {
        position: 'absolute',
    },
    intervalSpan: {
        position: 'absolute',
        top: '20%',
        height: '60%',
        backgroundColor: theme.palette.primary.light,
    },
    stepSpan: {
        position: 'absolute',
        top: 0,
        height: '100%',
        backgroundColor: theme.palette.primary.dark,
    },
}))

const ReelInterval = ({
    count,
    start,
    end,
    stepDuration,
    totalDuration,
    onPreview,
}) => {
    const classes = useStyles()
    const svc = new ReelService()

    // normalize the inputs
    stepDuration =
        typeof stepDuration !== 'undefined'
            ? svc.parseInputTime(stepDuration)
            : 0.0
    let intervalEnd =
        typeof end !== 'undefined' ? svc.parseInputTime(end) : totalDuration
    while (intervalEnd < 0) {
        intervalEnd += totalDuration
    }
    const intervalStart =
        typeof start !== 'undefined'
            ? svc.parseInputTime(start)
            : intervalEnd && (count === 1 || count === '1')
            ? intervalEnd - stepDuration
            : 0.0
    if (count === 1 || count === '1') {
        intervalEnd = intervalStart
    }

    // compute the timestamp for each step
    let timestamps = []
    try {
        timestamps = svc.getTimestamps(
            count,
            intervalStart,
            intervalEnd,
            stepDuration
        )
    } catch (err) {
        // okay fine
    }

    const makeStep = (timestamp) => (
        <Tooltip
            key={timestamp}
            title={moment.utc(timestamp * 1000).format('mm:ss')}
        >
            <div
                className={classes.stepSpan}
                style={{
                    left: `${((timestamp * 100) / totalDuration).toFixed(2)}%`,
                    width: stepDuration
                        ? `${((stepDuration * 100) / totalDuration).toFixed(
                              2
                          )}%`
                        : '2px',
                }}
                onMouseOver={() => {
                    if (typeof onPreview === 'function') {
                        onPreview(timestamp)
                    }
                }}
            />
        </Tooltip>
    )

    // compute the position for the entire interval and each step
    const leftPos = `${((intervalStart * 100) / totalDuration).toFixed(2)}%`
    const intervalW = `${(
        ((intervalEnd - intervalStart) * 100) /
        totalDuration
    ).toFixed(2)}%`

    return (
        <div className={classes.root}>
            <div className={classes.gutter}>
                {timestamps.length > 0 && (
                    <>
                        <div
                            className={classes.intervalSpan}
                            style={{
                                left: leftPos,
                                width: intervalW,
                            }}
                        />
                        {timestamps.map(makeStep)}
                    </>
                )}
            </div>
        </div>
    )
}

ReelInterval.propTypes = {
    /**
     * The count specifier
     */
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The total duration of the reel source
     */
    totalDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    /**
     * Timestamp at which to start the reel
     */
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Timestamp at which to stop the reel
     */
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The duration of each step, if any
     */
    stepDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Called on mouseover of a step with the timestamp of that step, useful
     * for providing some preview of the output of that step.
     */
    onPreview: PropTypes.func,
}

ReelInterval.defaultProps = {
    stepDuration: 0.0,
}

export default ReelInterval
