import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

import { makeStyles, withStyles } from '@material-ui/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Timer from '../Timer'

const useStyles = makeStyles((theme) => {
    return {
        connector: {
            flex: '1 1 auto',
            position: 'absolute',
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)',
            display: 'inline-flex',
            alignItems: 'center',
        },
        circle: {
            width: '7px',
            height: '7px',
            borderRadius: '50%',
            margin: '6px',
        },
        active: {
            backgroundColor: theme.palette.primary.dark,
            width: '11px',
            height: '11px',
            animation:
                '$stepper-pulsate 2000ms cubic-bezier(0.4, 0, 0.2, 1) 200ms infinite',
        },
        completed: {
            backgroundColor: theme.palette.primary.main,
        },
        '@keyframes stepper-pulsate': {
            '0%': {
                backgroundColor: theme.palette.primary.dark,
            },
            '50%': {
                backgroundColor: theme.palette.primary.light,
            },
            '100%': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    }
})

const HiddenStepConnector = withStyles({
    line: {
        border: 'none',
    },
})(StepConnector)

const ThemedStepConnector = withStyles((theme) => ({
    line: {
        borderRadius: 1,
    },
    active: {
        '& $line': {
            borderTopWidth: '3px',
            borderRadius: 1,
            borderColor: theme.palette.primary.dark,
            animation:
                '$connector-pulsate 2000ms cubic-bezier(0.4, 0,  0.2, 1) 200ms infinite',
        },
    },
    completed: {
        '& $line': {
            borderTopWidth: '1px',
            borderColor: theme.palette.primary.main,
        },
    },
    '@keyframes connector-pulsate': {
        '0%': {
            borderColor: theme.palette.primary.dark,
        },
        '50%': {
            borderColor: theme.palette.primary.light,
        },
        '100%': {
            borderColor: theme.palette.primary.dark,
        },
    },
}))(StepConnector)

const TooltipConnector = ({ tooltipNode, active, completed }) => {
    const classes = useStyles()
    const circleClass = classNames(classes.circle, {
        [classes.active]: active,
        [classes.completed]: completed,
    })
    return (
        <div className={classes.connector}>
            <ThemedStepConnector active={active} completed={completed} />
            <Tooltip title={tooltipNode} placement="top">
                <div className={circleClass} />
            </Tooltip>
            <ThemedStepConnector active={completed} completed={completed} />
        </div>
    )
}

TooltipConnector.propTypes = {
    tooltipNode: PropTypes.node.isRequired,
    active: PropTypes.bool,
    completed: PropTypes.bool,
}

const JobStateStepper = ({ job }) => {
    let activeStep

    if (job.state === 'created') {
        activeStep = 0
    } else if (job.state === 'queued') {
        activeStep = 1
    } else if (job.state === 'started') {
        activeStep = 2
    } else if (job.state === 'success') {
        activeStep = 3
    } else if (job.state === 'failed') {
        activeStep = 3
    } else if (job.state === 'dropped') {
        if (!job.enqueued_at) {
            activeStep = 0
        } else if (!job.started_at) {
            activeStep = 1
        } else if (!job.ended_at) {
            activeStep = 2
        } else {
            activeStep = 3
        }
    }

    const printDate = (dstr) => (
        <>
            {moment.utc(dstr).format('HH:mm:ss.SSS')}
            <br />
            {moment.utc(dstr).format('D MMM YYYY')}
        </>
    )

    return (
        <Stepper
            alternativeLabel
            connector={<HiddenStepConnector />}
            activeStep={activeStep}
        >
            <Step>
                <StepLabel
                    optional={
                        <Typography
                            variant="caption"
                            align="center"
                            display="block"
                        >
                            {printDate(job.last_retried_at || job.created_at)}
                        </Typography>
                    }
                >
                    {job.last_retried_at
                        ? `Retried (x${job.retries})`
                        : 'Created'}
                </StepLabel>
            </Step>
            <Step>
                <TooltipConnector
                    active={activeStep === 0}
                    completed={activeStep > 0}
                    tooltipNode={
                        <Timer
                            active
                            vertical
                            label="Time to Schedule"
                            startDate={job.last_retried_at || job.created_at}
                            endDate={job.enqueued_at}
                        />
                    }
                />
                <StepLabel
                    optional={
                        job.enqueued_at && (
                            <Typography
                                variant="caption"
                                align="center"
                                display="block"
                            >
                                {printDate(job.enqueued_at)}
                            </Typography>
                        )
                    }
                >
                    Queued
                </StepLabel>
            </Step>
            <Step>
                <TooltipConnector
                    active={activeStep === 1}
                    completed={activeStep > 1}
                    tooltipNode={
                        <Timer
                            active
                            vertical
                            label="Time in Queue"
                            startDate={job.enqueued_at}
                            endDate={job.started_at}
                        />
                    }
                />
                <StepLabel
                    optional={
                        job.started_at && (
                            <Typography
                                variant="caption"
                                align="center"
                                display="block"
                            >
                                {printDate(job.started_at)}
                            </Typography>
                        )
                    }
                >
                    Started
                </StepLabel>
            </Step>
            <Step>
                <TooltipConnector
                    active={activeStep === 2}
                    completed={activeStep > 2}
                    tooltipNode={
                        <Timer
                            active
                            vertical
                            label="Execution Time"
                            startDate={job.started_at}
                            endDate={job.ended_at}
                        />
                    }
                />
                <StepLabel
                    optional={
                        job.ended_at && (
                            <Typography
                                variant="caption"
                                align="center"
                                display="block"
                            >
                                {printDate(job.ended_at)}
                            </Typography>
                        )
                    }
                    error={job.state === 'failed'}
                >
                    Ended
                </StepLabel>
            </Step>
        </Stepper>
    )
}

JobStateStepper.propTypes = {
    /**
     * The job whose state to display
     */
    job: PropTypes.object.isRequired,
}

export default JobStateStepper
