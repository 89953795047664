import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { RadioGroup, TextField } from 'redux-form-material-ui'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LinkIcon from '@material-ui/icons/Link'
import ErrorIcon from '@material-ui/icons/Error'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'
import RefreshIcon from '@material-ui/icons/Refresh'

import Link from '../components/Link'
import EditableTagList from '../components/EditableTagList'

const styles = (theme) => ({
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    titleField: {
        width: 'calc(100% - 48px)',
    },
    progress: {
        display: 'block',
        width: '100%',
    },
    success: {
        display: 'flex',
        alignItems: 'center',
        color: 'green',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    submitIcon: {
        marginRight: theme.spacing(0.25),
    },
    visibility: {
        paddingTop: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
})

const VideoForm = ({
    classes,
    video,
    errors,
    isSubmitting,
    pristine,
    showLink,
    onCancel,
    onTagCreate,
    onTagListChange,
    randomizeTitle,
    remoteSubmit,
}) => {
    return (
        <form>
            <div className={classes.title}>
                <FormControl
                    className={classes.titleField}
                    margin="normal"
                    fullWidth>
                    <Field
                        name="title"
                        label="Title"
                        placeholder="Title"
                        component={TextField}
                        fullWidth
                    />
                </FormControl>
                <Tooltip placement="top" title="Random Title">
                    <IconButton color="secondary" onClick={randomizeTitle}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <FormControl margin="normal" fullWidth error={!!errors.tags}>
                <EditableTagList tags={video.tags} onChange={onTagListChange} />
                {errors.tags && (
                    <FormHelperText>{errors.tags.join(' ')}</FormHelperText>
                )}
            </FormControl>
            <FormControl margin="normal">
                <InputLabel shrink>Visibility</InputLabel>

                <Field
                    className={classes.visibility}
                    name="visibility"
                    row
                    component={RadioGroup}>
                    <FormControlLabel
                        value="private"
                        control={
                            <Radio
                                icon={<LockIcon fontSize="small" />}
                                checkedIcon={
                                    <LockIcon
                                        color="secondary"
                                        fontSize="small"
                                    />
                                }
                            />
                        }
                    />
                    <FormControlLabel
                        value="public"
                        control={
                            <Radio
                                icon={<PublicIcon fontSize="small" />}
                                checkedIcon={
                                    <PublicIcon
                                        color="secondary"
                                        fontSize="small"
                                    />
                                }
                            />
                        }
                    />
                </Field>
            </FormControl>
            <CardActions className={classes.cardActions}>
                {video._successMsg && (
                    <div className={classes.success}>
                        <CheckCircleOutlineIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {video._successMsg}
                        </Typography>
                    </div>
                )}
                {video._failedMsg && (
                    <div className={classes.error}>
                        <ErrorIcon
                            className={classes.submitIcon}
                            color="inherit"
                        />
                        <Typography color="inherit" variant="subtitle2">
                            {video._failedMsg}
                        </Typography>
                    </div>
                )}
                <div className={classes.grow} />
                {showLink &&
                    video &&
                    video.id && (
                        <Link src={`/videos/${video.slug}`} color="inherit">
                            <IconButton color="secondary">
                                <LinkIcon />
                            </IconButton>
                        </Link>
                    )}
                {onCancel && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onCancel}>
                        Cancel
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || pristine}
                    onClick={remoteSubmit}>
                    {isSubmitting
                        ? 'Submitting...'
                        : video && video.id ? 'Update' : 'Create'}
                </Button>
            </CardActions>
            {isSubmitting && (
                <div className={classes.progress}>
                    <LinearProgress />
                </div>
            )}
        </form>
    )
}

VideoForm.propTypes = {
    /**
     * CSS classes to apply to rendered components
     */
    classes: PropTypes.object.isRequired,
    /**
     * The current form values for the video
     */
    video: PropTypes.object.isRequired,
    /**
     * True when the form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * True when the form data has not been modified
     */
    pristine: PropTypes.bool,
    /**
     * Function to handle form submission
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * Handler to call when pressing the cancel button
     */
    onCancel: PropTypes.func,
    /**
     * Callbabck to use when tags are updated
     */
    onTagListChange: PropTypes.func.isRequired,
    /**
     * Function to generate a random title
     */
    randomizeTitle: PropTypes.func.isRequired,
    /**
     * Function to trigger form submission
     */
    remoteSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(VideoForm)
