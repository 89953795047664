const initialState = {
    loggedIn: false,
}

function UserState(state = initialState, action) {
    switch (action.type) {
        case 'USER_LOGIN':
            localStorage.setItem('user', JSON.stringify(action.userData))
            return Object.assign({}, state, {
                loggedIn: true,
                user: action.userData,
            })

        case 'USER_LOGOUT':
            localStorage.removeItem('user')
            return {
                loggedIn: false,
            }

        case 'STORE_USER':
            return Object.assign({}, state, {
                loggedIn: true,
                user: action.userData,
            })

        default:
            return state
    }
}

export default UserState
