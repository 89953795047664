import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Stat from '../Stat'

const useStyles = makeStyles((theme) => ({
    section: {
        padding: theme.spacing(1),
    },
}))

/**
 * Given a size in bytes, return the rounded size in megabytes.
 *
 * @param {number} size  The size in bytes
 * @return {number}      The rounded size in MB
 */
const toMB = (size) => {
    return Math.round((100 * size) / 1024 / 1024) / 100
}
const toKbps = (bitRate) => {
    return Math.round((100 * bitRate) / 1024) / 100
}

const StreamData = ({ rawStreamData }) => {
    const classes = useStyles()
    rawStreamData = rawStreamData.sort((a, b) => {
        if (a.codec_type === 'video' && b.codec_type !== 'video') {
            return -1
        } else if (a.codec_type !== 'video' && b.codec_type === 'video') {
            return 1
        }

        return 0
    })
    let totalSize = 0
    const streams = rawStreamData.map((stream, idx) => {
        totalSize += stream.duration * stream.bit_rate
        let stats = [
            {
                label: 'Codec',
                value: stream.codec_name,
            },
            {
                label: 'Duration',
                value: stream.duration
                    ? `${Number.parseFloat(stream.duration).toFixed(2)} s`
                    : null,
            },
            {
                label: 'Bit Rate',
                value: `${toKbps(stream.bit_rate)} kbps`,
            },
        ]
        if (stream.codec_type === 'video') {
            stats = stats.concat([
                {
                    label: 'Aspect Ratio',
                    value: stream.display_aspect_ratio,
                },
                {
                    label: 'Height',
                    value: stream.height,
                },
                {
                    label: 'Width',
                    value: stream.width,
                },
                {
                    label: 'No. Frames',
                    value: stream.nb_frames,
                },
                {
                    label: 'Frame Rate',
                    value: stream.r_frame_rate,
                },
            ])
        }
        return (
            <div key={idx} className={classes.section}>
                <Typography variant="h6">{stream.codec_type}</Typography>

                {stats.map(({ label, value }, statIdx) => {
                    const statProps = {
                        comfy: true,
                    }
                    if ((statIdx + 1) % 2 === 0) {
                        statProps.alt = true
                    }
                    return (
                        <Stat key={statIdx} label={label} {...statProps}>
                            {value}
                        </Stat>
                    )
                })}
            </div>
        )
    })
    return (
        <>
            {streams}
            <div className={classes.section}>
                <Stat label="Size" comfy>
                    {toMB(totalSize / 8)} MB
                </Stat>
            </div>
        </>
    )
}

StreamData.propTypes = {
    rawStreamData: PropTypes.array.isRequired,
}

export default StreamData
