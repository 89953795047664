export const openDialog = () => ({ type: 'OPEN_VIDEO_UPLOAD_DIALOG' })
export const closeDialog = () => ({ type: 'CLOSE_VIDEO_UPLOAD_DIALOG' })
export const submitForm = () => ({ type: 'SUBMIT_VIDEO_UPLOAD_FORM' })
export const successForm = () => ({ type: 'SUCCESS_VIDEO_UPLOAD_FORM' })
export const failedForm = () => ({ type: 'FAILED_VIDEO_UPLOAD_FORM' })
export const addVideos = (videos) => {
    return { type: 'ADD_VIDEOS', videos }
}

export const submitVideoFiles = (enqueueSnackbar, files) => {
    return (dispatch) => {
        let body = new FormData()
        Array.from(files).forEach((file) => body.append('videos', file))

        dispatch(submitForm())
        return fetch('/api/v1/videos/files', { method: 'POST', body })
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(successForm())
                    return json.then((videos) => {
                        enqueueSnackbar(
                            `Uploaded ${videos.length} video(s) successfully!`,
                            {
                                variant: 'success',
                            },
                        )
                        dispatch(addVideos(videos))
                    })
                }

                dispatch(failedForm())
                return json.then((err) => {
                    enqueueSnackbar(
                        'Failed to upload files: ' + err.errors.join(', '),
                        { variant: 'error' },
                    )
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }
}
