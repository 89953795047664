import React from 'react'
import PropTypes from 'prop-types'

import JobCardBase from './JobCardBase'
import ReencodeCard from './ReencodeCard'
import GenerateCollectionCard from './GenerateCollectionCard'
import GetMetadataCard from './GetMetadataCard'

const JobCard = ({ job, ...props }) => {
    const funcName = job.func_name.split('.').slice(-1)[0]
    if (funcName === 'reencode_media' || funcName === 'reencode_video') {
        return <ReencodeCard job={job} {...props} />
    } else if (
        funcName === 'get_media_metadata' ||
        funcName === 'get_video_metadata'
    ) {
        return <GetMetadataCard job={job} {...props} />
    } else if (funcName === 'generate_media_collection') {
        return <GenerateCollectionCard job={job} {...props} />
    } else {
        return <JobCardBase job={job} {...props} />
    }
}

JobCard.propTypes = {
    job: PropTypes.object.isRequired,
}

export default JobCard
