import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const sizes = {
    small: 64,
    medium: 128,
    large: 256,
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'block',
        width: '128px',
        backgroundColor: theme.palette.grey[100],
        borderRadius: '4px',
        margin: theme.spacing(3),
    },

    item: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.grey[500],
        borderRadius: '4px',
        backgroundSize: '100% 100%',
    },

    dimension: {
        position: 'absolute',
        color: theme.palette.secondary.light,
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(3),
        width: '100%',
    },
    dimHash: {
        backgroundColor: theme.palette.secondary.light,
        height: '60%',
        width: '1px',
    },
    dimSep: {
        flexGrow: 1,
        height: '1px',
        backgroundColor: theme.palette.secondary.light,
    },
    dimNumeral: {
        fontSize: '10px',
        padding: '1px 4px',
    },
    itemW: {
        top: theme.spacing(-3),
        left: 0,
    },
    itemH: {
        transform: 'rotate(-90deg)',
        transformOrigin: 'top left',
        top: '100%',
        left: theme.spacing(-3),
    },
    sourceW: {
        bottom: theme.spacing(-3),
        left: 0,
    },
    sourceH: {
        transform: 'rotate(90deg)',
        transformOrigin: 'top left',
        top: '0%',
        left: `calc(100% + ${theme.spacing(3)}px)`,
    },
}))

const ReelDimensions = ({
    sourceW,
    sourceH,
    itemW,
    itemH,
    background,
    size,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    const sizeBase = sizes[size] || 128

    // compute the scaled size of the reel source
    const sourceRatio = sourceW / sourceH
    const sourceStyles = {}
    if (sourceRatio >= 1) {
        sourceStyles.width = `${sizeBase}px`
        sourceStyles.height = `${sizeBase / sourceRatio}px`
    } else {
        sourceStyles.height = `${sizeBase}px`
        sourceStyles.width = `${sizeBase * sourceRatio}px`
    }

    // compute the scaled size of the (potentially resized) reel items
    const itemStyles = {}
    if (background) {
        itemStyles.backgroundImage = `url(${background})`
    }
    if (itemW && !itemH) {
        itemH = Math.round((itemW * 10) / sourceRatio) / 10
    } else if (itemH && !itemW) {
        itemW = Math.round(itemH * sourceRatio * 10) / 10
    } else if (!itemW && !itemH) {
        itemW = sourceW
        itemH = sourceH
    }
    const itemRatio = itemW / itemH
    itemStyles.width = `${(itemW * 100) / sourceW}%`
    itemStyles.height = `${(itemH * 100) / sourceH}%`

    const makeDimension = (numeral, width, dimClass) => {
        const dimStyles = {}
        const color =
            dimClass === classes.sourceW || dimClass === classes.sourceH
                ? theme.palette.grey[500]
                : theme.palette.secondary.light
        if (dimClass === classes.itemH || dimClass === classes.sourceH) {
            if (dimClass === classes.itemH) {
                dimStyles.width = `${100 / itemRatio}%`
            } else if (dimClass === classes.sourceH) {
                dimStyles.width = width
                dimStyles.color = color
            }
        } else if (dimClass === classes.sourceW) {
            dimStyles.color = color
        }
        return (
            <div
                className={classNames(classes.dimension, dimClass)}
                style={dimStyles}
            >
                <div
                    className={classes.dimHash}
                    style={{ backgroundColor: color }}
                />
                <div
                    className={classes.dimSep}
                    style={{ backgroundColor: color }}
                />
                <div className={classes.dimNumeral}>{numeral}</div>
                <div
                    className={classes.dimSep}
                    style={{ backgroundColor: color }}
                />
                <div
                    className={classes.dimHash}
                    style={{ backgroundColor: color }}
                />
            </div>
        )
    }

    return (
        <div className={classes.root} style={sourceStyles}>
            <div className={classes.item} style={itemStyles}>
                {makeDimension(itemW, itemStyles.width, classes.itemW)}
                {makeDimension(itemH, itemStyles.width, classes.itemH)}
            </div>
            {makeDimension(sourceH, sourceStyles.height, classes.sourceH)}
            {makeDimension(sourceW, sourceStyles.width, classes.sourceW)}
        </div>
    )
}

ReelDimensions.propTypes = {
    /**
     * The width of the reel source
     */
    sourceW: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    /**
     * The height of the reel source
     */
    sourceH: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    /**
     * The desired width of the reel items
     */
    itemW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The desired height of the reel items
     */
    itemH: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Optional background to use for scaled image
     */
    background: PropTypes.string,
    /**
     * The size of the component
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
}

ReelDimensions.defaultProps = {
    size: 'medium',
}

export default ReelDimensions
