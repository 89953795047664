import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import StreamData from './StreamData'
import PollableJobCard from '../../containers/PollableJobCard'

const useStyles = makeStyles((theme) => ({
    resource: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        marginBottom: theme.spacing(2),
    },
}))

const GetMetadataCard = ({ job, ...props }) => {
    const classes = useStyles()

    const link = `/media/${job.args[0]}`
    let preview

    if (
        job.state === 'success' &&
        job.result.result &&
        job.result.result.resources
    ) {
        preview = (
            <>
                {job.result.result.resources.map((resource, idx) => (
                    <div key={idx} className={classes.resource}>
                        <Typography variant="h6">
                            {resource.video_codec_name || '???'} /{' '}
                            {resource.audio_codec_name || '???'}
                        </Typography>
                        <StreamData rawStreamData={resource.raw_stream_data} />
                    </div>
                ))}
            </>
        )
    } else if (job.state === 'success') {
        preview = <StreamData rawStreamData={[job.result]} />
    }
    return (
        <PollableJobCard
            job={job}
            link={link}
            preview={preview}
            pollDelay={20000}
            {...props}
        />
    )
}

GetMetadataCard.propTypes = {
    /**
     * The job to render
     */
    job: PropTypes.object.isRequired,
}

export default GetMetadataCard
