import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import PauseIcon from '@material-ui/icons/Pause'
import PlayIcon from '@material-ui/icons/PlayArrow'
import PopOutIcon from '@material-ui/icons/OpenInNew'
import ReplayIcon from '@material-ui/icons/Replay'

import PollableJobCard from '../../containers/PollableJobCard'
import StreamData from './StreamData'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2, 4),
        padding: theme.spacing(1),
        outline: 0,
    },
    compare: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        '& video': {
            display: 'block',
            width: '100%',
        },
    },
    actions: {
        display: 'flex',
    },
    streamData: {
        padding: theme.spacing(1),
    },
}))

const ReencodeCard = ({ job, ...props }) => {
    const classes = useStyles()
    const [isOpen, setOpen] = useState(false)
    const originalEl = useRef()
    const newEl = useRef()

    const link = `/media/${job.args[0]}`
    let preview

    if (job.state === 'success' && job.result.result.original) {
        const original = job.result.result.original
        const newMedia = job.result.result.new
        const videos = (
            <>
                <div className={classes.compare}>
                    <div className={classes.item}>
                        <Typography variant="h5">Original</Typography>
                        <Typography variant="h6">
                            {original.video_codec_name || '???'} /{' '}
                            {original.audio_codec_name || '???'}
                        </Typography>
                        <video ref={originalEl} preload="none">
                            <source src={original.url} />
                            This encoding is not supported by your browser.
                        </video>
                        <StreamData rawStreamData={original.raw_stream_data} />
                    </div>
                    <div className={classes.item}>
                        <Typography variant="h5">New</Typography>
                        <Typography variant="h6">
                            {newMedia.video_codec_name || '???'} /{' '}
                            {newMedia.audio_codec_name || '???'}
                        </Typography>
                        <video ref={newEl} preload="none">
                            <source src={newMedia.url} />
                            This encoding is not supported by your browser.
                        </video>
                        <StreamData rawStreamData={newMedia.raw_stream_data} />
                    </div>
                </div>
                <div className={classes.actions}>
                    <Tooltip title="Play">
                        <IconButton
                            onClick={() => {
                                originalEl.current.play()
                                newEl.current.play()
                            }}
                        >
                            <PlayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Pause">
                        <IconButton
                            onClick={() => {
                                originalEl.current.pause()
                                newEl.current.pause()
                            }}
                        >
                            <PauseIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Replay">
                        <IconButton
                            onClick={() => {
                                originalEl.current.load()
                                newEl.current.load()
                            }}
                        >
                            <ReplayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Pop Out">
                        <IconButton
                            onClick={() => {
                                setOpen(true)
                            }}
                        >
                            <PopOutIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </>
        )
        preview = (
            <div className={classes.root}>
                {videos}
                <Modal open={isOpen} onClose={() => setOpen(false)}>
                    <div className={classes.paper}>{videos}</div>
                </Modal>
            </div>
        )
    }
    return (
        <PollableJobCard
            job={job}
            link={link}
            preview={preview}
            pollDelay={20000}
            {...props}
        />
    )
}

ReencodeCard.propTypes = {
    /**
     * The job to render
     */
    job: PropTypes.object.isRequired,
}

export default ReencodeCard
