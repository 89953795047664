import { connect } from 'react-redux'

import JobPage from '../components/JobPage'
import { getJobById } from '../selectors'
import { fetchJob } from '../actions/JobPage'
import { cancelJob, retryJob } from '../actions/JobsPage'

const mapStateToProps = (state, { jobId }) => {
    const job = getJobById(state, jobId)
    return {
        job,
        jobId,
    }
}

export default connect(
    mapStateToProps,
    {
        fetchJob,
        cancelJob,
        retryJob,
    }
)(JobPage)
