import { connect } from 'react-redux'

import { submitVideoFiles } from '../actions/VideoUploadDialog'
import VideoUploadDialog from '../components/VideoUploadDialog'

const mapStateToProps = ({ videoUploadDialog }) => {
    return {
        ...videoUploadDialog,
    }
}

export default connect(
    mapStateToProps,
    {
        submitVideoFiles,
    }
)(VideoUploadDialog)
