import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Typography from '@material-ui/core/Typography'

import Placeholder from '../containers/Placeholder'
import Link from './Link'
import VisibilityIcon from './VisibilityIcon'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        position: 'relative',
    },
    gridList: {
        width: '100%',
    },
    subHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    subHeaderIcon: {
        marginRight: theme.spacing(0.5),
    },
    thumbContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
    },
    thumb: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}))

const VideoResultsGridList = ({ videos }) => {
    const classes = useStyles()
    const items = videos.map((video) => {
        return (
            <GridListTile key={video.id} className={classes.tile}>
                <Link src={`/videos/${video.slug}`}>
                    <div className={classes.thumbContainer}>
                        {video.thumbnail ? (
                            <img
                                src={video.thumbnail}
                                alt={video.title}
                                className={classes.thumb}
                            />
                        ) : (
                            <Placeholder classes={{ img: classes.thumb }} />
                        )}
                    </div>
                    <GridListTileBar
                        title={video.title}
                        subtitle={
                            <div className={classes.subHeader}>
                                <VisibilityIcon
                                    className={classes.subHeaderIcon}
                                    fontSize="inherit"
                                    visibility={video.visibility}
                                />{' '}
                                &bull;{' '}
                                {moment
                                    .utc(video.created_at)
                                    .format('MMMM Do, YYYY')}{' '}
                                &bull;{' '}
                                {moment(video.duration * 1000).format('m:ss')}
                            </div>
                        }
                    />
                </Link>
            </GridListTile>
        )
    })

    if (items.length === 0) {
        return (
            <div className={classes.root}>
                <Typography>There are no matching videos</Typography>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Hidden smDown implementation="js">
                <GridList
                    cellHeight="auto"
                    className={classes.gridList}
                    cols={3}
                >
                    {items}
                </GridList>
            </Hidden>
            <Hidden mdUp xsDown implementation="js">
                <GridList
                    cellHeight="auto"
                    className={classes.gridList}
                    cols={2}
                >
                    {items}
                </GridList>
            </Hidden>
            <Hidden smUp implementation="js">
                <GridList
                    cellHeight="auto"
                    className={classes.gridList}
                    cols={1}
                >
                    {items}
                </GridList>
            </Hidden>
        </div>
    )
}

VideoResultsGridList.propTypes = {
    /**
     * The videos to render
     */
    videos: PropTypes.array.isRequired,
}

export default VideoResultsGridList
