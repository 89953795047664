import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.breakpoints.values.md,
            maxWidth: '100%',
            margin: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.breakpoints.values.lg,
            maxWidth: '100%',
            margin: 'auto',
        },
    },
    noPad: {
        padding: 0,
    },
}))

const PageContainer = ({ children, noPad }) => {
    const classes = useStyles()
    const className = classNames(classes.root, {
        [classes.noPad]: noPad,
    })
    return <div className={className}>{children}</div>
}

PageContainer.propTypes = {
    /**
     * The contents of the page to render
     */
    children: PropTypes.node.isRequired,
    /**
     * If true, remove padding from the page container
     */
    noPad: PropTypes.bool,
}

export default PageContainer
