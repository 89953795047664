import { storeJob } from './Entities'
import {
    makePageLoadStart,
    makePageLoadError,
    makePageLoadSuccess,
    makeUpdateSearchResults,
} from './PageState'

export const pageLoadStart = makePageLoadStart('jobPage')
export const pageLoadError = makePageLoadError('jobPage')
export const pageLoadSuccess = makePageLoadSuccess('jobPage')
export const updateSearchResults = makeUpdateSearchResults('jobPage')

export const fetchJob = (jobId) => {
    return (dispatch) => {
        dispatch(pageLoadStart())

        return fetch(`/api/v1/jobs/${jobId}`)
            .then((res) => {
                const json = res.json()

                if (res.ok) {
                    dispatch(pageLoadSuccess())

                    return json.then((job) => {
                        dispatch(storeJob(job))
                    })
                }

                dispatch(pageLoadError('Bad response from server'))
            })
            .catch((error) => dispatch(pageLoadError(error)))
    }
}
