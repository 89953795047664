import { connect } from 'react-redux'

import VideoPage from '../components/VideoPage'
import { getPageState, getVideoByIdOrSlug } from '../selectors'
import {
    fetchVideo,
    generateSpectrogram,
    reencodeVideo,
    updateMetadata,
} from '../actions/VideoPage'
import { submitForm as submitReelForm } from '../actions/ReelForm'

const mapStateToProps = (state) => {
    const pageState = getPageState(state, 'videoPage')
    const video = getVideoByIdOrSlug(state, pageState.entityId)
    return {
        video,
    }
}

export default connect(
    mapStateToProps,
    {
        fetchVideo,
        generateSpectrogram,
        reencodeVideo,
        submitReelForm,
        updateMetadata,
    }
)(VideoPage)
